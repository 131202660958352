import React, { useState } from 'react';
import axios from '../../../../axiosConfig';
import { Line } from 'react-chartjs-2';
import styles from './SignalsBacktestTechnicalsPlot.module.css'; // Import the CSS module

const SignalsBacktestTechnicalsPlot = ({ companyData }) => {
    const [backtestResults, setBacktestResults] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [chartKey, setChartKey] = useState(0); 
    const [startDate, setStartDate] = useState(''); 
    const [endDate, setEndDate] = useState(''); 
    const [showInputs, setShowInputs] = useState(false); 
    const [windowSize, setWindowSize] = useState(''); 

    const fetchBacktestResults = async () => {
        if (!companyData || !companyData.symbol) {
            setError('No company symbol provided');
            return;
        }
    
        try {
            setLoading(true);
            const response = await axios.get(`/backtest_signals/?symbol=${companyData.symbol}&start_date=${startDate}&end_date=${endDate}&window=${windowSize}`);
            const data = response.data;
        
            const newBacktestResults = {};
            for (let indicator in data) {
                if (data[indicator].cumulative_returns && Object.keys(data[indicator].cumulative_returns).length > 0) {
                    const formattedDates = Object.keys(data[indicator].cumulative_returns);
                    const dataValues = Object.values(data[indicator].cumulative_returns);
            
                    const formattedData = {
                        labels: formattedDates,
                        datasets: [
                            {
                                label: 'Cumulative Returns',
                                data: dataValues,
                                borderColor: 'rgba(75,192,192,1)', 
                                fill: false,
                                pointRadius: 0,
                            }
                        ],
                        sharpe_ratio: data[indicator].sharpe_ratio,
                        current_trade_signal: data[indicator].current_trade_signal
                    };
            
                    newBacktestResults[indicator] = formattedData;
                } else {
                    setError(`No cumulative returns data received for ${indicator}`);
                }
            }
            setBacktestResults(newBacktestResults);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const handleClick = () => {
        setBacktestResults({});
        setError(null);
        fetchBacktestResults();
        setChartKey(prevKey => prevKey + 1); 
    }
    const handleBacktestClick = () => {
        setShowInputs(true); 
    }

    let signalMap = {
        '1': 'Buy',
        '0': 'Hold',
        '-1': 'Sell'
    };

    return (
        <div>
            <button onClick={handleBacktestClick}>Back-test</button>
            {showInputs && (
                <div>
                    <div style={{ marginBottom: '5px', marginLeft: '10px' , marginTop: '10px'}}>
                        <label style={{ fontSize: 'small' }}>From: </label>
                        <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
                    </div>
                    <div style={{ marginBottom: '5px', marginLeft: '10px' }}>
                        <label style={{ fontSize: 'small' }}>To: </label>
                        <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} style={{ marginLeft: '16px' }}  />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                        <label style={{ fontSize: 'small' }}>Window: </label>
                        <input type="number" value={windowSize} onChange={e => setWindowSize(e.target.value)} min="1" placeholder="Days" style={{ width: '50px', marginRight: '0px' }} />
                        <button onClick={handleClick}>Show Results</button>
                    </div>
                </div>
            )}
            {error && <div>Error: {error}</div>}
            {loading && <div>Loading...</div>}
            {Object.keys(backtestResults).map(indicator => (
                <div key={indicator} className={styles.chartContainer}>
                    <h4 style={{fontSize: '14px', paddingTop: '30px'}}>{`${indicator.replace(/_/g, ' ')}:`}</h4>
                    <div className={styles.chartWrapper}>
                        <Line 
                            data={backtestResults[indicator]} 
                            key={chartKey} 
                            options={{
                                plugins: {
                                    title: {
                                        display: true,
                                        text: `Sharpe Ratio: ${(backtestResults[indicator].sharpe_ratio * 100).toFixed(2)}%, Current Trade Signal: ${signalMap[backtestResults[indicator].current_trade_signal]}`
                                    },
                                    legend: {
                                        display: false
                                    }
                                },
                                scales: {
                                    x: {
                                        ticks: {
                                            maxRotation: 0, 
                                            autoSkipPadding: 30 
                                        }
                                    },
                                    y: {
                                        title: {
                                            display: true,
                                            text: 'Cumulative Return'
                                        }
                                    }
                                },
                                maintainAspectRatio: false,
                                responsive: true
                            }}
                        />
                    </div>
                </div>
            ))}
            {!loading && showInputs && Object.keys(backtestResults).length > 0 && <p style={{fontSize: '13px', fontStyle: 'italic', paddingTop: '20px'}}>Note: Transaction cost and interest rate are not accounted</p>}
        </div>
    );
}

export default SignalsBacktestTechnicalsPlot;
