import React, { useState, useEffect } from 'react';
import './AutocompleteInput.css';
import axios from '../../../axiosConfig';

const AutocompleteInput = ({ onCompanySelect }) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const loadSuggestions = async () => {
            if (query.length > 1) {
                setLoading(true);
                try {
                    const response = await axios.get(`/api/search/?q=${query}`);
                    setSuggestions(response.data);
                } catch (error) {
                    console.error('Error fetching suggestions:', error);
                } finally {
                    setLoading(false);
                }
                setHighlightedIndex(-1);
            } else {
                setSuggestions([]);
            }
        };

        loadSuggestions();
    }, [query]);

    const handleKeyDown = (e) => {
        if (e.keyCode === 40) { // Down arrow
            setHighlightedIndex(prevIndex =>
                prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex);
        } else if (e.keyCode === 38) { // Up arrow
            setHighlightedIndex(prevIndex =>
                prevIndex > 0 ? prevIndex - 1 : 0);
        } else if (e.keyCode === 13 && highlightedIndex !== -1) { // Enter key
            setQuery(suggestions[highlightedIndex].name);
            setSuggestions([]);
            onCompanySelect(suggestions[highlightedIndex].name);
        }
    };

    const handleSelect = (companyName) => {
        setQuery(companyName);
        setSuggestions([]);
        onCompanySelect(companyName);
    };

    return (
        <div className="autocomplete">
            <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Search for a company"
                className="autocomplete-input"
            />
            {loading && <div className="loading-spinner"></div>}
            {!loading && suggestions.length > 0 && (
                <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={suggestion.id}
                            className={`suggestion-item ${index === highlightedIndex ? 'highlighted' : ''}`}
                            onMouseDown={() => handleSelect(suggestion.name)}
                        >
                            {suggestion.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AutocompleteInput;
