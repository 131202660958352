import React from 'react';

const SummaryDisplayLatestD = ({ summary }) => {
  // Function to preprocess a single section string into title and items
  const preprocessSection = (sectionString) => {
    let lines = sectionString.split('\n').filter(line => line.trim() !== '');
    let title = '';
    let items = [];
    const topic1Index = lines.findIndex(line => line.startsWith('Topic 1'));
    if (topic1Index !== -1) {
      lines = lines.slice(topic1Index);
    }
    if (lines.length > 0) {
      if (lines[0].startsWith('Topic')) {
        items = lines;
      } else {
        title = lines[0];
        items = lines.slice(1);
      }
    }
    // Regular expression to match text within brackets
    const regex = /\(.*?\)/g;
    // Remove text within brackets from each item
    items = items.map(item => item.replace(regex, '').trim());
    return { title, items };
  };

  return (
    <div style={{ padding: '0px', background: '#f5f5f5', borderRadius: '8px', margin: '10px 0' }}>
      {summary.map((section, index) => {
        const { title, items } = preprocessSection(section);
        return (
          <div key={index} style={{
              background: '#eef2f5',
              padding: '10px',
              margin: '10px 0',
              borderRadius: '4px',
              borderLeft: '5px solid #007bff'
            }}>
            
            <span style={title === 'Executive Summary:' || title === 'Sentiment Overview:' ? { textDecoration: 'underline' } : {}}>{title}</span>
            {items.map((item, itemIndex) => {
              const isTopicTitle = item.startsWith('Topic');
              return (
                <p key={itemIndex} style={{ 
                  marginTop: isTopicTitle ? (itemIndex === 0 ? '0' : '40px') : '5px', 
                  marginBottom: isTopicTitle ? '20px' : '5px',
                  marginLeft: (item.startsWith('-') || item.startsWith('\u2022')) ? '20px' : '0px',
                  fontWeight: isTopicTitle ? 'bold' : (item.match(/^\d+\..*/) ? 'bold' : 'normal')
                
                }}>
                    {(item.startsWith('-') || item.startsWith('\u2022')) ? `\u2022${item.slice(1)}` : item}
                </p>
              )
            })}
          </div>
        );
      })}
    </div>
  );
};

export default SummaryDisplayLatestD;