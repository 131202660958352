import React, { useState } from 'react';
import SummaryDisplayNews from './MicroProbe/Companies/SummaryDisplayNews';
import { newsSummary } from '../../services/newsSummary';
import styles from './TopicGather.module.css'; // Import the CSS module

const TopicGather = () => {
    const [keyword, setKeyword] = useState('');
    const [summaryData, setSummaryData] = useState(null);
    const [isLoadingSummary, setIsLoadingSummary] = useState(false); 

    const fetchSummary = async () => {
        setIsLoadingSummary(true); 
        setSummaryData(null); 
        try {
            const summary = await newsSummary(keyword); 
            setSummaryData(summary); 
        } catch (error) {
            console.error('Failed to fetch summary:', error);
        }
        setIsLoadingSummary(false); 
    }

    const handleInputChange = (event) => {
        setKeyword(event.target.value);
    }

    const handleSummaryClick = () => {
        fetchSummary();
    }

    return (
        <div className={styles.topicGatherContainer}>
            <h3>Topic Gather</h3>
            <input type="text" value={keyword} onChange={handleInputChange} placeholder="Search keyword" className={styles.keywordInput} style={{ padding: '10px' }} />
            <button onClick={handleSummaryClick} className={styles.summaryButton}>Summary</button>
            {isLoadingSummary && <div>Loading summary...</div>}
            {summaryData && <SummaryDisplayNews summary={summaryData.response} />}
        </div>
    );
}

export default TopicGather;