import React, { useState } from 'react';
import axios from '../../../../axiosConfig';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import colorbrewer from 'colorbrewer';
import styles from './LongTermOutlook.module.css'; // Import the CSS module

function LongTermOutlook({ inputData }) {
  const [loading, setLoading] = useState(false);
  const [chartsData, setChartsData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [responseMessage, setResponseMessage] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showData, setShowData] = useState(false); 

  const getLastFullYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    return today.getMonth() === 0 && today.getDate() === 1 ? currentYear : currentYear - 1;
  };

  const fetchData = async (buttonName) => {
    setLoading(true);
    setResponseMessage('');
    setChartsData([]);
    setCurrentCategory(buttonName);
    const lastFullYear = getLastFullYear();

    try {
      const response = await axios.get('fetch_imf_data/', {
        params: {
          code: inputData.code,
          category: buttonName
        }
      });

      const dataCategory = buttonName.replace(/\s+/g, '_');
      const data = response.data[dataCategory];

      if (!data || !data.values) {
        throw new Error(`Data for category ${dataCategory} not found.`);
      }

      const allYears = Object.keys(data.values).flatMap(key => Object.keys(data.values[key].values[inputData.code]));
      const minYear = Math.min(...allYears);
      const maxYear = Math.max(...allYears);

      setStartDate(minYear.toString());
      setEndDate(maxYear.toString());
      setOriginalData(data.values);

      const newChartsData = createChartsData(data.values, minYear.toString(), maxYear.toString(), lastFullYear);
      setChartsData(newChartsData);
    } catch (error) {
      console.error(`Error fetching data for ${buttonName}: ${error}`);
      setResponseMessage(`Error fetching data for ${buttonName}: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const createChartsData = (dataValues, startYear, endYear, lastFullYear) => {
    return Object.keys(dataValues).map(key => {
      const dataset = dataValues[key];
      if (dataset.values[inputData.code]) {
        const labels = Object.keys(dataset.values[inputData.code]).filter(year => year >= startYear && year <= endYear);
        const dataPoints = labels.map(year => ({
          x: year,
          y: dataset.values[inputData.code][year],
          future: parseInt(year) > lastFullYear, // Determine if the data point is in the future
          description: dataset.description, // Include description
          unit: dataset.unit // Include unit
        }));

        // Generate a single dataset with a color change for future data points
        const borderColor = getRandomColor();
        const futureColor = "#B0AFAB";
        const borderColors = dataPoints.map(point => point.future ? futureColor : borderColor);
        const pointBackgroundColors = dataPoints.map(point => point.future ? futureColor : borderColor);

        return {
          label: dataset.label,
          data: {
            labels,
            datasets: [
              {
                label: dataset.label,
                data: dataPoints, // Pass the full data points array
                borderColor: borderColors, // Apply color dynamically based on whether the data point is in the future
                pointBackgroundColor: pointBackgroundColors, // Apply color dynamically for dots
                fill: false
              }
            ]
          }
        };
      }
      return null;
    }).filter(chart => chart !== null);
  };

  const getRandomColor = () => {
    let palette = colorbrewer.Paired[12];

    const lightYellowIndex = palette.indexOf('#ffff99');
    if (lightYellowIndex !== -1) {
      palette[lightYellowIndex] = '#FEE12B'; // Darker yellow color
    }
    const randomIndex = Math.floor(Math.random() * palette.length);
    return palette[randomIndex];
  };

  const updateChart = () => {
    if (originalData.length === 0) return;
    const lastFullYear = getLastFullYear();
    const newChartsData = createChartsData(originalData, startDate, endDate, lastFullYear);
    setChartsData(newChartsData);
  };

  const categories = [
    'National Account',
    'Trade and Balance of Payment',
    'Labor Prices and Inflation',
    'Money and Banking',
    'Public Sector Finance and Debt',
    'Private Sector Finance and Debt'
  ];

  return (
    <div>
      <h3>Signals</h3>
      <h4>Long Term Outlook</h4>
      {!showData ? (
        <button onClick={() => setShowData(true)}>Get Data</button>
      ) : (
        <>
          <div className={styles.buttonContainer}>
            {categories.map((category, index) => (
              <button key={index} onClick={() => fetchData(category)} className={styles.button}>
                {category}
              </button>
            ))}
          </div>
          {loading ? <p>Loading...</p> : <p>{responseMessage}</p>}
          <div className={styles.container}>
            {chartsData.length > 0 && (
              <>

                <div className={styles.chartControls}>
                  <div>
                    <label className={styles.chartControlLabel}>
                      Start Year: 
                      <input type="number" value={startDate} onChange={(e) => setStartDate(e.target.value)} className={styles.chartControlInput} style={{ marginLeft: '4px' }}/>
                    </label>
                  </div>
                  <div>
                    <label className={styles.chartControlLabel}>
                      End Year: 
                      <input type="number" value={endDate} onChange={(e) => setEndDate(e.target.value)} className={styles.chartControlInput} style={{ marginLeft: '8px' }} />
                    </label>
                    <button onClick={updateChart} className={styles.updateButton}>Update Chart</button>
                  </div>
          
                </div>


                <div className={styles.chartHeader}>
                  <h2>{currentCategory}</h2>
                </div>
                <div className={styles.chartWrapper}> {/* Wrapper for the charts */}
                  {chartsData.map((chart, index) => (
                    <div key={index} className={styles.chartContainer}>
                      <div className={styles.scrollableChart}>
                        <h4>{chart.label}</h4>
                        <div className={styles.chartContent}>
                          <Line data={chart.data} options={{
                            scales: {
                              x: {
                                type: 'time',
                                time: {
                                  unit: 'year',
                                  tooltipFormat: 'yyyy', // Format tooltip to show only the year
                                }
                              }
                            },
                            
                            plugins: {
                              legend: {
                                display: false
                              },
                              tooltip: {
                                displayColors: false,
                                position: 'nearest',
                                bodyFont: {
                                  size: window.innerWidth < 768 ? 12 : 14 
                                },
                                titleFont: {
                                  size: window.innerWidth < 768 ? 12 : 14 
                                },
                                
                              
                                callbacks: {
                                  title: function (tooltipItems) {
                                    const date = new Date(tooltipItems[0].parsed.x);
                                    return date.getFullYear().toString();
                                  },
                                  label: function (tooltipItem) {
                                    const dataPoint = tooltipItem.raw;
                                    const description = "Description: " + (dataPoint.description || 'No description available'); // Added "Description: " prefix
                                    const unit = dataPoint.unit || 'No unit specified';
                                    const maxLineLength = 50; // Maximum characters per line
                                
                                    // Function to split description into multiple lines without splitting words
                                    const splitDescription = (desc, maxLength) => {
                                      const words = desc.split(' '); // Split the description into words
                                      const result = [];
                                      let currentLine = '';

                                      words.forEach(word => {
                                        if ((currentLine + word).length <= maxLength) {
                                          currentLine += `${word} `; // Add word to current line
                                        } else {
                                          result.push(currentLine.trim()); // Push current line to result and trim any trailing space
                                          currentLine = `${word} `; // Start a new line with the current word
                                        }
                                      });

                                      if (currentLine) {
                                        result.push(currentLine.trim()); // Push the last line to result
                                      }

                                      return result;
                                    };
                                
                                    const descriptionLines = splitDescription(description, maxLineLength);
                                
                                    return [
                                      `Value: ${dataPoint.y}`,
                                      ...descriptionLines, // Spread operator to include all description lines
                                      `Unit: ${unit}`
                                    ];
                                  }
                                }
                              }
                            }
                          }} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default LongTermOutlook;
