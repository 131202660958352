import React, { useState } from 'react';
import axios from '../../../../axiosConfig';
import { Line } from 'react-chartjs-2';
import PriceDataBox from './PriceDataBox'; 
import styles from '../../MicroProbe/Companies/SignalsBacktestTechnicalsPlot.module.css';



const SignalsBacktestTechnicalsPlot = ({ inputData }) => {
    const [backtestResults, setBacktestResults] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [chartKey, setChartKey] = useState(0); 
    const [startDate, setStartDate] = useState(''); 
    const [endDate, setEndDate] = useState(''); 
    const [showInputs, setShowInputs] = useState(false); 
    const [windowSize, setWindowSize] = useState(''); 

    const [from_symbol, setFromSymbol] = useState('USD');
    const [to_symbol, setToSymbol] = useState('USD');

    const handleFromForeignExchangeSelect = async (foreignExchangeName) => {
        try {
            const response = await axios.get(`/api/foreignexchange/name/${foreignExchangeName}`);
            setFromSymbol(response.data.code);
        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    };

    const handleToForeignExchangeSelect = async (foreignExchangeName) => {
        try {
            const response = await axios.get(`/api/foreignexchange/name/${foreignExchangeName}`);
            setToSymbol(response.data.code);
        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    };

    const fetchBacktestResults = async () => {
        if (!inputData || !inputData.code) {
            setError('No currency symbol provided');
            return;
        }
    
        try {
            setLoading(true);
            const response = await axios.get(`/backtest_signals_fx/`, {
                params: {
                    currency: inputData.name,
                    start_date: startDate,
                    end_date: endDate,
                    window: windowSize,
                    from_symbol: from_symbol,
                    to_symbol: to_symbol
                }
            });

            const data = response.data;
        
            const newBacktestResults = {};
            for (let indicator in data) {
                if (data[indicator].cumulative_returns && Object.keys(data[indicator].cumulative_returns).length > 0) {
                    const formattedDates = Object.keys(data[indicator].cumulative_returns);
                    const dataValues = Object.values(data[indicator].cumulative_returns);
            
                    const formattedData = {
                        labels: formattedDates,
                        datasets: [
                            {
                                label: 'Cumulative Returns',
                                data: dataValues,
                                borderColor: 'rgba(75,192,192,1)', 
                                fill: false,
                                pointRadius: 0,
                            }
                        ],
                        sharpe_ratio: data[indicator].sharpe_ratio,
                        current_trade_signal: data[indicator].current_trade_signal
                    };
            
                    newBacktestResults[indicator] = formattedData;
                } else {
                    setError(`No cumulative returns data received for ${indicator}`);
                }
            }
            setBacktestResults(newBacktestResults);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const handleClick = () => {
        setBacktestResults({}); 
        setError(null); 
        fetchBacktestResults();
        setChartKey(prevKey => prevKey + 1); 
    }

    const handleBacktestClick = () => {
        setShowInputs(prevShowInputs => !prevShowInputs); 
        if (showInputs) { 
            setBacktestResults({});
            setError(null);
        }
    }

    let signalMap = {
        '1': 'Buy',
        '0': 'Hold',
        '-1': 'Sell'
    };

    return (
        <div>
            <h5>Signals Backtest</h5>
            <button onClick={handleBacktestClick}>Back-test</button>
            {showInputs && (
                <div className={styles.inputsContainer}>
                    <div className={styles.inputRow} style={{ marginBottom: '5px', marginTop: '15px' }}>
                        <label className={styles.inputLabel}>From: </label>
                        <PriceDataBox onForeignExchangeSelect={handleFromForeignExchangeSelect} />
                    </div>
                    <div className={styles.inputRow} style={{ marginBottom: '5px' }}>
                        <label className={styles.inputLabel}>To: </label>
                        <PriceDataBox onForeignExchangeSelect={handleToForeignExchangeSelect} />
                    </div>
                    <div className={styles.inputRow} style={{ marginBottom: '5px' }}>
                        <label className={styles.inputLabel}>Start Date: </label>
                        <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
                    </div>
                    <div className={styles.inputRow} style={{ marginBottom: '5px' }}>
                        <label className={styles.inputLabel}>End Date: </label>
                        <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
                    </div>
                    <div className={styles.inputRow} style={{ marginBottom: '5px' }}>
                        <label className={styles.inputLabel}>Window: </label>
                        <input type="number" value={windowSize} onChange={e => setWindowSize(e.target.value)} min="1" placeholder="Days" className={styles.windowInput} />
                        <button onClick={handleClick} className={styles.showResultsButton}>Show Results</button>
                    </div>
                </div>
            )}

            {error && <div>Error: {error}</div>}
            {loading && <div>Loading...</div>}
            {Object.keys(backtestResults).map(indicator => (
                <div key={indicator} className={styles.chartContainer}>
                    <h4 style={{fontSize: '14px', paddingTop: '30px'}}>{`${indicator.replace(/_/g, ' ')}:`}</h4>
                    <div className={styles.chartWrapper}>
                        <Line 
                            data={backtestResults[indicator]} 
                            key={chartKey} 
                            options={{
                                plugins: {
                                    title: {
                                        display: true,
                                        text: `Sharpe Ratio: ${(backtestResults[indicator].sharpe_ratio * 100).toFixed(2)}%, Current Trade Signal: ${signalMap[backtestResults[indicator].current_trade_signal]}`
                                    },
                                    legend: {
                                        display: false
                                    }
                                },
                                scales: {
                                    x: {
                                        ticks: {
                                            maxRotation: 0, 
                                            autoSkipPadding: 30 
                                        }
                                    },
                                    y: {
                                        title: {
                                            display: true,
                                            text: 'Cumulative Return'
                                        }
                                    }
                                },
                                maintainAspectRatio: false,
                                responsive: true
                            }}
                        />
                    </div>
                </div>
            ))}
            {!loading && showInputs && Object.keys(backtestResults).length > 0 && (
                <p style={{fontSize: '13px', fontStyle: 'italic', paddingTop: '20px'}}>
                    Note: Transaction cost and interest rate are not accounted
                </p>
            )}
        </div>
    );
}

export default SignalsBacktestTechnicalsPlot;
