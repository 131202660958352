import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';

const ContactPage = () => {
  const form = useRef();
  const [messageStatus, setMessageStatus] = useState(''); // State to hold message status

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_wwi1bvt', 'template_9c4idb8', form.current, 'JTUPpUqzXBfH_B-D0')
      .then(
        (result) => {
          console.log('Message sent!', result.text);
          setMessageStatus('Message sent successfully!'); // Update status message on success
        },
        (error) => {
          console.log('Failed to send message', error.text);
          setMessageStatus('Failed to send message. Please try again.'); // Update status message on failure
        }
      );

    e.target.reset(); // Reset form after submission
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Help & Support</h2>
      <form ref={form} onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.field}>
          <label style={styles.label}>Work Email*</label>
          <input type="email" name="user_email" required style={styles.input} />
        </div>
        <div style={styles.field}>
          <label style={styles.label}>First Name*</label>
          <input type="text" name="first_name" required style={styles.input} />
        </div>
        <div style={styles.field}>
          <label style={styles.label}>Last Name*</label>
          <input type="text" name="last_name" required style={styles.input} />
        </div>
        <div style={styles.field}>
          <label style={styles.label}>Company Name</label>
          <input type="text" name="company_name" style={styles.input} />
        </div>
        <div style={styles.field}>
          <label style={styles.label}>Message*</label>
          <textarea name="message" required style={styles.textarea} />
        </div>
        <button type="submit" style={styles.button}>Send Message</button>
      </form>

      {/* Message to indicate success or error */}
      {messageStatus && (
        <div style={styles.statusMessage}>
          {messageStatus}
        </div>
      )}
    </div>
  );
};

export default ContactPage;

// Inline CSS styles for simplicity
const styles = {
  container: {
    width: '100%',
    maxWidth: '500px',
    margin: '50px auto',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'left',
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    marginBottom: '15px',
    marginRight: '15px',
  },
  label: {
    fontSize: '16px',
    marginBottom: '5px',
    color: '#555',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '14px',
    color: '#333',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '14px',
    color: '#333',
    minHeight: '150px',
  },
  button: {
    padding: '10px',
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
  statusMessage: {
    marginTop: '20px',
    fontSize: '16px',
    color: '#007BFF', // Success message color
    textAlign: 'center',
  },
};
