import React, { useState, useRef } from 'react';
import axiosInstance from '../../../../axiosConfig';
import axios from 'axios';
import styles from './ChatNumbers.module.css'; // Import the new CSS module

const ChatNumbersNearTermForecast = ({ inputData }) => {
  const [customerRequest, setCustomerRequest] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [displayChatHistory, setDisplayChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showChat, setShowChat] = useState(false); 
  const source = useRef(axios.CancelToken.source());

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const maxRetries = 8;
    const delay = 2000; // Retry delay in milliseconds
    let retries = 0;

    const fetchData = async () => {
      try {
        source.current.cancel('Operation canceled by the user.');
        source.current = axios.CancelToken.source();

        const response = await axiosInstance.post('/chat_with_numbers_near_term_forecast/', {
          customer_request: customerRequest,
          country: inputData.code,
          chat_history: chatHistory,
        }, {
          cancelToken: source.current.token,
        });

        // Check if the response indicates a connection error in response.data.error
        if (response.data.error === 'Connection error.') {
          throw new Error('Connection error.');
        }

        setChatHistory([
          ...chatHistory,
          { role: 'user', content: customerRequest },
          { role: 'assistant', content: response.data }, 
        ]);
        setDisplayChatHistory([
          ...displayChatHistory,
          { role: 'user', content: customerRequest },
          { role: 'assistant', content: typeof response.data === 'string' ? response.data.replace(/\n/g, '<br/>') : JSON.stringify(response.data, null, 2).replace(/\n/g, '<br/>') },
        ]);

        setCustomerRequest('');
        setResponseMessage('');
        setLoading(false);
      } catch (error) {
        console.error('There was an error!', error);

        if ((error.message === 'Connection error.' || (error.response && error.response.data && error.response.data.error === 'Connection error.')) && retries < maxRetries) {
          retries += 1;
          //console.log(`Connection error. Retrying (${retries}/${maxRetries}) in ${delay / 1000} seconds...`);
          setTimeout(fetchData, delay);
        } else {
          setResponseMessage(error.response ? error.response.data.error : 'An error occurred. Please try again.');
          setLoading(false);
        }
      }
    };

    fetchData();
  };

  const handleChatButtonClick = () => {
    setShowChat(!showChat); 
    if (showChat) { 
      setChatHistory([]); 
      setDisplayChatHistory([]); 
    }
  };

  return (
    <div>
      <button onClick={handleChatButtonClick} className={styles.chatButton}>Chat with Near Term Forecast</button>
      {showChat && ( 
        <div className={styles.chatContainer}>
          <div className={styles.chatBox}> 
            <div className={styles.chatHistory}>
              {displayChatHistory.map((message, index) => (
                <div key={index} className={styles.message}>
                  <strong>{message.role === 'user' ? 'You: ' : 'Assistant: '}</strong>
                  {message.role === 'user' ? message.content : <pre className={styles.messageContent} dangerouslySetInnerHTML={{ __html: message.content }} />}
                </div>
              ))}
            </div>

            {responseMessage && (
              <div className={styles.errorMessage}>
                {responseMessage}
              </div>
            )}

            <div className={styles.formContainer}>
              <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                <textarea 
                  value={customerRequest} 
                  onChange={e => { setCustomerRequest(e.target.value); setResponseMessage(''); }} 
                  placeholder={`Ask about numbers and include the category (button name)\ne.g.'... under Prices and Deflators'`}
                  rows="6" 
                  className={styles.textarea} 
                />
                <button type="submit" disabled={loading} className={styles.submitButton}>
                  Ask
                </button>
              </form>
              {loading && <p>Loading...</p>}
            </div>
          </div> 
        </div>
      )}
    </div>
  );
};

export default ChatNumbersNearTermForecast;
