import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TopicGather from './AIMarketDecoder/MicroProbe/MicroProbeTopicGather';
import styles from './Suite.module.css'; // Import the CSS module

const SmartSearchSuite = () => {
  const [activeComponent, setActiveComponent] = useState(null);

  const showTopicInsightInput = () => {
    setActiveComponent('topic');
  };

  return (
    <div>
      {/* Main container */}
      <div className={styles.container}>
        {/* Heading */}
        <h3 className={styles.heading}>Smart Search Engine</h3>

        {/* Buttons */}
        <div className={styles.buttonContainer}>
          <button 
            className={`${styles.button} ${activeComponent === 'topic' ? styles.active : ''}`} 
            onClick={showTopicInsightInput}
          >
            Topic Search
          </button>
        </div>
      </div>

      {/* Active Section */}
      <div className={`${styles.activeSectionSmartSearch} ${activeComponent === 'topic' ? styles.active : ''}`}>
        {activeComponent === 'topic' && <TopicGather />}
      </div>

      {/* Back link */}
      <div className={styles.backLinkContainer}>
        <Link to="/">Back</Link>
      </div>
    </div>
  );
};

export default SmartSearchSuite;
