import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Suite.module.css'; 
import MicroProbeCompany from './AIMarketDecoder/MicroProbe/MicroProbeSignals';
import BondFrontPage from './AIMarketDecoder/Bond/BondFrontPageSignals';
import CommodityFrontPage from './AIMarketDecoder/Commodity/CommodityFrontPageSignals';
import CryptoFrontPage from './AIMarketDecoder/Crypto/CryptoFrontPageSignals';
import EconomyFrontPage from './AIMarketDecoder/Economy/EconomyFrontPageSignals';
import ForeignExchangeFrontPage from './AIMarketDecoder/ForeignExchange/ForeignExchangeFrontPageSignals';


const SignalsSuite = () => {
  const [activeComponent, setActiveComponent] = useState(null);

  const showCompaniesInput = () => {
    setActiveComponent('companies');
  };

  const showBondInput = () => {
    setActiveComponent('bond');
  };

  const showCommodityInput = () => {
    setActiveComponent('commodity');
  };

  const showCryptoInput = () => {
    setActiveComponent('crypto');
  };

  const showEconomyInput = () => {
    setActiveComponent('economy');
  };

  const showForeignExchangeInput = () => {
    setActiveComponent('foreignExchange');
  };

  return (
    <div>
      {/* Main container */}
      <div className={styles.container}>
        {/* Heading */}
        <h3 className={styles.heading}>AI-Based Signals Suite</h3>

        {/* Buttons */}
        <div className={styles.buttonContainer}>
          <button className={`${styles.button} ${activeComponent === 'companies' ? styles.active : ''}`} onClick={showCompaniesInput}>Companies</button>
          <button className={`${styles.button} ${activeComponent === 'foreignExchange' ? styles.active : ''}`} onClick={showForeignExchangeInput}>Foreign Exchange</button>
          <button className={`${styles.button} ${activeComponent === 'bond' ? styles.active : ''}`} onClick={showBondInput}>Bond</button>
          <button className={`${styles.button} ${activeComponent === 'commodity' ? styles.active : ''}`} onClick={showCommodityInput}>Commodity</button>
          <button className={`${styles.button} ${activeComponent === 'crypto' ? styles.active : ''}`} onClick={showCryptoInput}>Crypto</button>
          <button className={`${styles.button} ${activeComponent === 'economy' ? styles.active : ''}`} onClick={showEconomyInput}>Economy</button>
        </div>
      </div>

      {/* Active Section */}
      <div className={`${styles.activeSection} ${activeComponent ? styles.active : ''}`}>
        {activeComponent === 'companies' && (
          <div className={styles.companyInputBox}>
            <MicroProbeCompany />
          </div>
        )}
        {activeComponent === 'bond' && (
          <div className={styles.bondInputBox}>
            <BondFrontPage />
          </div>
        )}
        {activeComponent === 'commodity' && (
          <div className={styles.commodityInputBox}>
            <CommodityFrontPage />
          </div>
        )}
        {activeComponent === 'crypto' && (
          <div className={styles.cryptoInputBox}>
            <CryptoFrontPage />
          </div>
        )}
        {activeComponent === 'economy' && (
          <div className={styles.economyInputBox}>
            <EconomyFrontPage />
          </div>
        )}
        {activeComponent === 'foreignExchange' && (
          <div className={styles.foreignExchangeInputBox}>
            <ForeignExchangeFrontPage />
          </div>
        )}
      </div>

      {/* Back link */}
      <div className={styles.backLinkContainer}>
        <Link to="/">Back</Link>
      </div>
    </div>
  );
};

export default SignalsSuite;