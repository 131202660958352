import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import axiosInstance from '../../../axiosConfig';
//import SummaryDisplay from './Companies/SummaryDisplay';
import SummaryDisplay from './SummaryDisplayVideos';
import mammoth from 'mammoth';
import { getDocument } from 'pdfjs-dist';
import JSZip from 'jszip';
import '../pdf.worker.entry';
import styles from './MicroProbe.module.css';

const DocumentUpload = () => {
  const [file, setFile] = useState(null);
  const [question, setQuestion] = useState('');
  const [summary, setSummary] = useState([]);
  const [loadingHighlights, setLoadingHighlights] = useState(false);
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const source = useRef(axios.CancelToken.source());
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [extractedText, setExtractedText] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return;

    const fileType = file.name.split('.').pop().toLowerCase();
    let text = '';

    if (fileType === 'docx') {
      text = await extractTextFromWord(file);
    } else if (fileType === 'pdf') {
      text = await extractTextFromPDF(file);
    } else if (fileType === 'pptx') {
      text = await extractTextFromPPT(file);
    } else if (fileType === 'txt') {
      text = await extractTextFromTXT(file);
    } else {
      alert('Unsupported file type');
    }

    setExtractedText(text);
    setIsFileUploaded(true);
    setConversationHistory([]);
    setQuestion('');
  };

  const extractTextFromWord = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer });
    return result.value;
  };

  const extractTextFromPDF = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await getDocument({ data: arrayBuffer }).promise;
    let text = '';

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      text += content.items.map(item => item.str).join(' ');
    }

    return text;
  };

  const extractTextFromPPT = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const zip = await JSZip.loadAsync(arrayBuffer);
    const files = zip.folder('ppt/slides').file(/.xml/);
    let text = '';

    for (let file of files) {
      const content = await file.async('string');
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(content, "text/xml");
      const textElements = xmlDoc.getElementsByTagName('a:t');
      for (let i = 0; i < textElements.length; i++) {
        text += textElements[i].textContent + ' ';
      }
    }

    return text;
  };

  const extractTextFromTXT = async (file) => {
    const text = await file.text();
    return text;
  };

  const handleKeyHighlightsClick = async () => {

    if (extractedText) {
      setLoadingHighlights(true);
      setSummary(null);
      try {
        source.current.cancel('Operation canceled by the user.');
        source.current = axios.CancelToken.source();

        const response = await axiosInstance.post(`/document_transcript_summary/`, {
          transcript: extractedText,
          cancelToken: source.current.token,
        });
        //const parsedResponse = JSON.parse(response.data.response);

        const parsedResponse = response.data.response;
        setSummary(parsedResponse);
      } catch (error) {
        if (axios.isCancel(error)) {

        } else {
          console.error(error);
        }
      }
      setLoadingHighlights(false);
    }
  };

  const handleAskQuestionClick = async () => {
    if (extractedText && question) {
      setLoadingQuestion(true);
      try {
        source.current.cancel('Operation canceled by the user.');
        source.current = axios.CancelToken.source();
        const response = await axiosInstance.post('/document_chat/', {
          question: question,
          chat_history: conversationHistory,
          transcript: extractedText
        }, {
          cancelToken: source.current.token,
        });
        setConversationHistory([...conversationHistory, { question: question, answer: response.data.answer }]);
        setQuestion('');
      } catch (error) {
        if (axios.isCancel(error)) {

        } else {
          console.error('Error fetching answer:', error);
        }
      }
      setLoadingQuestion(false);
    }
  };

  const handleChatClick = () => {
    setShowChat(!showChat);
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.section} ${styles.sectionQuarter}`}>
        <div className={styles.sectionContent}>
          <h4>Document Insight</h4>
          <div>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleFileUpload}>Upload</button>
          </div>
          {isFileUploaded && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginTop: '10px' }}>
              <div style={{ flex: 1 }}>
                <button onClick={handleKeyHighlightsClick}>Get Key Highlights</button>
                {loadingHighlights && <p>Loading...</p>}
                {summary && summary.length > 0 && <SummaryDisplay summary={summary} />}
              </div>
              <div style={{ flex: 1 }}>
                <button onClick={handleChatClick}>Chat</button>
                {showChat && (
                  <div style={{ 
                    overflowY: 'scroll', 
                    maxHeight: '600px', 
                    flexGrow: 1, 
                    padding: '20px', 
                    background: '#f5f5f5', 
                    borderRadius: '8px', 
                    margin: '10px 0' 
                  }}>
                    {conversationHistory.length > 0 && (
                      <div>
                        <h3>Conversation History:</h3>
                        {conversationHistory.map((qaPair, index) => (
                          <div key={index}>
                            <h4>Q: {qaPair.question}</h4>
                            <p>A: {qaPair.answer}</p>
                          </div>
                        ))}
                      </div>
                    )}
                    <div>
                      <input
                        type="text"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        placeholder="Ask about the document..."
                        style={{ width: 'calc(100% - 60px)', padding: '4px', fontSize: '14px' }}
                      />
                      <button onClick={handleAskQuestionClick}>Ask</button>
                      {loadingQuestion && <p>Loading...</p>}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Link to="/text-videos-suite">Back</Link>
      </div>
    </div>
  );
}

export default DocumentUpload;
