import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import InsightExplorer from './AIMarketDecoder/MicroProbe/Companies/InsightExplorer';
import styles from './Suite.module.css'; // Use the same CSS module as in code 2

const AgentsSuite = () => {
  const [activeComponent, setActiveComponent] = useState(null);

  const showChatAgentInput = () => {
    setActiveComponent('insight explorer'); // Set the active component
  };

  return (
    <div>
      {/* Main container */}
      <div className={styles.container}> 
        {/* Heading */}
        <h3 className={styles.heading}>Investment Agent</h3>  
        {/* Buttons */}
        <div className={styles.buttonContainer}>
          <button 
            className={`${styles.button} ${activeComponent === 'insight explorer' ? styles.active : ''}`} 
            onClick={showChatAgentInput}
          >
            Insight Explorer
          </button>
        </div>
      </div>

      {/* Active Section */}
      <div className={`${styles.activeSection} ${activeComponent === 'insight explorer' ? styles.active : ''}`}>
        {activeComponent === 'insight explorer' && <InsightExplorer />}
      </div>

      {/* Back link */}
      <div className={styles.backLinkContainer}>
        <Link to="/">Back</Link>
      </div> 
    </div>
  );
};

export default AgentsSuite;
