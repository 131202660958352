import React from 'react';

const SummaryDisplay = ({ summary }) => {
  const preprocessSection = (sectionString) => {
    const lines = sectionString.split('\n').filter(line => line.trim() !== '');
    const startIndex = lines.findIndex(line => line.match(/^\d+\..*:/));
    const relevantLines = startIndex !== -1 ? lines.slice(startIndex) : lines;

    if (relevantLines.length > 0) {
      const title = relevantLines[0];
      const items = relevantLines.slice(1);
      return { title, items };
    }
    return { title: '', items: [] };
  };

  const firstBoldIndex = summary.findIndex(section => {
    const { title } = preprocessSection(section);
    return title.match(/^\d+\..*:/);
  });

  const sectionsToDisplay = firstBoldIndex === -1 ? summary : summary.slice(firstBoldIndex);

  return (
    <div style={{ padding: '20px', background: '#f5f5f5', borderRadius: '8px', margin: '10px 0' }}>
      {sectionsToDisplay.map((section, index) => {
        const { title, items } = preprocessSection(section);
        return (
          <div key={index} style={{
            background: '#eef2f5',
            padding: '10px',
            margin: '10px 0',
            borderRadius: '4px',
            borderLeft: '5px solid #007bff'
          }}>
            {title.match(/^\d+\..*:/) ? (
              <>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {title.split(':')[0]}:
                </span>
                <span style={{ fontSize: '16px', fontWeight: 'normal' }}>
                  {title.split(':')[1]}
                </span>
              </>
            ) : (
              <span style={{ fontSize: '16px' }}>{title}</span>
            )}
            {items.map((item, itemIndex) => {
              const leadingSpaces = item.match(/^ */)[0].length;
              const baseIndent = item.trim().startsWith('-') ? 20 : 0;
              const additionalIndent = 20;
              return (
                <p key={itemIndex} style={{
                  margin: '10px 0',
                  marginLeft: baseIndent + leadingSpaces * additionalIndent + 'px',
                  fontWeight: 'normal'
                }}>
                  {item.trim().startsWith('-') ? `\u2022${item.trim().slice(1)}` : item}
                </p>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default SummaryDisplay;
