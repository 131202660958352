import React, { useState, useEffect, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import colorbrewer from 'colorbrewer';
import axios from '../../../../axiosConfig';
import styles from './ShortTermEconomics.module.css'; // Import the CSS module

const formatYAxisLabel = (yAxisLabel) => {
  const words = yAxisLabel.split(' ');
  return words.length > 3 ? `${words[0]}` : yAxisLabel;
};

function ShortTermEconomics({ inputData }) {
  const [loading, setLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [error, setError] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubtitle, setSelectedSubtitle] = useState(null);
  const [categories, setCategories] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [tempStartDate, setTempStartDate] = useState('');
  const [tempEndDate, setTempEndDate] = useState('');
  const [originalData, setOriginalData] = useState({});
  const [dataUnavailable, setDataUnavailable] = useState(false);
  const [activeButton, setActiveButton] = useState('');

  // Helper function to get a random color
  const getRandomColor = useCallback(() => {
    let palette = colorbrewer.Paired[12];
    const lightYellowIndex = palette.indexOf('#ffff99');
    if (lightYellowIndex !== -1) {
      palette.splice(lightYellowIndex, 1);
    }
    return palette[Math.floor(Math.random() * palette.length)];
  }, []);

  // Format chart data
  const formatChartData = useCallback((data) => {
    if (data.length === 0) return { labels: [], datasets: [] };

    const labels = data.map(item => item[0]);
    const borderColor = getRandomColor();

    return {
      labels,
      datasets: [
        {
          label: 'Value',
          data: data.map(item => item[1]),
          borderColor,
          backgroundColor: borderColor,
          fill: false,
          pointRadius: 2,
        }
      ]
    };
  }, [getRandomColor]);

  // Filter data within date range and format for chart
  const filterData = useCallback(
    (data) => {
      const parseDate = (dateStr) => {
        if (dateStr.includes('-Q')) {
          const [year, quarter] = dateStr.split('-Q');
          const month = (parseInt(quarter, 10) - 1) * 3;
          return new Date(year, month, 1);
        } else {
          return new Date(dateStr);
        }
      };

      const start = startDate ? parseDate(startDate) : null;
      const end = endDate ? parseDate(endDate) : null;

      const filteredData = Object.keys(data).reduce((acc, key) => {
        const filtered = data[key].filter(([date]) => {
          const itemDate = parseDate(date);
          return (!start || itemDate >= start) && (!end || itemDate <= end);
        });
        if (filtered.length) {
          acc[key] = filtered;
        }
        return acc;
      }, {});

      const formattedData = Object.keys(filteredData).map(key => ({
        title: key,
        data: formatChartData(filteredData[key])
      }));

      setChartData(formattedData);
    },
    [startDate, endDate, formatChartData]
  );

  // Reset date filters
  const resetDates = useCallback(() => {
    setStartDate('');
    setTempStartDate('');
    setEndDate('');
    setTempEndDate('');
  }, []);

  // Fetch categories
  const initiateLoading = useCallback(async () => {
    setInitialLoad(true);
    setCategoriesLoading(true);
    setDataUnavailable(false);
    try {
      const response = await axios.get(`/available-buttons/${inputData.code}/`);
      setCategories(response.data);
      if (response.data.length === 0) {
        setDataUnavailable(true);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to fetch categories');
    } finally {
      setCategoriesLoading(false);
    }
  }, [inputData.code]);

  // Fetch data based on category selection
  const fetchData = useCallback(
    async (category) => {
      setSelectedCategory(category);
      setLoading(true);
      setError(null);
      setHasFetched(false);

      if (!originalData[category]) {
        try {
          const response = await axios.get(`/get_short_term_econ_data?code=${inputData.code}&category=${category}`);
          const data = response.data;
          setOriginalData(prevData => ({ ...prevData, [category]: data }));
          filterData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
          setError('Failed to fetch data');
        } finally {
          setLoading(false);
          setHasFetched(true);
        }
      } else {
        filterData(originalData[category]);
        setLoading(false);
        setHasFetched(true);
      }
    },
    [inputData.code, originalData, filterData]
  );

  // Handle subtitle click
  const handleSubtitleClick = useCallback(
    (subtitle) => {
      setSelectedSubtitle(subtitle === selectedSubtitle ? null : subtitle);
    },
    [selectedSubtitle]
  );

  // Reset dates and fetch data on category change
  const resetDatesAndFetchData = (category) => {
    resetDates();
    setSelectedCategory(category);
    setActiveButton(category);
    fetchData(category);
  };

  // Automatically filter data when dates or category changes
  useEffect(() => {
    if (selectedCategory && originalData[selectedCategory]) {
      filterData(originalData[selectedCategory]);
    }
  }, [startDate, endDate, selectedCategory, originalData, filterData]);

  return (
    <div className={styles.container}>
      <h3>Numbers</h3>
      {!initialLoad ? (
        <button className={styles.getDataButton} onClick={initiateLoading} >Get Data</button>
      ) : (
        <>
          {categoriesLoading ? <p>Loading ...</p> : (
            <>
              {dataUnavailable ? <p>Data is not available, please check out Long Term Outlook section </p> : (
                categories.map((group, groupIndex) => (
                  <div key={groupIndex}>
                    <button onClick={() => handleSubtitleClick(group.subtitle)} style={{ marginBottom: '10px' }}>
                      {group.subtitle}
                    </button>
                    {selectedSubtitle === group.subtitle && (
                      <div className={styles.buttonContainer}>
                        {group.items.map((category, index) => (
                          <button
                            key={index}
                            onClick={() => resetDatesAndFetchData(category)}
                            className={`${styles.button} ${activeButton === category ? styles.buttonActive : ''}`}
                          >
                            {category}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              )}
            </>
          )}
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              {hasFetched && chartData.length > 0 && (
                <div className={styles.chartControls}>
                  <label>Start Date: </label>
                  <input type="date" value={tempStartDate} onChange={e => setTempStartDate(e.target.value)} />

                  <label style={{ marginLeft: '0px' }}>End Date: </label>
                  <input type="date" value={tempEndDate} onChange={e => setTempEndDate(e.target.value)} />

                  <button style={{ marginLeft: '0px' }} onClick={() => {
                    setStartDate(tempStartDate);
                    setEndDate(tempEndDate);
                  }}>Update Chart</button>
                </div>
              )}

              {selectedCategory && hasFetched && chartData.length > 0 && (
                <h2 style={{ textAlign: 'center', marginTop: '40px', marginBottom: '10px' }}>{selectedCategory}</h2>
              )}

              {hasFetched && chartData.length === 0 ? (
                <p>Data is not available</p>
              ) : (
                <div className={styles.chartWrapper}>
                  {chartData.map((chart, index) => {
                    const [chartTitle, yAxisLabel] = chart.title.includes('(')
                      ? [
                          chart.title.substring(0, chart.title.indexOf('(')).trim(),
                          chart.title.substring(chart.title.indexOf('(') + 1, chart.title.lastIndexOf(')')).trim()
                        ]
                      : [chart.title.replace(/_/g, ' '), ''];

                    return (
                      <div key={index} className={styles.chartContainer}>
                        <div className={styles.scrollableChart}>
                          <h4>{chartTitle}</h4>
                          <div className={styles.chartContent}>
                            <Line
                              data={chart.data}
                              options={{
                                responsive: true,
                                plugins: {
                                  legend: {
                                    display: false
                                  },
                                  tooltip: {
                                    displayColors: false,
                                    callbacks: {
                                      title: function (tooltipItems) {
                                        const label = tooltipItems[0].label;
                                        return `Date: ${label}`;
                                      },
                                      label: function (tooltipItem) {
                                        return `Value: ${tooltipItem.parsed.y}`;
                                      }
                                    }
                                  }
                                },
                                scales: {
                                  x: {
                                    title: {
                                      display: false
                                    }
                                  },
                                  y: {
                                    title: {
                                      display: true,
                                      text: formatYAxisLabel(yAxisLabel),
                                      font: {
                                        size: 15 // Default size
                                      },
                                      className: styles.yAxisLabel
                                    }
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {selectedCategory === 'Household Indicators' && (
                <p style={{ marginTop: '2em', fontStyle: 'italic', fontSize: '0.8em' }}>
                  *GDP is based on Chain linked volume (rebased).
                </p>
              )}
              {selectedCategory === 'GDP - Expenditure Approach' && (
                <p style={{ marginTop: '2em', fontStyle: 'italic', fontSize: '0.8em' }}>
                  *GDP and components are measured in millions.
                </p>
              )}
              {selectedCategory === 'GDP - Output Approach' && (
                <p style={{ marginTop: '2em', fontStyle: 'italic', fontSize: '0.8em' }}>
                  *GDP and components are measured in millions.
                </p>
              )}
              {selectedCategory === 'GDP - Income Approach' && (
                <p style={{ marginTop: '2em', fontStyle: 'italic', fontSize: '0.8em' }}>
                  *GDP and components are measured in millions.
                </p>
              )}
              {selectedCategory === 'International Trade' && (
                <p style={{ marginTop: '2em', fontStyle: 'italic', fontSize: '0.8em' }}>
                  *International Trade is measured in billions.
                </p>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ShortTermEconomics;
