import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from './axiosConfig';

// Set axios baseURL to the environment variable or fallback to production URL
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL || 'https://api.fina-ai.org';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
