import React, { useState } from 'react';
import axios from '../../../../axiosConfig';
import SummaryDisplayForecastTechnicals from './SummaryDisplayForecastTechnicals';

const SignalsBacktestTechnicals = ({ inputData }) => {
    const [predictionResults, setPredictionResults] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [forecastHorizon, setForecastHorizon] = useState(7); // default forecast horizon is 7 days
    const [showInput, setShowInput] = useState(false);


    const fetchPredictionResults = async () => {
        if (!inputData || !inputData.code) {
            setError('No commodity symbol provided');
            return;
        }
    
        setLoading(true);
        try {
            const response = await axios.get(`/predict_stock_prices_technicals_commodity/`, {
                params: {
                    forecast_horizon: forecastHorizon,
                    commodityCode: inputData.code, 
                    commodityName: inputData.name,
                   
                }
            });
            let data = response.data.response;
            // If data is a string, wrap it in an array
            if (typeof data === 'string') {
                data = [data];
            }
            setPredictionResults(data);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    const handlePredictionClick = () => {
        setShowInput(true);
    }

    const handleGoClick = () => {
        setPredictionResults(null); 
        setError(null); 
        fetchPredictionResults();
    }


    return (
        <div>
            <h4>Forecast</h4>
       
            <button onClick={handlePredictionClick} disabled={loading}>
                Predict commodity Price
            </button>
            {showInput && (
                <div>
                    <label style={{fontSize: 'small'}}>Forecast Horizon: </label>
                    <input 
                        type="number" 
                        value={forecastHorizon} 
                        onChange={(e) => setForecastHorizon(e.target.value)} 
                        placeholder="Days"
                        style={{width: '50px'}}
                    />
                    <button onClick={handleGoClick} disabled={loading}>
                        Go
                    </button>
                </div>
            )}
            {loading && <div>Loading...</div>}
            {predictionResults && <SummaryDisplayForecastTechnicals summary={predictionResults} />}
            {/*{predictionResults && <div>{JSON.stringify(predictionResults)}</div>} */}
            {error && <div>Error: {error}</div>}
        </div>
    );
}

export default SignalsBacktestTechnicals;