import React, { useState, useEffect } from 'react';
import { fetchFinancialData, fetchFinancialDataPrice } from '../../../../services/financialDataService';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import styles from './FinancialData.module.css'; // Import CSS module
import CustomDropdown from './CustomDropdownFinancialData';


ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement);

function FinancialData({ companyData }) {
  const [financialData, setFinancialData] = useState({});
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({});
  const [currentView, setCurrentView] = useState('');
  const [metricSelection, setMetricSelection] = useState('');
  const [dataPeriod, setDataPeriod] = useState('ANNUAL');
  const [displayNote, setDisplayNote] = useState(false);
  const [selectedButton, setSelectedButton] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Define available metrics for each category
  const metricOptions = {
    TIME_SERIES_DAILY_ADJUSTED: [
      "5. adjusted close",
      "1. open",
      "2. high",
      "3. low",
      "4. close",
      "6. volume",
      "7. dividend amount",
      "8. split coefficient"
    ],
    INCOME_STATEMENT: [
      'grossProfit',
      'totalRevenue',
      'costOfRevenue',
      'costofGoodsAndServicesSold',
      'operatingIncome',
      'sellingGeneralAndAdministrative',
      'researchAndDevelopment',
      'operatingExpenses',
      'investmentIncomeNet',
      'netInterestIncome',
      'interestIncome',
      'interestExpense',
      'nonInterestIncome',
      'otherNonOperatingIncome',
      'depreciation',
      'depreciationAndAmortization',
      'incomeBeforeTax',
      'incomeTaxExpense',
      'interestAndDebtExpense',
      'netIncomeFromContinuingOperations',
      'comprehensiveIncomeNetOfTax',
      'ebit',
      'ebitda',
      'netIncome'
    ],
    BALANCE_SHEET: [
      'totalAssets', 'totalCurrentAssets', 'cashAndCashEquivalentsAtCarryingValue',
      'cashAndShortTermInvestments', 'inventory', 'currentNetReceivables',
      'totalNonCurrentAssets', 'propertyPlantEquipment', 'accumulatedDepreciationAmortizationPPE',
      'intangibleAssets', 'intangibleAssetsExcludingGoodwill', 'goodwill',
      'investments', 'longTermInvestments', 'shortTermInvestments', 'otherCurrentAssets',
      'otherNonCurrentAssets', 'totalLiabilities', 'totalCurrentLiabilities',
      'currentAccountsPayable', 'deferredRevenue', 'currentDebt', 'shortTermDebt',
      'totalNonCurrentLiabilities', 'capitalLeaseObligations', 'longTermDebt',
      'currentLongTermDebt', 'longTermDebtNoncurrent', 'shortLongTermDebtTotal',
      'otherCurrentLiabilities', 'otherNonCurrentLiabilities', 'totalShareholderEquity',
      'treasuryStock', 'retainedEarnings', 'commonStock', 'commonStockSharesOutstanding'
    ],
    CASH_FLOW: [
      'operatingCashflow', 'paymentsForOperatingActivities', 'proceedsFromOperatingActivities',
      'changeInOperatingLiabilities', 'changeInOperatingAssets', 'depreciationDepletionAndAmortization',
      'capitalExpenditures', 'changeInReceivables', 'changeInInventory', 'profitLoss',
      'cashflowFromInvestment', 'cashflowFromFinancing', 'proceedsFromRepaymentsOfShortTermDebt',
      'paymentsForRepurchaseOfCommonStock', 'paymentsForRepurchaseOfEquity',
      'paymentsForRepurchaseOfPreferredStock', 'dividendPayout', 'dividendPayoutCommonStock',
      'dividendPayoutPreferredStock', 'proceedsFromIssuanceOfCommonStock',
      'proceedsFromIssuanceOfLongTermDebtAndCapitalSecuritiesNet', 'proceedsFromIssuanceOfPreferredStock',
      'proceedsFromRepurchaseOfEquity', 'proceedsFromSaleOfTreasuryStock',
      'changeInCashAndCashEquivalents', 'changeInExchangeRate', 'netIncome'
    ],
    EARNINGS: ['reportedEPS', 'estimatedEPS', 'surprise', 'surprisePercentage']
  };

  const handleButtonClick = async (functionType) => {
    setLoading(true);
    setSelectedButton(functionType);
    setCurrentView(functionType);
    setMetricSelection('');
    let data;
    if (functionType === 'TIME_SERIES_DAILY_ADJUSTED') {
      data = await fetchFinancialDataPrice(functionType, companyData.symbol, 'full');
    } else {
      data = await fetchFinancialData(functionType, companyData.symbol, dataPeriod);
    }
    setFinancialData(data);
    setLoading(false);
  };

  useEffect(() => {
    let reports = [];
    if (currentView === 'TIME_SERIES_DAILY_ADJUSTED') {
      reports = Object.entries(financialData['Time Series (Daily)'] || {}).map(([date, data]) => ({ ...data, fiscalDateEnding: date }));
    } else if (currentView === 'EARNINGS') {
      reports = dataPeriod === 'ANNUAL' ? financialData.annualEarnings || [] : financialData.quarterlyEarnings || [];
    } else {
      reports = financialData[dataPeriod.toLowerCase() + 'Reports'] || [];
    }

    if (metricSelection && reports.length > 0) {
      reports = reports.filter(report => {
        const reportDate = new Date(report.fiscalDateEnding);
        return (!startDate || reportDate >= new Date(startDate)) && (!endDate || reportDate <= new Date(endDate));
      });

      if (currentView === 'EARNINGS' && dataPeriod === 'ANNUAL' && ['estimatedEPS', 'surprise', 'surprisePercentage'].includes(metricSelection)) {
        setDisplayNote(true);
      } else {
        const labels = reports.map(report => report.fiscalDateEnding).reverse();
        const dataPoints = reports.map(report => parseFloat(report[metricSelection]) || 0).reverse();

        if (metricSelection === 'reportedEPS' && parseFloat(dataPoints[dataPoints.length - 1]) === 0) {
          dataPoints.pop();
          labels.pop();
        }

        const datasets = {
          label: metricSelection.replace(/^\d+\.\s/, ''),
          data: dataPoints,
          fill: false,
          backgroundColor: 'rgb(75, 192, 192)',
          borderColor: 'rgba(75, 192, 192, 0.2)',
        };

        if (currentView === 'TIME_SERIES_DAILY_ADJUSTED') {
          datasets.borderColor = 'rgb(75, 192, 192)';
          datasets.pointRadius = 0;
        }

        setChartData({
          labels,
          datasets: [datasets]
        });

        setDisplayNote(false);
      }
    }
  }, [metricSelection, financialData, currentView, dataPeriod, startDate, endDate]);

  const renderChartOrNote = () => {
    if (displayNote) {
      return <p>Please check quarterly data.</p>;
    } else {
      const ChartComponent = Line;
      return (
        <div className={styles.chartContainer}>
          <div className={styles.scrollableChart}>
            <div className={styles.chartContent}>
              <ChartComponent
                data={chartData}
                options={{
                  responsive: true,
                  scales: {
                    x: {
                      ticks: {
                        maxRotation: 0,
                        autoSkipPadding: 20
                      }
                    }
                  },
                  plugins: {
                    tooltip: {
                      bodyFont: {
                        size: window.innerWidth < 768 ? 12 : 14 // Adjust font size for mobile
                      },
                      titleFont: {
                        size: window.innerWidth < 768 ? 12 : 14 // Adjust title font size for mobile
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <h4>Historical</h4>
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.button} ${selectedButton === 'TIME_SERIES_DAILY_ADJUSTED' ? styles.buttonActive : ''}`}
          onClick={() => handleButtonClick('TIME_SERIES_DAILY_ADJUSTED')}
        >
          Stock Metrics
        </button>
        <button
          className={`${styles.button} ${selectedButton === 'INCOME_STATEMENT' ? styles.buttonActive : ''}`}
          onClick={() => handleButtonClick('INCOME_STATEMENT')}
        >
          Income Statement
        </button>
        <button
          className={`${styles.button} ${selectedButton === 'BALANCE_SHEET' ? styles.buttonActive : ''}`}
          onClick={() => handleButtonClick('BALANCE_SHEET')}
        >
          Balance Sheet
        </button>
        <button
          className={`${styles.button} ${selectedButton === 'CASH_FLOW' ? styles.buttonActive : ''}`}
          onClick={() => handleButtonClick('CASH_FLOW')}
        >
          Cash Flow
        </button>
        <button
          className={`${styles.button} ${selectedButton === 'EARNINGS' ? styles.buttonActive : ''}`}
          onClick={() => handleButtonClick('EARNINGS')}
        >
          Earnings
        </button>
      </div>

      {currentView && (
        <div className={styles.chartControls}>
          {/*}
          <select onChange={handleMetricChange} value={metricSelection}>
            <option value="">Select a Metric</option>
            {metricOptions[currentView] && metricOptions[currentView].map((option, index) => (
              <option key={index} value={option}>{option.replace(/^\d+\.\s/, '')}</option>
            ))}
          </select>  */}


          <CustomDropdown
            options={metricOptions[currentView]}
            onSelect={(option) => setMetricSelection(option)}
            placeholder="Select a Metric"
            resetTrigger={currentView} // Reset when currentView changes
          />

          {(currentView === 'EARNINGS' || currentView === 'INCOME_STATEMENT' || currentView === 'BALANCE_SHEET' || currentView === 'CASH_FLOW') && (
            <CustomDropdown
              options={['ANNUAL', 'QUARTERLY']}
              onSelect={(option) => setDataPeriod(option)}
              placeholder="Select Data Period"
              resetTrigger={currentView} // Reset when currentView changes
            />
          )}


          <div className={styles.dateInputs}>
            <div>
              <label>From:</label>
              <input type="date" onChange={(e) => setStartDate(e.target.value)} value={startDate} />
            </div>
            <div>
              <label>To:</label>
              <input type="date" onChange={(e) => setEndDate(e.target.value)} value={endDate} />
            </div>
          </div>
        </div>

        
      )}
      {loading ? <p>Loading...</p> : (metricSelection && Object.keys(chartData).length > 0 ? renderChartOrNote() : null)}
    </div>
  );
}

export default FinancialData;
