import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../../../../axiosConfig';
import styles from '../../MicroProbe//MicroProbe.module.css';
import ChatNumbersShortTermEconomics from './ChatNumbersShortTermEconomics';
import ShortTermEconomics from './ShortTermEconomics';



const CountriesNumbers = () => {
  const { economyName } = useParams();
  const [inputData, setInputData] = useState(null);
  
  useEffect(() => {
    const fetchInputData = async () => {
      try {
        const response = await axios.get(`/api/country/name/${economyName}`);
        setInputData(response.data);

      } catch (error) {
        console.error('Error fetching currency data:', error);
      }
    };
  
    fetchInputData();
  }, [economyName]);


return (
  <div className={styles.container}>
    <h1 className={styles.title}>{economyName }</h1>

    {inputData && (
      <>  

  
        <div className={`${styles.section} ${styles.sectionQuarter}`}>

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto%' }}>
            <ShortTermEconomics inputData={inputData} />
          </div>
          <div className={styles.dividernone}></div>

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <ChatNumbersShortTermEconomics inputData={inputData} />
          </div>
          <div className={styles.dividernone}></div>
        </div>

      </>
    )}

    <div style={{ marginTop: '20px' }}>
      <Link to="/numbers-suite">Back</Link>
      </div> 
  </div>
);
};

export default CountriesNumbers;