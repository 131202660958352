import React, { useState, useRef } from 'react';
import SummaryDisplayIdeaMaker from './SummaryDisplayIdeaMaker';
import DocumentUpload from './DocumentUpload';
import axios from '../../axiosConfig';
import styles from './IdeasMaker.module.css';
import CustomDropdown from './IdeaMakerCustomDropdown';

const IdeasMaker = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [predictionResults, setPredictionResults] = useState(null);

  const [showDropdowns, setShowDropdowns] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [customFormat, setCustomFormat] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);

  const [selectedOption2, setSelectedOption2] = useState('');
  const [customFormat2, setCustomFormat2] = useState('');
  const [showCustomInput2, setShowCustomInput2] = useState(false);

  const [selectedOption3, setSelectedOption3] = useState('');
  const [customFormat3, setCustomFormat3] = useState('');
  const [showCustomInput3, setShowCustomInput3] = useState(false);

  const [selectedOption4, setSelectedOption4] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [finalTexts, setFinalTexts] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const customInputRef = useRef();
  const customInputRef2 = useRef();
  const customInputRef3 = useRef();


  // Options for dropdowns
  const formatOptions = [
    'Full report',
    'Talking point',
    'Presentation',
    'Executive Summary',
    'White Papers',
    'Case study',
    'Memo',
    'Investor Update',
    'Create your own',
  ];

  const styleOptions = [
    'Financial analyst',
    'Sales person',
    'Researcher',
    'Journalist',
    'Investor',
    'Regulator',
    'Executive',
    'Stakeholder',
    'Risk analyst',
    "Devil's advocate",
    'Create your own',
  ];

  const toneOptions = [
    'Serious',
    'Neutral',
    'Optimistic',
    'Cautious',
    'Analytical',
    'Academic',
    'Conversational',
    'Investor-friendly',
    'Funny',
    'Poetic',
    'Pirate',
    'Fictional Warren Buffet',
    'Create your own',
  ];

  const contentOptions = [
    'News',
    'Sentiment',
    'Numbers',
    'Texts',
    'Signals',
    'Upload Document',
    'Additional Information',
  ];

  const handleButtonClick = () => {
    setShowResults(false);
    setFinalTexts([]);
    setSelectedOption('');
    setCustomFormat('');
    setSelectedOption2('');
    setCustomFormat2('');
    setSelectedOption3('');
    setCustomFormat3('');
    setSelectedOption4('');
    setInputValue('');
    setShowDropdowns(!showDropdowns);
    setTimeout(() => {
      setShowResults(true);
    }, 0);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option === 'Create your own') {
      setShowCustomInput(true);
      setCustomFormat('');
    } else {
      setShowCustomInput(false);
    }
  };

  const handleOptionChange2 = (option) => {
    setSelectedOption2(option);
    if (option === 'Create your own') {
      setShowCustomInput2(true);
      setCustomFormat2('');
    } else {
      setShowCustomInput2(false);
    }
  };

  const handleOptionChange3 = (option) => {
    setSelectedOption3(option);
    if (option === 'Create your own') {
      setShowCustomInput3(true);
      setCustomFormat3('');
    } else {
      setShowCustomInput3(false);
    }
  };

  const handleOptionChange4 = (option) => {
    setSelectedOption4(option);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleFinalizeClick = () => {
    if (selectedOption === 'Create your own') {
      setCustomFormat(customInputRef.current.value);
      customInputRef.current.value = '';
    }
  };

  const handleFinalizeClick2 = () => {
    if (selectedOption2 === 'Create your own') {
      setCustomFormat2(customInputRef2.current.value);
      customInputRef2.current.value = '';
    }
  };

  const handleFinalizeClick3 = () => {
    if (selectedOption3 === 'Create your own') {
      setCustomFormat3(customInputRef3.current.value);
      customInputRef3.current.value = '';
    }
  };

  const handleFinalizeClick4 = () => {
    const text = selectedOption4 + ': ' + inputValue;
    setFinalTexts([...finalTexts, text]);
    setInputValue('');
  };

  const handleDeleteClick = (index) => {
    const newFinalTexts = [...finalTexts];
    newFinalTexts.splice(index, 1);
    setFinalTexts(newFinalTexts);
  };

  const fetchPredictionResults = async () => {
    setPredictionResults(null);
    setError(null);

    setLoading(true);
    try {
      const response = await axios.post(`/idea_maker/`, {
        format: selectedOption === 'Create your own' ? customFormat : selectedOption,
        style: selectedOption2 === 'Create your own' ? customFormat2 : selectedOption2,
        tone: selectedOption3 === 'Create your own' ? customFormat3 : selectedOption3,
        content: finalTexts,
      });
      let data = response.data.response;
      if (typeof data === 'string') {
        data = [data];
      }
      setPredictionResults(data);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const handleTextExtracted = (text) => {
    const titledText = 'Document Content: ' + text;
    setFinalTexts([...finalTexts, titledText]);
  };

  return (
    <div className={styles.ideasMaker}>
      <h3>Idea Maker</h3>
      <button onClick={handleButtonClick} className={styles.createButton}>
        Create Ideas
      </button>
      <br />
      {showDropdowns && (
        <>
          <div className={styles.dropdownContainer}>
            <label className={styles.labelWithMargin} style={{ marginRight: '16px' }}>Format: </label>
            <CustomDropdown
              options={formatOptions}
              onSelect={handleOptionChange}
              placeholder="Select format"
            />
            {showCustomInput && (
              <div className={styles.customInputContainer}>
                <textarea
                  ref={customInputRef}
                  placeholder="Enter your format"
                  className={styles.customInput}
                />
                <button onClick={handleFinalizeClick}>Create</button>
              </div>
            )}
          </div>

          <div className={styles.dropdownContainer}>
            <label className={styles.labelWithMargin} style={{ marginRight: '31px' }}>Style: </label>
            <CustomDropdown
              options={styleOptions}
              onSelect={handleOptionChange2}
              placeholder="Select style"
            />
            {showCustomInput2 && (
              <div className={styles.customInputContainer}>
                <textarea
                  ref={customInputRef2}
                  placeholder="Enter your style"
                  className={styles.customInput}
                />
                <button onClick={handleFinalizeClick2}>Create</button>
              </div>
            )}
          </div>

          <div className={styles.dropdownContainer}>
            <label className={styles.labelWithMargin} style={{ marginRight: '32px' }}>Tone: </label>
            <CustomDropdown
              options={toneOptions}
              onSelect={handleOptionChange3}
              placeholder="Select tone"
            />
            {showCustomInput3 && (
              <div className={styles.customInputContainer}>
                <textarea
                  ref={customInputRef3}
                  placeholder="Enter your tone"
                  className={styles.customInput}
                />
                <button onClick={handleFinalizeClick3}>Create</button>
              </div>
            )}
          </div>

          <div className={styles.dropdownContainer}>
            <label className={styles.labelWithMargin}>Content: </label>
            <CustomDropdown
              options={contentOptions}
              onSelect={handleOptionChange4}
              placeholder="Select content"
            />
            {selectedOption4 !== '' && (
              <div className={styles.contentContainer}>
                {selectedOption4 === 'Upload Document' ? (
                  <DocumentUpload onTextExtracted={handleTextExtracted} />
                ) : (
                  <textarea
                    placeholder="Enter content from section outputs and your views here..."
                    value={inputValue}
                    onChange={handleInputChange}
                    className={styles.contentTextarea}
                  />
                )}
                {selectedOption4 !== 'Upload Document' && (
                  <button onClick={handleFinalizeClick4}>Create</button>
                )}
              </div>
            )}
          </div>
        </>
      )}

      {showResults && (
        <>
          {(selectedOption || selectedOption2 || selectedOption3 || finalTexts.length > 0) && (
            <div className={styles.resultContainer}>
              {selectedOption && (
                <p>
                  Format:{' '}
                  {selectedOption === 'Create your own' ? customFormat : selectedOption}
                </p>
              )}
              {selectedOption2 && (
                <p>
                  Style:{' '}
                  {selectedOption2 === 'Create your own' ? customFormat2 : selectedOption2}
                </p>
              )}
              {selectedOption3 && (
                <p>
                  Tone:{' '}
                  {selectedOption3 === 'Create your own' ? customFormat3 : selectedOption3}
                </p>
              )}
              {finalTexts.length > 0 && <p>Content:</p>}
              <ul>
                {finalTexts.map((text, index) => (
                  <li key={index}>
                    {text}
                    <button
                      onClick={() => handleDeleteClick(index)}
                      className={styles.deleteButton}
                    >
                      X
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <button onClick={fetchPredictionResults}>Generate Report</button>
          {loading && <p>Loading...</p>}
          {predictionResults && <SummaryDisplayIdeaMaker summary={predictionResults} />}
          {error && <p>Error: {error}</p>}
        </>
      )}
    </div>
  );
};

export default IdeasMaker;
