import React, { useState } from 'react';
import axios from '../../../../axiosConfig';
import SummaryDisplayForecastMacro from './SummaryDisplayForecastMacro';

const SignalsBacktestMacro = ({ companyData }) => {
    const [predictionResults, setPredictionResults] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchPredictionResults = async () => {
        if (!companyData || !companyData.symbol) {
            setError('No company symbol provided');
            return;
        }
    
        setLoading(true);
        try {
            const response = await axios.get(`/predict_stock_prices_macro/${companyData.symbol}`, {
                params: {
                    companyName: companyData.name // added company name to the request parameters
                }
            });
            let data = response.data.response;
            // If data is a string, wrap it in an array
            if (typeof data === 'string') {
                data = [data];
            }
            setPredictionResults(data);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    const handlePredictionClick = () => {
        setPredictionResults(null); 
        setError(null); 
        fetchPredictionResults();
    }

    return (
        <div>
            <h4>Macro-based</h4>
            <button onClick={handlePredictionClick} disabled={loading}>
                Predict Stock Prices
            </button>
            {loading && <div>Loading...</div>}
            {predictionResults && <SummaryDisplayForecastMacro summary={predictionResults} />}
            {/*{predictionResults && <div>{JSON.stringify(predictionResults)}</div>} */}
            {error && <div>Error: {error}</div>}
        </div>
    );
}

export default SignalsBacktestMacro;