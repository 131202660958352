import axios from '../axiosConfig';


const BASE_URL = '/api/financial-data'; 

export const fetchFinancialData = async (functionType, symbol) => {
  try {
    //const response = await axios.get(`${BASE_URL}?function=${functionType}&symbol=${symbol}`);
    const response = await axios.get(`${BASE_URL}?function=${encodeURIComponent(functionType)}&symbol=${encodeURIComponent(symbol)}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch financial data:', error);
    return null;
  }
};

const BASE_URL1 = '/api/financial-data-price'; 

export const fetchFinancialDataPrice = async (functionType, symbol, outputsize = 'compact') => {
  try {
    //const response = await axios.get(`${BASE_URL1}?function=${functionType}&symbol=${symbol}&outputsize=${outputsize}`);
    const response = await axios.get(`${BASE_URL1}?function=${encodeURIComponent(functionType)}&symbol=${encodeURIComponent(symbol)}&outputsize=${encodeURIComponent(outputsize)}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch financial data price:', error);
    return null;
  }
};