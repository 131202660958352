import React from 'react';

const SummaryDisplayIdeaMaker = ({ summary }) => {
  const preprocessSection = (sectionString) => {
    const lines = sectionString.split('\n').filter(line => line.trim() !== '');
    if (lines.length > 0) {
      const title = lines[0];
      const items = lines.slice(1);
      return { title, items };
    }
    return { title: '', items: [] };
  };

  const sectionsToDisplay = summary;

  return (
    <div style={{ padding: '0px', background: '#f5f5f5', borderRadius: '8px', margin: '10px 0' }}>
      {sectionsToDisplay.map((section, index) => {
        const { title, items } = preprocessSection(section);
        return (
          <div key={index} style={{
            background: '#eef2f5',
            padding: '10px',
            margin: '10px 0',
            borderRadius: '4px',
            borderLeft: '5px solid #007bff'
          }}>
            <span style={{ fontSize: '16px' }}>
              {title}
            </span>
            {items.map((item, itemIndex) => {
              const leadingSpaces = item.match(/^ */)[0].length;
              const baseIndent = item.trim().startsWith('-') ? 20 : 0;
              const additionalIndent = 20;
              return (
                <p key={itemIndex} style={{
                  margin: '10px 0',
                  marginLeft: baseIndent + leadingSpaces * additionalIndent + 'px',
                }}>
                  {item.trim().startsWith('-') ? `\u2022${item.trim().slice(1)}` : item}
                </p>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default SummaryDisplayIdeaMaker;
