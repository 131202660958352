import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; 


const CommodityFrontPageNumbers = () => {
  let navigate = useNavigate(); 
  const [selectedCategory, setSelectedCategory] = useState(null);
  const dropdownRef = useRef(null);

  const commodities = [
    { name: "Palladium Futures", code: "XPDUSD:CUR", category: "Precious Metals" },
    { name: "Gold", code: "XAUUSD:CUR", category: "Precious Metals" },
    { name: "Platinum", code: "XPTUSD:CUR", category: "Precious Metals" },
    { name: "Silver", code: "XAGUSD:CUR", category: "Precious Metals" },
    { name: "Crude Oil Futures", code: "CL1:COM", category: "Energy" },
    { name: "Brent Futures", code: "CO1:COM", category: "Energy" },
    { name: "Natural Gas Futures", code: "NG1:COM", category: "Energy" },
    { name: "Gasoline Futures", code: "XB1:COM", category: "Energy" },
    { name: "Sugar Futures", code: "SB1:COM", category: "Agricultural Commodities" },
    { name: "Corn Futures", code: "C_1:COM", category: "Agricultural Commodities" },
    { name: "Soybeans Futures", code: "S_1:COM", category: "Agricultural Commodities" },
    { name: "Coffee Futures", code: "KC1:COM", category: "Agricultural Commodities" },
    { name: "Wheat Futures", code: "W_1:COM", category: "Agricultural Commodities" },
    { name: "Nickel Futures", code: "LN1:COM", category: "Industrial Commodities" },
    { name: "Aluminum Futures", code: "LMAHDS03:COM", category: "Industrial Commodities" },
    { name: "Copper Futures", code: "HG1:COM", category: "Industrial Commodities" },
    { name: "Tin Futures", code: "LMSNDS03:COM", category: "Industrial Commodities" },
    { name: "Steel Futures", code: "JBP:COM", category: "Industrial Commodities" },
    { name: "Iron Ore Futures", code: "SCO:COM", category: "Industrial Commodities" }
  ];

  const categories = ["Precious Metals", "Energy", "Agricultural Commodities", "Industrial Commodities"];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSelectedCategory(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  const handleCommoditySelect = (commodity) => {
    navigate(`/commodity-numbers/${commodity.name}`, { state: { code: commodity.code } });
  };

  return (
    <div style={{ padding: '0px' }}>
      <h5 style={{ marginTop: '20px' }}>Commodity:</h5>
  
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '20px' }}>
        {categories.map(category => (
          <div key={category} style={{ position: 'relative', marginRight: '10px' }}>
            <button 
              style={{
                padding: '10px 10px',
                cursor: 'pointer',
                backgroundColor: selectedCategory === category ? '#A9A9A9' : '#fff',
                color: selectedCategory === category ? 'black' : 'inherit',
                border: '1px solid #ccc',
                borderRadius: '4px',
                transition: 'background-color 0.3s ease, color 0.3s ease'
              }}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
            {selectedCategory === category && (
              <div ref={dropdownRef} style={{
                position: 'absolute',
                backgroundColor: 'white',
                border: '1px solid #ccc',
                boxShadow: '0px 8px 16px rgba(0,0,0,0.2)',
                zIndex: 1,
                width: '200px',
                marginTop: '5px',
                borderRadius: '4px'
              }}>
                {commodities
                  .filter(commodity => commodity.category === category)
                  .map(commodity => (
                    <div 
                      key={commodity.code} 
                      onClick={() => handleCommoditySelect(commodity)}
                      style={{
                        padding: '8px 16px',
                        cursor: 'pointer'
                      }}
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f1f1f1'}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
                    >
                      {commodity.name}
                    </div>
                  ))
                }
              </div>
            )}
          </div>
        ))}
      </div>


    </div>
  );
};

export default CommodityFrontPageNumbers;
