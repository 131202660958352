import React, { useState } from 'react';
import axios from '../../../../axiosConfig';
import SummaryDisplayForecastTechnicals from './SummaryDisplayForecastTechnicals';
import PriceDataBox from './PriceDataBox'; 
import PriceDataBoxCrypto from './PriceDataBoxCrypto';

const SignalsBacktestTechnicalsCurrencyPairs = ({ inputData }) => {
    const [predictionResults, setPredictionResults] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [forecastHorizon, setForecastHorizon] = useState(7); // default forecast horizon is 7 days
    const [showInput, setShowInput] = useState(false);

    const [from_symbol, setFromSymbol] = useState('USD');
    const [to_symbol, setToSymbol] = useState('USD');

    const handleFromCryptoSelect = async (cryptoName) => {
        try {
            const response = await axios.get(`/api/crypto/name/${cryptoName}`);
            setFromSymbol(response.data.code);
        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    };

    const handleToForeignExchangeSelect = async (foreignExchangeName) => {
        try {
            const response = await axios.get(`/api/foreignexchange/name/${foreignExchangeName}`);
            setToSymbol(response.data.code);
        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    };

    const fetchPredictionResults = async () => {
        if (!inputData || !inputData.code) {
            setError('No company symbol provided');
            return;
        }
    
        setLoading(true);
        try {
            const response = await axios.get(`/predict_stock_prices_technicals_crypto/`, {
                params: {
                    forecast_horizon: forecastHorizon,
                    from_symbol: from_symbol, 
                    to_symbol: to_symbol
                }
            });
            let data = response.data.response;
            // If data is a string, wrap it in an array
            if (typeof data === 'string') {
                data = [data];
            }
            setPredictionResults(data);
        } catch (err) {
            if (err.response && err.response.status === 404) {
                setError('No data is available for this cryptocurrency, please try a different pair');
            } else {
                setError(err.message);
            }
        }
        setLoading(false);
    };

    const handlePredictionClick = () => {
        setShowInput(true);
    }

    const handleGoClick = () => {
        setPredictionResults(null); 
        setError(null); 
        fetchPredictionResults();
    }

    return (
        <div>
            <h5>Forecast for Any Pair</h5>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ marginRight: '10px', fontSize: 'small' }}>From: </label>
                <PriceDataBoxCrypto onCryptoSelect={handleFromCryptoSelect} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ marginRight: '26px', fontSize: 'small' }}>To: </label>
                <PriceDataBox onForeignExchangeSelect={handleToForeignExchangeSelect} />
            </div>
            <br />
            <button onClick={handlePredictionClick} disabled={loading}>
                Predict Crypto Price
            </button>
            {showInput && (
                <div>
                    <label style={{fontSize: 'small'}}>Forecast Horizon: </label>
                    <input 
                        type="number" 
                        value={forecastHorizon} 
                        onChange={(e) => setForecastHorizon(e.target.value)} 
                        placeholder="Days"
                        style={{width: '50px'}}
                    />
                    <button onClick={handleGoClick} disabled={loading}>
                        Go
                    </button>
                </div>
            )}
            {loading && <div>Loading...</div>}
            {predictionResults && <SummaryDisplayForecastTechnicals summary={predictionResults} />}

            {error && <div style={{ marginTop: '20px' }}>{error}</div>}
        </div>
    );
}

export default SignalsBacktestTechnicalsCurrencyPairs;