import React, { useState, useEffect } from 'react';
import axios from '../../../../axiosConfig';
import { fetchEarningsTitles } from '../../../../services/earningsApi';
import SummaryDisplayForecastAI from './SummaryDisplayForecastAI';

const SignalsBacktestAI = ({ companyData }) => {
    const [predictionResults, setPredictionResults] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [mostRecentTitleId, setMostRecentTitleId] = useState('');
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        const fetchTitles = async () => {
            try {
                const titlesData = await fetchEarningsTitles(companyData.symbol);
                if (titlesData.length > 0) {
                    const mostRecentTitleId = titlesData[0].id;
                    setMostRecentTitleId(mostRecentTitleId);
                    setDataLoaded(true);
                }
            } catch (error) {
                console.error(error);
            }
        };

        setDataLoaded(false);
        fetchTitles();
    }, [companyData.symbol]);

    const fetchPredictionResults = async () => {
        if (!companyData || !companyData.symbol || !mostRecentTitleId) {
            setError('No company symbol or most recent title ID provided');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get('/ai_signal_reasoning/', {
                params: { 
                    transcript_id: mostRecentTitleId,
                    companyName: companyData.name
                }
            });

            let data = response.data.response;
            if (typeof data === 'string') {
                data = [data];
            }
            setPredictionResults(data);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    const handlePredictionClick = () => {
        setPredictionResults(null); 
        setError(null); 
        if (dataLoaded) {
            fetchPredictionResults();
        }
    }

    return (
      <div>
          <h3>Signals</h3>
          <h4> Overview </h4>
          <button onClick={handlePredictionClick} disabled={loading || !dataLoaded}>
              Get Stock Analysis
          </button>
          {(loading || !dataLoaded) && <div>Loading...</div>}
          {predictionResults && <SummaryDisplayForecastAI summary={predictionResults} />}
          {error && <div>Error: {error}</div>}
      </div>
  );
}

export default SignalsBacktestAI;