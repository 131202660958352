import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import AutocompleteInputEconomy from './AutocompleteInputEconomy'; 
import styles from '../MicroProbe/MicroProbe.module.css'; 


const EconomyFrontPageSignals = () => {
  let navigate = useNavigate(); 

  // Function to handle selection of a economy
  const handleEconomySelect = (economyName) => {
    navigate(`/economy-signals/${economyName}`); 
  };

  return (
    <div >
      
      <h5>Economy:</h5>
      <div className={styles.searchSection}>
        <AutocompleteInputEconomy onEconomySelect={handleEconomySelect} />
      </div>


    </div>
  );
};

export default EconomyFrontPageSignals;