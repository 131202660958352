import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import styles from '../../MicroProbe//MicroProbe.module.css';
import NearTermForecast from './NearTermForecast';
import ChatNumbersNearTermForecast from './ChatNumbersNearTermForecast';
import SignalsBacktestAI from './SignalsBacktestAI';
import axios from '../../../../axiosConfig';



const IndividualBondSignals = () => {
  const { economyName } = useParams();
  const [inputData, setInputData] = useState(null);
  
  useEffect(() => {
    const fetchInputData = async () => {
      try {
        const response = await axios.get(`/api/country/name/${economyName}`);
        setInputData(response.data);

      } catch (error) {
        console.error('Error fetching currency data:', error);
      }
    };
  
    fetchInputData();
  }, [economyName]);


return (
  <div className={styles.container}>
    <h1 className={styles.title}>{economyName } Bond</h1>

    {inputData && (
      <>  

        <div className={`${styles.section} ${styles.sectionQuarter}`}>
 
        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestAI inputData={inputData} />
        </div>
        <div className={styles.divider}></div>
        

   
        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <NearTermForecast inputData={inputData} />
        </div>
        <div className={styles.dividernone}></div>
        

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <ChatNumbersNearTermForecast inputData={inputData} />
        </div>
        </div>

     

      </>
    )}

    <div style={{ marginTop: '20px' }}>
        <Link to="/signals-suite">Back</Link>
      </div> 

  </div>
);
};

export default IndividualBondSignals;