import React, { useState } from 'react';
import mammoth from 'mammoth';
import { getDocument } from 'pdfjs-dist';
import JSZip from 'jszip';
import './pdf.worker.entry'; // Ensure the path is correct

const DocumentUpload = ({ onTextExtracted }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return;

    const fileType = file.name.split('.').pop().toLowerCase();
    let extractedText = '';

    if (fileType === 'docx') {
      extractedText = await extractTextFromWord(file);
    } else if (fileType === 'pdf') {
      extractedText = await extractTextFromPDF(file);
    } else if (fileType === 'pptx') {
      extractedText = await extractTextFromPPT(file);
    } else if (fileType === 'txt') {
      extractedText = await extractTextFromTXT(file);
    } else {
      alert('Unsupported file type');
    }

    onTextExtracted(extractedText);
  };

  const extractTextFromWord = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer });
    return result.value;
  };

  const extractTextFromPDF = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await getDocument({ data: arrayBuffer }).promise;
    let text = '';

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      text += content.items.map(item => item.str).join(' ');
    }

    return text;
  };

  const extractTextFromPPT = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const zip = await JSZip.loadAsync(arrayBuffer);
    const files = zip.folder('ppt/slides').file(/.xml/);
    let text = '';
  
    for (let file of files) {
      const content = await file.async('string');
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(content, "text/xml");
      const textElements = xmlDoc.getElementsByTagName('a:t');
      for (let i = 0; i < textElements.length; i++) {
        text += textElements[i].textContent + ' ';
      }
    }
  
    return text;
  };

  const extractTextFromTXT = async (file) => {
    const text = await file.text();
    return text;
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>Upload and Extract Text</button>
    </div>
  );
};

export default DocumentUpload;
