import React from 'react';

const SummaryDisplayForecastTechnicals = ({ summary }) => {
  // Function to preprocess a single section string into title and items
  const preprocessSection = (sectionString) => {
    sectionString = sectionString.replace(/\*/g, '');
    
    const titles = [
      "Forecast:", 
      "Factors Influencing the Forecast:", 
      "Concerning Trends and Risks:", 
      "Insights from Feature Importance:", 
      "Conclusion:"
    ];
  
    //const titleRegex = new RegExp(`((\\d*\\.?\\s?)?\\*\\*?\\s?(${titles.join('|')})\\s?\\*\\*?)`, 'g');
    const titleRegex = new RegExp(`(\\d*\\.?\\s?(${titles.join('|')})\\s?)`, 'g');
  
    let sections = sectionString.split(titleRegex).filter(section => section.trim() !== '');
  
    let processedLines = [];
    for (let i = 0; i < sections.length; i++) {
      if (titles.some(title => sections[i].includes(title))) {
        processedLines.push({ type: 'title', content: sections[i].replace(/\*+/g, '').trim() });
        i++; // Skip the next section which is the text of the title
      } else {
        let lines = sections[i].split('\n').filter(line => line.trim() !== '');
        lines.forEach(line => {
          if (line.trim().startsWith('- ')) {
            processedLines.push({ type: 'item', content: line.trim().substring(2) });
          } else if (line.trim() !== '') {
            processedLines.push({ type: 'text', content: line.trim() });
          }
        });
      }
    }
  
    return processedLines;
  };

  return (
    <div style={{ padding: '0px', background: '#f5f5f5', borderRadius: '8px', margin: '10px 0' }}>
      {summary.map((section, index) => {
        const processedLines = preprocessSection(section);
        return (
          <div key={index} style={{
              background: '#eef2f5',
              padding: '10px',
              margin: '10px 0',
              borderRadius: '4px',
              borderLeft: '5px solid #007bff'
            }}>
            
            {processedLines.map((line, lineIndex) => {
              if (line.type === 'title') {
                return <p key={lineIndex} style={{fontWeight: 'bold'}}>{line.content}</p>
              } else if (line.type === 'item') {
                return (
                  <p key={lineIndex} style={{ 
                      marginTop: '15px', 
                      marginBottom: '15px', 
                      marginLeft: '15px',
                      marginRight: '0px'
                  }}>
                      {`\u2022 ${line.content}`}
                  </p>
                )
              } else {
                return <p key={lineIndex}>{line.content}</p>
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

export default SummaryDisplayForecastTechnicals;