import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import AutocompleteInputCrypto from './AutocompleteInputCrypto'; 
import styles from '../MicroProbe/MicroProbe.module.css'; 


const CryptoFrontPage = () => {
  let navigate = useNavigate(); 

  // Function to handle selection of a foreign exchange
  const handleCryptoSelect = (cryptoName) => {
    navigate(`/crypto-details/${cryptoName}`); 
  };

  return (
    <div>
      <h5>Cryptocurrency:</h5>

      <div className={styles.searchSection} style={{ marginBottom: '10px' }}>
        <AutocompleteInputCrypto onCryptoSelect={handleCryptoSelect} />
      </div>


    </div>
  );
};

export default CryptoFrontPage;