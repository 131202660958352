import React, { useState } from 'react';
import axios from '../../../../axiosConfig';
import SummaryDisplayForecastTechnicals from './SummaryDisplayForecastTechnicals';

const SignalsBacktestTechnicals = ({ inputData }) => {
    const [predictionResults, setPredictionResults] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [forecastHorizon, setForecastHorizon] = useState(7); // default forecast horizon is 7 days
    const [showInput, setShowInput] = useState(false);

    const currencies = ['EUR', 'GPB', 'AUD', 'NZD', 'CHF', 'CAD'];
    let from_symbol, to_symbol;

    if (inputData.code === 'USD') {
        from_symbol = 'EUR';
        to_symbol = 'USD';
    } else if (currencies.includes(inputData.code)) {
        from_symbol = inputData.code;
        to_symbol = 'USD';
    } else {
        from_symbol = 'USD';
        to_symbol = inputData.code;
    }

    const fetchPredictionResults = async () => {
        if (!inputData || !inputData.code) {
            setError('No company symbol provided');
            return;
        }
    
        setLoading(true);
        try {
            const response = await axios.get(`/predict_stock_prices_technicals_fx/`, {
                params: {
                    forecast_horizon: forecastHorizon,
                    //currencyName: inputData.name, 
                    from_symbol: from_symbol, 
                    to_symbol: to_symbol
                }
            });
            let data = response.data.response;
            // If data is a string, wrap it in an array
            if (typeof data === 'string') {
                data = [data];
            }
            setPredictionResults(data);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    const handlePredictionClick = () => {
        setShowInput(true);
    }

    const handleGoClick = () => {
        setPredictionResults(null); 
        setError(null); 
        fetchPredictionResults();
    }

    let heading = inputData.code === 'USD' ? 'Forecast vs Euro' : 'Forecast vs Dollar';

    return (
        <div>
            <h4>Forecast</h4>
            <h5>{heading}</h5>
            <button onClick={handlePredictionClick} disabled={loading}>
                Predict Forex Price
            </button>
            {showInput && (
                <div>
                    <label style={{fontSize: 'small'}}>Forecast Horizon: </label>
                    <input 
                        type="number" 
                        value={forecastHorizon} 
                        onChange={(e) => setForecastHorizon(e.target.value)} 
                        placeholder="Days"
                        style={{width: '50px'}}
                    />
                    <button onClick={handleGoClick} disabled={loading}>
                        Go
                    </button>
                </div>
            )}
            {loading && <div>Loading...</div>}
            {predictionResults && <SummaryDisplayForecastTechnicals summary={predictionResults} />}
            {/*{predictionResults && <div>{JSON.stringify(predictionResults)}</div>} */}
            {error && <div>Error: {error}</div>}
        </div>
    );
}

export default SignalsBacktestTechnicals;