import React, { useEffect, useState } from 'react';
import { useParams, useLocation,Link } from 'react-router-dom';
import LatestDevelopments from './LatestDevelopments';
import styles from '../../MicroProbe//MicroProbe.module.css';
import IdeasMaker from '../../IdeasMaker';
import TopicGather from '../../TopicGather';
import ChatNumbers from './ChatNumbers';
import PriceData from './PriceData';
import SignalsBacktestTechnicalsPlot from './SignalsBacktestTechnicalsPlot';
import SignalsBacktestTechnicals from './SignalsBacktestTechnicals';
import SignalsBacktestAI from './SignalsBacktestAI';
import CompareData from './CompareData';
import PerformanceOverview from './PerformanceOverview';
import VideoInsight from '../../MicroProbe/Companies/VideoInsightForDashboard';
import ChatUrl from '../../MicroProbe/Companies/ChatUrl';


const IndividualCommodity = () => {
  const { commodityName } = useParams();
  const location = useLocation();
  const [inputData, setInputData] = useState(null);
  
  useEffect(() => {
    // Check if state is passed and has the required properties
    if (location.state && location.state.code) {
      setInputData({
        name: commodityName,
        code: location.state.code
      });
    }
  }, [commodityName, location.state]);


return (
  <div className={styles.container}>
    <h1 className={styles.title}>Commodity: {commodityName.replace("Futures", "").trim()}</h1>

    {inputData && (
      <>  
        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <LatestDevelopments  inputData={inputData} />
          </div>
         
        </div>


  
        <div className={`${styles.section} ${styles.sectionQuarter}`}>
        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <PerformanceOverview inputData={inputData} />
          </div>
          <div className={styles.dividernone}></div>
        
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <PriceData inputData={inputData} />
          </div>
          <div className={styles.dividernone}></div>

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <CompareData inputData={inputData} />
          </div>
          <div className={styles.dividernone}></div>

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <ChatNumbers inputData={inputData} />
          </div>
          <div className={styles.dividernone}></div> 
          

        </div>


        <div className={`${styles.section} ${styles.sectionQuarter}`}>

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}> 
            <TopicGather />
          </div>
          <div className={styles.divider}></div> 

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}> 
            <ChatUrl />
          </div>
     
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}> 
            <VideoInsight inputData={inputData} />
          </div>
          
        </div>

    

        <div className={`${styles.section} ${styles.sectionQuarter}`}>

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestAI inputData={inputData} />
        </div>
        <div className={styles.divider}></div>

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestTechnicals inputData={inputData} />
        </div>
        <div className={styles.divider}></div>  


   
        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestTechnicalsPlot inputData={inputData} />
        </div>
        <div className={styles.dividernone}></div>      
        </div>
       

    


        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>   
          <IdeasMaker />
        </div>
        </div>


      </>
    )}

    <Link to="/ai-market-dashboard">Back</Link>
  </div>
);
};

export default IndividualCommodity;