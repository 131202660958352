import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from '../../../../axiosConfig';
import axios from 'axios';  
import { fetchEarningsTitles } from '../../../../services/earningsApi';
import SummaryDisplayEarnings from './SummaryDisplayEarnings';
import styles from './EarningsPress.module.css'; 
import CustomDropdown from './CustomDropdownEarnings';

const EarningsPress = ({ companyData }) => {
  const [titles, setTitles] = useState([]);
  const [selectedTitleId, setSelectedTitleId] = useState('');
  const [question, setQuestion] = useState('');
  const [loading, setLoading] = useState(true);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [summary, setSummary] = useState([]);
  const [loadingHighlights, setLoadingHighlights] = useState(false);
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const source = useRef(axios.CancelToken.source());

  const handleSelectChange = (selectedId) => {
    setSelectedTitleId(selectedId);
    setConversationHistory([]);
    setQuestion('');
  };

  useEffect(() => {
    const fetchTitles = async () => {
      try {
        const titlesData = await fetchEarningsTitles(companyData.symbol);
        setTitles(titlesData);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTitles();
  }, [companyData.symbol]);

  const handleKeyHighlightsClick = async () => {
    if (selectedTitleId) {
      setLoadingHighlights(true);
      setSummary(null);
      try {
        source.current.cancel('Operation canceled by the user.');
        source.current = axios.CancelToken.source();

        const response = await axiosInstance.post('/earnings_summary/', {
          transcript_id: selectedTitleId
        }, {
          cancelToken: source.current.token
        });

        const parsedResponse = response.data.response;
        setSummary(parsedResponse);
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          console.error(error);
        }
      }
      setLoadingHighlights(false);
    }
  };

  const handleAskQuestionClick = async () => {
    if (selectedTitleId && question) {
      setLoadingQuestion(true);
      try {
        source.current.cancel('Operation canceled by the user.');
        source.current = axios.CancelToken.source();
        const response = await axiosInstance.post('/earnings_chat/', {
          question: question,
          chat_history: conversationHistory,
          transcript_id: selectedTitleId,
        }, {
          cancelToken: source.current.token,
        });
        setConversationHistory([...conversationHistory, { question: question, answer: response.data.answer }]);
        setQuestion('');
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          console.error('Error fetching answer:', error);
        }
      }
      setLoadingQuestion(false);
    }
  };


  const handleChatClick = () => {
    setShowChat(!showChat);
  };

  return (
    <div className={styles.earningsPress}>
      <h3>Document Insight</h3>
      <h4>Earnings Call</h4>
      <CustomDropdown titles={titles} handleSelectChange={handleSelectChange} />
      {loading ? <p>Loading...</p> : !loading && titles.length === 0 && <p>No transcript available</p>}
        <div className={styles.mainContainer}>
          <div className={styles.highlightSection}>
            <button onClick={handleKeyHighlightsClick} className={styles.highlightButton}>Get Key Highlights</button>
            {loadingHighlights && <p>Loading...</p>}
            {summary && summary.length > 0 && <SummaryDisplayEarnings summary={summary} />}
          </div>
          <div className={styles.chatSection}>
            <div>
              <button onClick={handleChatClick} className={styles.chatButton}>Chat</button>
              {showChat && (
                <div className={styles.chatContainer}>
                  {conversationHistory.length > 0 && (
                    <div>
                      <h3>Conversation History:</h3>
                      {conversationHistory.map((qaPair, index) => (
                        <div key={index}>
                          <h4>Q: {qaPair.question}</h4>
                          <p>A: {qaPair.answer}</p>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className={styles.inputSection}>
                    <textarea
                      type="text"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      placeholder="Ask about earnings call"
                      className={styles.questionInput}
                      rows="5"
                    />
                    <button onClick={handleAskQuestionClick} className={styles.askButton}>
                      Ask Question
                    </button>
                    {loadingQuestion && <p>Loading...</p>}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
    </div>
  );
}

export default EarningsPress;