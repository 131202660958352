import axios from '../axiosConfig';


const BASE_URL = '/api/get_sentiment_data'; // Adjust based on your Django backend route

export const fetchSentimentData = async (symbol) => {
  try {
    const url = `${BASE_URL}?symbol=${encodeURIComponent(symbol)}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch sentiment data:', error);
    return null;
  }
};