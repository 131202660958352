import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../../../../axiosConfig';
import LatestDevelopments from './LatestDevelopments';
import styles from '../../MicroProbe//MicroProbe.module.css';
import IdeasMaker from '../../IdeasMaker';
import TopicGather from '../../TopicGather';
import SentimentTrend from './SentimentTrend';
import Networks from './Networks';
import ChatNumbers from './ChatNumbers';
import PriceData from './PriceData';
import PerformanceOverview from './PerformanceOverview';
import SignalsBacktestTechnicalsPlot from './SignalsBacktestTechnicalsPlot';
import SignalsBacktestTechnicals from './SignalsBacktestTechnicals';
import SignalsBacktestAI from './SignalsBacktestAI';
import SignalsBacktestTechnicalsCurrencyPairs from './SignalsBacktestTechnicalsCurrencyPairs';
import RegulationHighlight from './RegulationHighlight';
import VideoInsight from '../../MicroProbe/Companies/VideoInsightForDashboard';
import ChatUrl from '../../MicroProbe/Companies/ChatUrl';



const IndividualCrypto = () => {
  const { cryptoName } = useParams();
  const [inputData, setInputData] = useState(null);
  
  useEffect(() => {
    const fetchInputData = async () => {
      try {
        const response = await axios.get(`/api/crypto/name/${cryptoName}`);
        setInputData(response.data);

      } catch (error) {
        console.error('Error fetching currency data:', error);
      }
    };
  
    fetchInputData();
  }, [cryptoName]);


return (
  <div className={styles.container}>
    <h1 className={styles.title}>Cryptocurrency: {cryptoName }</h1>

    {inputData && (
      <>  
        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <LatestDevelopments  inputData={inputData} />
          </div>

          <div className={styles.dividernone}></div> 
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <RegulationHighlight inputData={inputData} />
          </div>
          <div className={styles.dividernone}></div> 
      
        </div>

        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <SentimentTrend  inputData={inputData} />
          </div>

          <div className={styles.dividernone}></div>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <Networks  inputData={inputData} />
          </div>   
        </div>

  
        <div className={`${styles.section} ${styles.sectionQuarter}`}>
        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <PerformanceOverview  inputData={inputData} />
          </div>

        
          <div className={styles.divider}></div>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <PriceData inputData={inputData} />
          </div>
          <div className={styles.dividernone}></div>

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <ChatNumbers inputData={inputData} />
          </div>
          <div className={styles.dividernone}></div> 
          

        </div>


        <div className={`${styles.section} ${styles.sectionQuarter}`}>
        
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}> 
            <TopicGather />
          </div>
          <div className={styles.divider}></div>  

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}> 
            <ChatUrl />
          </div>
     
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}> 
            <VideoInsight inputData={inputData} />
          </div>
          
        </div>

    

        <div className={`${styles.section} ${styles.sectionQuarter}`}>

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestAI inputData={inputData} />
        </div>
        <div className={styles.divider}></div>

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestTechnicals inputData={inputData} />
        </div>
        <div className={styles.divider}></div>  

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestTechnicalsCurrencyPairs inputData={inputData} />
        </div>
        <div className={styles.divider}></div>  
   
        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestTechnicalsPlot inputData={inputData} />
        </div>
        <div className={styles.dividernone}></div>
      
        </div>
       

  

        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>   
          <IdeasMaker/>
        </div>
        </div>


      </>
    )}

    <Link to="/ai-market-dashboard">Back</Link>
  </div>
);
};

export default IndividualCrypto;