import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../../../../axiosConfig';
import styles from '../../MicroProbe//MicroProbe.module.css';
import SignalsBacktestTechnicalsPlot from './SignalsBacktestTechnicalsPlot';
import SignalsBacktestTechnicals from './SignalsBacktestTechnicals';
import SignalsBacktestAI from './SignalsBacktestAI';
import SignalsBacktestTechnicalsCurrencyPairs from './SignalsBacktestTechnicalsCurrencyPairs';



const IndividualCryptoSignals = () => {
  const { cryptoName } = useParams();
  const [inputData, setInputData] = useState(null);
  
  useEffect(() => {
    const fetchInputData = async () => {
      try {
        const response = await axios.get(`/api/crypto/name/${cryptoName}`);
        setInputData(response.data);

      } catch (error) {
        console.error('Error fetching currency data:', error);
      }
    };
  
    fetchInputData();
  }, [cryptoName]);


return (
  <div className={styles.container}>
    <h1 className={styles.title}>Cryptocurrency: {cryptoName }</h1>

    {inputData && (
      <>  
    
        <div className={`${styles.section} ${styles.sectionQuarter}`}>

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestAI inputData={inputData} />
        </div>
        <div className={styles.divider}></div>

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestTechnicals inputData={inputData} />
        </div>
        <div className={styles.divider}></div>  

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestTechnicalsCurrencyPairs inputData={inputData} />
        </div>
        <div className={styles.divider}></div>  
   
        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestTechnicalsPlot inputData={inputData} />
        </div>
        <div className={styles.dividernone}></div>
      
        </div>
       

      </>
    )}

    <div style={{ marginTop: '20px' }}>
        <Link to="/signals-suite">Back</Link>
      </div> 

  </div>
);
};

export default IndividualCryptoSignals;