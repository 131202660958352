import React from 'react';

const SummaryDisplayStock = ({ summary }) => {
  // Function to preprocess a single section string into title and items
  const preprocessSection = (sectionString) => {
    // Split by new lines to separate the title from list items
    let lines = sectionString.split('\n').filter(line => line.trim() !== '');

    const titleIndex = lines.findIndex(line => line.trim() === '1. Recent Stock Performance:');
    if (titleIndex > 0) {
      lines = lines.slice(titleIndex);
    }

    if (lines.length > 0) {
      const title = lines[0];
      const items = lines.slice(1);
      return { title, items };
    }
    return { title: '', items: [] };
  };

  return (
    <div style={{ padding: '0px', background: '#f5f5f5', borderRadius: '8px', margin: '10px 0' }}>
      {summary.map((section, index) => {
        const { title, items } = preprocessSection(section);
        return (
          <div key={index} style={{
              background: '#eef2f5',
              padding: '10px',
              margin: '10px 0',
              borderRadius: '4px',
              borderLeft: '5px solid #007bff'
            }}>
           
            {title.match(/^\d+\..*:$/) || title.includes('Recent Stock Performance:') ? <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</span> : <span style={{ fontSize: '16px' }}>{title}</span>}
            {items.map((item, itemIndex) => {
              const leadingSpaces = item.match(/^ */)[0].length;
              const baseIndent = item.trim().startsWith('-') ? 10 : 0; // Base indentation for the first layer of bullet points
              const additionalIndent = 5; // Additional indentation for each sub-bullet
              return (
                  <p key={itemIndex} style={{ margin: '10px 0', marginLeft: baseIndent + leadingSpaces * additionalIndent + 'px', fontWeight: item.match(/^\d+\..*/) || item.includes('Recent Stock Performance:') || item.includes('Future Stock Prospects:') ? 'bold' : 'normal' }}>
                      {item.trim().startsWith('-') ? `\u2022${item.trim().slice(1)}` : item}
                  </p>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default SummaryDisplayStock;