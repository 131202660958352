import React from 'react';
import { Link } from 'react-router-dom';
import './FeaturesPage.css';

const Feature = ({ title, path, description }) => (
  <Link to={path} className="feature">
    <div>
      <h3>{title}</h3>
      {description.map((desc, index) => {
        const [subTitle, subDesc] = desc.split(': ');
        return (
          <div key={index} className="feature-subsection">
            <strong>{subTitle}</strong>: {subDesc}
          </div>
        );
      })}
    </div>
  </Link>
);

const FeaturesPage = () => {
  const features = [
    {
      title: 'AI News & Sentiment Analytics',
      path: '/news-suite',
      description: [
        'Cut Through the Noise: AI filters vast amounts of market information from 200+ economies, presenting the most relevant updates.',
        'Subtle Sentiment Detection: Uncover hidden market emotions with nuanced sentiment analysis.',
        'Tailored for Your Strategy: Get insights across companies, currencies, bonds, commodities, economies, and cryptos.'
      ]
    },
    {
      title: 'AI Data Analytics',
      path: '/numbers-suite',
      description: [
        'Smart Data Agents: AI simplifies data exploration, answering your questions with actionable insights.',
        'AI-Enhanced Analytics: Leverage AI to analyze diverse asset classes, uncovering patterns in stocks, bonds, FX, and more.',
        'Dynamic Visualization: Transform raw datasets into interactive visual formats for deeper analysis.'
      ]
    },
    {
      title: 'AI Text and Video Discovery',
      path: '/text-videos-suite',
      description: [
        'Instant Text Extractor: Pull critical details from SEC filings and earnings transcripts instantly.',
        'Deep Video Insights: Analyze videos from new perspectives and extract key moments.',
        'Multimodal Intelligence: Merge insights from text, video, and numbers for a comprehensive view.'
      ]
    },
    {
      title: 'AI Market Signals',
      path: '/signals-suite',
      description: [
        'Predict in Real-Time: Tailored models adapt to market movements, providing real-time predictions.',
        'Decode Market Signals: Understand the meaning of each signal and gain actionable insights.',
        'Reasoning with Clarity: AI tracks decision trees to enhance decision-making and reduce bias.'
      ]
    },
    {
      title: 'AI Investment Agents',
      path: '/agents',
      description: [
        'Financials Deep Dive: AI analyzes thousands of financial metrics across 200,000+ companies.',
        'Alternative Data Edge: Integrate non-traditional data like CO2 emissions for a comprehensive view.',
        'AI Investment Picks: Construct regime scenarios and get tailored investment recommendations.'
      ]
    },
    {
      title: 'AI Reporting Studio',
      path: '/ideas-maker',
      description: [
        'Customized AI Reports: AI delivers reports tailored to your specific requirements.',
        'Dynamic Presentations: AI generates insightful presentations for any scenario.',
        'Creative Market Insights: Break free from traditional formats with engaging, unique reports.'
      ]
    },
    {
      title: 'AI Smart Search Engine',
      path: '/smart-search-suite',
      description: [
        'Live Topic Search: AI gathers and summarizes news, reports, and documents in real-time.',
        'Instant Market Answers: Get detailed updates on industries, stocks, or sectors.',
        'Rich Domain Knowledge: Access precise answers to financial queries, guiding informed decisions.'
      ]
    },
    {
      title: 'Extensive Dashboards',
      path: '/ai-market-dashboard',
      description: [
        'Comprehensive Coverage: Explore interactive dashboards across multiple assets like stocks, FX, and crypto.',
        'Multi-Layered Analytics: Access sentiment, signals, alternative data, and AI-driven insights seamlessly.',
        'Tailor Your Market View: Customize dashboards to create a unique, personalized market view.'
      ]
    }
  ];

  return (
    <div className="features-page">
      <h1>Explore Our Features</h1>
      <p>Discover the powerful tools and insights offered by Fina.ai.</p>
      <div className="features-list">
        {features.map(feature => (
          <Feature key={feature.title} {...feature} />
        ))}
      </div>
      <div className="feedback-section">
        <p>Got feedback? <a href="mailto:infor@fina-ai.org">Let us know!</a></p>
      </div>
    </div>
  );
};

export default FeaturesPage;
