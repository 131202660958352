import React, { useState, useEffect, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import colorbrewer from 'colorbrewer';
import axios from '../../../../axiosConfig';
import styles from './NearTermForecast.module.css'; // Import the CSS module

function NearTermForecast({ inputData }) {
  const [loading, setLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [error, setError] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dataUnavailable, setDataUnavailable] = useState(false);
  const [tempStartDate, setTempStartDate] = useState('');
  const [tempEndDate, setTempEndDate] = useState('');
  const [originalData, setOriginalData] = useState({});

  const today = new Date();
  const threeYearsAgo = new Date(today.getFullYear() - 3, 0, 1);
  const formattedThreeYearsAgo = threeYearsAgo.toISOString().split('T')[0];

  // Set initial start dates on component mount
  useEffect(() => {
    setStartDate(formattedThreeYearsAgo);
    setTempStartDate(formattedThreeYearsAgo);
  }, [formattedThreeYearsAgo]);

  // Reset dates and select category
  const resetDatesAndSetCategory = (category) => {
    setStartDate(formattedThreeYearsAgo);
    setTempStartDate(formattedThreeYearsAgo);
    setEndDate('');
    setTempEndDate('');
    setSelectedCategory(category);
  };

  // Load categories and handle loading state
  const initiateLoading = async () => {
    setInitialLoad(true);
    setCategoriesLoading(true);
    setDataUnavailable(false);
    try {
      const response = await axios.get(`/available-buttons-forecast/${inputData.code}/`);
      setCategories(response.data);
      if (response.data.length === 0) {
        setDataUnavailable(true);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to fetch categories');
    } finally {
      setCategoriesLoading(false);
    }
  };

  // Helper to get a random color from a palette
  const getRandomColor = useCallback(() => {
    let palette = colorbrewer.Paired[12];
    const lightYellowIndex = palette.indexOf('#ffff99');
    if (lightYellowIndex !== -1) {
      palette.splice(lightYellowIndex, 1);
    }
    return palette[Math.floor(Math.random() * palette.length)];
  }, []);

  // Format chart data based on fetched data
  const formatChartData = useCallback((data) => {
    if (data.length === 0) return { labels: [], datasets: [] };

    const labels = data.map(item => item[0]);
    const borderColor = getRandomColor();
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const parseDate = (dateStr) => {
      if (dateStr.includes('-Q')) {
        const [year, quarter] = dateStr.split('-Q');
        const month = ((parseInt(quarter, 10) - 1) * 3) + 1;
        return new Date(`${year}-${month.toString().padStart(2, '0')}-01`);
      } else {
        return new Date(`${dateStr}-01-01`);
      }
    };

    const pointDetails = data.map(item => {
      const itemDate = parseDate(item[0]);
      const isFuture = itemDate >= today;
      return {
        color: isFuture ? '#B0AFAB' : borderColor,
        radius: isFuture ? 5 : 2
      };
    });

    return {
      labels,
      datasets: [
        {
          label: 'Value',
          data: data.map(item => item[1]),
          borderColor,
          backgroundColor: borderColor,
          fill: false,
          pointRadius: pointDetails.map(detail => detail.radius),
          pointBackgroundColor: pointDetails.map(detail => detail.color),
        }
      ]
    };
  }, [getRandomColor]);

  // Filter data according to date range and format it for chart display
  const filterData = useCallback((data) => {
    const parseDate = (dateStr) => {
      if (dateStr.includes('-Q')) {
        const [year, quarter] = dateStr.split('-Q');
        const month = (parseInt(quarter, 10) - 1) * 3;
        return new Date(year, month, 1);
      } else {
        return new Date(dateStr);
      }
    };

    const start = startDate ? parseDate(startDate) : null;
    const end = endDate ? parseDate(endDate) : null;

    const filteredData = Object.keys(data).reduce((acc, key) => {
      const filtered = data[key].filter(([date]) => {
        const itemDate = parseDate(date);
        return (!start || itemDate >= start) && (!end || itemDate <= end);
      });
      if (filtered.length) {
        acc[key] = filtered;
      }
      return acc;
    }, {});

    const formattedData = Object.keys(filteredData).map(key => ({
      title: key,
      data: formatChartData(filteredData[key])
    }));

    setChartData(formattedData);
  }, [startDate, endDate, formatChartData]);

  // Fetch and filter data based on category selection
  const fetchData = useCallback(async (category) => {
    if (!originalData[category]) {
      setLoading(true);
      setError(null);
      setHasFetched(false);
      setSelectedCategory(category);
      try {
        const response = await axios.get(`/get_near_term_forecast_data?code=${inputData.code}&category=${category}`);
        const data = response.data;
        setOriginalData(prevData => ({ ...prevData, [category]: data }));
        filterData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
        setHasFetched(true);
      }
    } else {
      setSelectedCategory(category);
      filterData(originalData[category]);
    }
  }, [originalData, inputData.code, filterData]);

  useEffect(() => {
    if (selectedCategory) {
      fetchData(selectedCategory);
    }
  }, [selectedCategory, startDate, endDate, fetchData]);

  
  return (
    <div>
      <h4>Near Term Forecast</h4>
      {!initialLoad ? (
        <button onClick={initiateLoading}>Get Data</button>
      ) : (
        <>
          {categoriesLoading ? <p>Loading ...</p> : (
            <>
              {dataUnavailable ? <p>Data is not available, please check out Long Term Outlook section</p> : (
                <div className={styles.buttonContainer}>
                  {categories.map((category, index) => (
                    <button
                      key={index}
                      onClick={() => resetDatesAndSetCategory(category)}
                      className={`${styles.button} ${selectedCategory === category ? styles.buttonActive : ''}`}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              )}
            </>
          )}
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              {hasFetched && chartData.length > 0 && (
                <div className={styles.chartControls}>
                  <div>
                    <label>Start Date: </label>
                    <input type="date" value={tempStartDate} onChange={e => setTempStartDate(e.target.value)} />
                  </div>
                  <div>
                    <label style={{ marginRight: '5px' }}>End Date: </label>
                    <input type="date" value={tempEndDate} onChange={e => setTempEndDate(e.target.value)} />
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <button onClick={() => {
                      setStartDate(tempStartDate);
                      setEndDate(tempEndDate);
                    }}>Update Chart</button>
                  </div>
                </div>
              )}

              {selectedCategory && hasFetched && chartData.length > 0 && (
                <h2 style={{ textAlign: 'center', marginTop: '40px', marginBottom: '10px' }}>{selectedCategory}</h2>
              )}

              {hasFetched && chartData.length === 0 ? (
                <p>Data is not available</p>
              ) : (
                <div className={styles.chartWrapper}>
                  {chartData.map((chart, index) => {
                    const [chartTitle, yAxisLabel] = chart.title.includes('(')
                      ? [
                          chart.title.substring(0, chart.title.indexOf('(')).trim(),
                          chart.title.substring(chart.title.indexOf('(') + 1, chart.title.lastIndexOf(')')).trim()
                        ]
                      : [chart.title.replace(/_/g, ' '), ''];

                    return (
                      <div key={index} className={styles.chartContainer}>
                        <div className={styles.scrollableChart}>
                          <h4>{chartTitle}</h4>
                          <div className={styles.chartContent}>
                            <Line
                              data={chart.data}
                              options={{
                                responsive: true,
                                plugins: {
                                  legend: {
                                    display: false
                                  },
                                  tooltip: {
                                    displayColors: false,
                                    position: 'nearest',
                                    bodyFont: {
                                      size: window.innerWidth < 768 ? 12 : 14 
                                    },
                                    titleFont: {
                                      size: window.innerWidth < 768 ? 12 : 14 
                                    },
                                    
                                    callbacks: {
                                      title: function (tooltipItems) {
                                        const label = tooltipItems[0].label;
                                        return `Date: ${label}`;
                                      },
                                      label: function (tooltipItem) {
                                        const yAxisLabelText = yAxisLabel;
                                        const maxLineLength = 50;

                                        const splitLabel = (labelText, maxLength) => {
                                          const words = labelText.split(' ');
                                          const result = [];
                                          let currentLine = '';

                                          words.forEach(word => {
                                            if ((currentLine + word).length <= maxLength) {
                                              currentLine += `${word} `;
                                            } else {
                                              result.push(currentLine.trim());
                                              currentLine = `${word} `;
                                            }
                                          });

                                          if (currentLine) {
                                            result.push(currentLine.trim());
                                          }

                                          return result;
                                        };

                                        const yAxisLabelLines = splitLabel(yAxisLabelText, maxLineLength);
                                        const formattedValue = new Intl.NumberFormat().format(tooltipItem.parsed.y);
                                        return [`Value: ${formattedValue}`, ...yAxisLabelLines];
                                      }
                                    }
                                  }
                                },
                                scales: {
                                  x: {
                                    title: {
                                      display: false
                                    }
                                  },
                                  y: {
                                    ticks: {
                                      callback: function (value) {
                                        const absValue = Math.abs(value);
                                        let formattedValue = value;

                                        if (absValue >= 1e12) {
                                          formattedValue = `${(absValue / 1e12).toFixed(1)} Trillion`;
                                        } else if (absValue >= 1e9) {
                                          formattedValue = `${(absValue / 1e9).toFixed(1)} Billion`;
                                        } else if (absValue >= 1e6) {
                                          formattedValue = `${(absValue / 1e6).toFixed(1)} Million`;
                                        } else {
                                          formattedValue = parseFloat(value.toFixed(2)).toString();
                                          if (formattedValue.indexOf('.') > -1) {
                                            formattedValue = formattedValue.replace(/\.0+$/, '');
                                          }
                                        }

                                        return value < 0 && !formattedValue.startsWith('-') ? `-${formattedValue}` : formattedValue;
                                      }
                                    }
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {selectedCategory === 'Interest Rates' && (
                <p style={{ marginTop: '2em', fontStyle: 'italic', fontSize: '0.8em' }}>
                  *Long-term interest rate: 10 Year in general; Short-term interest rate: 3-month in general.
                </p>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default NearTermForecast;
