import React from 'react';

const SummaryDisplayForecastAI = ({ summary }) => {
  const preprocessSection = (sectionString) => {  

    const firstTitleRegex = /1\.\s+[^:]+:/;
    const firstTitleMatch = sectionString.match(firstTitleRegex);
    let leadingText = 'Here are the key factors that could influence price movements:';
  
    // Remove the leading text before the first title
    if (firstTitleMatch && firstTitleMatch.index !== -1) {
      sectionString = sectionString.substring(firstTitleMatch.index);
    }
    
    // The rest of your logic here
    if (sectionString.startsWith('["')) {
      sectionString = sectionString.substring(2);
    }
    if (sectionString.endsWith('"]')) {
      sectionString = sectionString.substring(0, sectionString.length - 2);
    }
  
    // Replace escaped double quotes at the start and end of a phrase with regular double quotes
    sectionString = sectionString.replace(/\\"(.*?)\\"/g, '"$1"');
  
    sectionString = sectionString.replace(/\\n/g, '\n');
    sectionString = sectionString.replace(/[[]"]/g, '').trim();
    sectionString = sectionString.replace(/\*/g, '');
  
    const titles = [
      "1. Decision Tree:",
      "2. Market Sentiment and News Impact:", 
      "3. Macroeconomic Factors:", 
      "4. Technical Factors:", 
      '2. Fundamentals and Central Bank Policies:',
      '3. Market Sentiment and News Impact:',
      '4. Macroeconomic Factors:',
      '5. Technical Factors:',

    ];
  
    const titleRegex = new RegExp(`(\\d*\\.?\\s?(${titles.join('|')})\\s?)`, 'g');
  
    let sections = sectionString.split(titleRegex).filter(section => section.trim() !== '');
  
    let processedLines = [];
  
    // Add the leading text as regular text (not bold)
    if (leadingText.trim() !== '') {
      processedLines.push({ type: 'text', content: leadingText.trim() });
    }
  
    for (let i = 0; i < sections.length; i++) {
      if (titles.some(title => sections[i].includes(title))) {
        processedLines.push({ type: 'title', content: sections[i].replace(/\*+/g, '').trim() });
        i++;
      } else {
        let lines = sections[i].split('\n').filter(line => line.trim() !== '');
        lines.forEach(line => {
          // Check if line matches the pattern "number. text:" or non-numbered text ending with a colon
          if (/^\d+\.\s+.*:$/.test(line.trim()) || /^[^-\d].*:$/.test(line.trim())) {
            processedLines.push({ type: 'boldText', content: line.trim() });
          } else if (line.trim().startsWith('- ')) {
            processedLines.push({ type: 'item', content: line.trim().substring(2) });
          } else if (line.trim() !== '') {
            processedLines.push({ type: 'text', content: line.trim() });
          }
        });
      }
    }
  
    return processedLines.map(line => {
      if (line.type === 'text' || line.type === 'item' || line.type === 'boldText') {
        line.content = line.content.split('\n').join('<br />');
      }
      return line;
    });
  };
  

  return (
    <div style={{ padding: '0px', background: '#f5f5f5', borderRadius: '8px', margin: '10px 0' }}>
      {summary.map((section, index) => {
        const processedLines = preprocessSection(section);
        return (
          <div key={index} style={{
              background: '#eef2f5',
              padding: '10px',
              margin: '10px 0',
              borderRadius: '4px',
              borderLeft: '5px solid #007bff'
            }}>
            
            {processedLines.map((line, lineIndex) => {
              if (line.type === 'title' || line.type === 'boldText') {
                return <p key={lineIndex} style={{fontWeight: 'bold'}} dangerouslySetInnerHTML={{ __html: line.content }}></p>
              } else if (line.type === 'item') {
                return (
                  <ul style={{paddingLeft: '15px'}}>
                    <li key={lineIndex} dangerouslySetInnerHTML={{ __html: line.content }}></li>
                  </ul>
                );
              } else if (line.type === 'text') {
                return <p key={lineIndex} dangerouslySetInnerHTML={{ __html: line.content }}></p>
              } else {
                return null;
              }
            })}
          </div>
        );
      })}
    </div>
  );
};

export default SummaryDisplayForecastAI;