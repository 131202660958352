import React, { useEffect, useState } from 'react';
import { useParams, useLocation,Link } from 'react-router-dom';
import LatestDevelopments from './LatestDevelopments';
import styles from '../../MicroProbe//MicroProbe.module.css';




const IndividualCommodityNews = () => {
  const { commodityName } = useParams();
  const location = useLocation();
  const [inputData, setInputData] = useState(null);
  
  useEffect(() => {
    // Check if state is passed and has the required properties
    if (location.state && location.state.code) {
      setInputData({
        name: commodityName,
        code: location.state.code
      });
    }
  }, [commodityName, location.state]);


return (
  <div className={styles.container}>
    <h1 className={styles.title}>Commodity: {commodityName.replace("Futures", "").trim()}</h1>

    {inputData && (
      <>  
        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <LatestDevelopments  inputData={inputData} />
          </div>
          
          {/*}
          <div className={styles.divider}></div>
          <div className={styles.sectionContent}>
            <TopicGather/>
          </div>  */}
         
        </div>




      </>
    )}

    <div style={{ marginTop: '20px' }}>
        <Link to="/news-suite">Back</Link>
      </div> 
  </div>
);
};

export default IndividualCommodityNews;