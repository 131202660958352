import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../../../../axiosConfig';
import LatestDevelopments from './LatestDevelopments';
import EconomyHighlight from './EconomyHighlight';
import CentralBankHighlight from './CentralBankHighlight';
import StockHighlight from './StockHighlight';
import PerformanceOverview from './PerformanceOverview';
import styles from '../../MicroProbe//MicroProbe.module.css';


const IndividualBondNews = () => {
  const { economyName } = useParams();
  const [inputData, setInputData] = useState(null);
  
  useEffect(() => {
    const fetchInputData = async () => {
      try {
        const response = await axios.get(`/api/country/name/${economyName}`);
        setInputData(response.data);

      } catch (error) {
        console.error('Error fetching currency data:', error);
      }
    };
  
    fetchInputData();
  }, [economyName]);


return (
  <div className={styles.container}>
    <h1 className={styles.title}>{economyName } Bond</h1>

    {inputData && (
      <>  
        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <LatestDevelopments  inputData={inputData} />
          </div>  
    
        </div>

        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
              <EconomyHighlight inputData={inputData} />
            </div>
            <div className={styles.dividernone}></div> 

            <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
              <CentralBankHighlight inputData={inputData} />
            </div>
            <div className={styles.dividernone}></div> 

            <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
              <StockHighlight inputData={inputData} />
            </div>
            <div className={styles.dividernone}></div> 

            <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <PerformanceOverview  inputData={inputData} />
          </div>
      
        </div>
 

      </>
    )}

    <div style={{ marginTop: '20px' }}>
        <Link to="/news-suite">Back</Link>
      </div> 
  </div>
);
};

export default IndividualBondNews;