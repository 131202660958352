import React, { useState, useRef } from 'react';
import axiosInstance from '../../../../axiosConfig';  // Custom Axios instance
import axios from 'axios';  // Original Axios library
import styles from './ChatNumbers.module.css';  // Import the new CSS module

const ChatNumbers = ({ companyData }) => {
  const [customerRequest, setCustomerRequest] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [displayChatHistory, setDisplayChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showChat, setShowChat] = useState(false); 
  const source = useRef(axios.CancelToken.source());

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const maxRetries = 8;
    const delay = 2000; 
    let retries = 0;

    const fetchData = async () => {
      try {
        if (source.current) {
          source.current.cancel('Operation canceled by the user.');
        }
        source.current = axios.CancelToken.source();

        const response = await axiosInstance.post('/chat_with_numbers/', {
          customer_request: customerRequest,
          default_ticker: companyData.symbol,
          chat_history: chatHistory,
        }, {
          cancelToken: source.current.token,
        });


        if (response.data.error) {
          throw new Error(response.data.error);
        }

        const combinedResponseContent = response.data.response +
          (response.data.new_images && response.data.new_images.length > 0
            ? '<br>' + response.data.new_images.map(image =>
                `<div class="${styles.scrollableImageContainer}"><img src="${image}" alt="Stock related visual" class="${styles.image}"></div>`
              ).join('')
            : '');

        setChatHistory([
          ...chatHistory,
          { role: 'user', content: customerRequest },
          { role: 'assistant', content: response.data.response },
        ]);
        setDisplayChatHistory([
          ...displayChatHistory,
          { role: 'user', content: customerRequest },
          { role: 'assistant', content: combinedResponseContent },
        ]);

        setCustomerRequest('');
        setResponseMessage('');
        setLoading(false);
      } catch (error) {
        console.error('There was an error!', error);

        if (error.message === 'Connection error.' && retries < maxRetries) {
          retries += 1;
          //console.log(`Connection error. Retrying (${retries}/${maxRetries}) in ${delay / 1000} seconds...`);
          setTimeout(fetchData, delay);
        } else {
          setResponseMessage(error.response ? error.response.data.error : 'An error occurred. Please try again.');
          setLoading(false);
        }
      }
    };

    fetchData();
  };

  const handleChatButtonClick = () => {
    setShowChat(!showChat); 
    if (showChat) { 
      setChatHistory([]); 
      setDisplayChatHistory([]); 
    }
  };

  return (
    <div>
      <button onClick={handleChatButtonClick} className={styles.chatButton}>Chat with Numbers</button>
      {showChat && ( 
        <>
          <div className={styles.chatContainer}>
            {displayChatHistory.map((message, index) => (
              <div key={index} className={styles.message}>         
                <strong>{message.role === 'user' ? 'You: ' : 'Assistant: '}</strong>
                {message.role === 'user' || !message.isImage 
                  ? <pre className={styles.messageContent} dangerouslySetInnerHTML={{ __html: message.content }} />
                  : (
                      <div className={styles.scrollableImageContainer}>
                        <img src={message.src} alt="Stock related visual" className={styles.image} />
                      </div>
                    )
                }
              </div>
            ))}

            {responseMessage && (
              <div className={styles.errorMessage}>
                {responseMessage}
              </div>
            )}

            <form onSubmit={handleSubmit} className={styles.formContainer}>
              <textarea 
                value={customerRequest} 
                onChange={e => { setCustomerRequest(e.target.value); setResponseMessage(''); }}  
                placeholder="Ask about numbers"
                rows="4" 
                className={styles.textarea} 
              />
              <button type="submit" disabled={loading} className={styles.submitButton}>Ask</button>
            </form>

            {loading && <p>Loading...</p>}
          </div>
        </>
      )}
    </div>
  );
};

export default ChatNumbers;
