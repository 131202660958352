import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 
import styles from './SuiteTextVideo.module.css'; // Import the same CSS module as Code 2
import MicroProbeCompanyEarnings from './AIMarketDecoder/MicroProbe/MicroProbeEarnings';
import MicroProbeCompanySEC from './AIMarketDecoder/MicroProbe/MicroProbeSEC';
import VideoInsight from './AIMarketDecoder/MicroProbe/MicroProbeVideos';
import DocumentUploads from './AIMarketDecoder/MicroProbe/MicroProbeDocuments';

const TextsVideosSuite = () => {
  const [activeComponent, setActiveComponent] = useState(null);
  const navigate = useNavigate(); 

  const showCompaniesEarningsInput = () => {
    setActiveComponent('earnings');
  };

  const showCompaniesSECInput = () => {
    setActiveComponent('SEC');
  };

  const showVideoInsightInput = () => {
    navigate('/videos/');
  };

  const showDocumentInsightInput = () => {
    navigate('/documents/');
  };

  return (
    <div>
      {/* Main container */}
      <div className={styles.container}>
        {/* Heading */}
        <h3 className={styles.heading}>Texts & Videos Suite</h3>

        {/* Buttons */}
        <div className={styles.buttonContainer}>
          <button className={`${styles.button} ${activeComponent === 'earnings' ? styles.active : ''}`} onClick={showCompaniesEarningsInput}>Earnings Call Transcripts</button>
          <button className={`${styles.button} ${activeComponent === 'SEC' ? styles.active : ''}`} onClick={showCompaniesSECInput}>SEC Filings</button>
          <button className={`${styles.button} ${activeComponent === 'documents' ? styles.active : ''}`} onClick={showDocumentInsightInput}>Documents</button>
          <button className={`${styles.button} ${activeComponent === 'videos' ? styles.active : ''}`} onClick={showVideoInsightInput}>Video Insight</button>
        </div>
      </div>

      {/* Active Section */}
      <div className={`${styles.activeSection} ${activeComponent ? styles.active : ''}`}>
        {activeComponent === 'earnings' && (
          <div className={styles.earningsInputBox}>
            <MicroProbeCompanyEarnings />
          </div>
        )}
        {activeComponent === 'SEC' && (
          <div className={styles.secInputBox}>
            <MicroProbeCompanySEC />
          </div>
        )}

        {activeComponent === 'documents' && (
          <div className={styles.documentInputBox}>
            <DocumentUploads />
          </div>
        )}

        {activeComponent === 'videos' && (
          <div className={styles.videoInputBox}>
            <VideoInsight />
          </div>
        )}
      </div>

      {/* Back link */}
      <div className={styles.backLinkContainer}>
        <Link to="/">Back</Link>
      </div>
    </div>
  );
};

export default TextsVideosSuite;
