import React, { useState } from 'react';
import axios from './axiosConfig';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';

const Login = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // New state for password visibility
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('/login/', { username, password });

      if (response && response.status === 200) {
        const { access, refresh } = response.data;

        localStorage.setItem('access_token', access);
        localStorage.setItem('refresh_token', refresh);

        setIsAuthenticated(true);
        navigate('/');
      } else {
        setError('Invalid login response');
      }
    } catch (error) {
      if (error.response) {
        setError('Invalid login credentials');
      } else if (error.request) {
        setError('No response from the server. Please try again.');
      } else {
        setError('Network error or server not reachable');
      }

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
        required
        className={styles.input}
      />
      <div className={styles.passwordContainer}>
        <input
          type={showPassword ? 'text' : 'password'} // Toggle input type
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          className={styles.input}
        />
        <span
          className={styles.showPasswordIcon}
          onClick={() => setShowPassword(!showPassword)}
        >
          <i className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
        </span>
      </div>
      <button type="submit" className={styles.button} disabled={loading}>
        {loading ? 'Logging in...' : 'Login'}
      </button>
      {loading && <div className={styles.loadingSpinner}></div>}
      {error && <p className={styles.error}>{error}</p>}
    </form>
  );
};

export default Login;