import axios from '../axiosConfig';


export const fetchEarningsTitles = async (companyId) => {
    try {
        const response = await axios.get(`/earnings-titles/${companyId}/`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch earnings titles:', error);
        return [];  
    }
};

