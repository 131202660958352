import React from 'react';
import { Link } from 'react-router-dom';
import IdeasMaker from './AIMarketDecoder/IdeasMaker';
import styles from './SuiteIdeaMaker.module.css'; // Import the CSS module

const IdeaMakerSuite = () => {
  return (
    <div>
      {/* Main container */}
      <div className={styles.container}>
        {/* Heading */}
        <h3 className={styles.heading}>Instant Report Suite</h3>

        {/* Active Section */}
        <div className={styles.activeSection}>
          <IdeasMaker />
        </div>
      </div>

      {/* Back link */}
      <div className={styles.backLinkContainer}>
        <Link to="/">Back</Link>
      </div>
    </div>
  );
};

export default IdeaMakerSuite;
