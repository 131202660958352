import React, { useState, useRef } from 'react';
import axiosInstance from '../../../../axiosConfig';
import axios from 'axios';
import SummaryDisplay from './SummaryDisplay';
import styles from '../MicroProbeVideo.module.css';

const VideoInsight = () => {
  const [url, setUrl] = useState('');
  const [question, setQuestion] = useState('');
  const [summary, setSummary] = useState([]);
  const [loadingHighlights, setLoadingHighlights] = useState(false);
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const source = useRef(axios.CancelToken.source());
  const [isUrlSubmitted, setIsUrlSubmitted] = useState(false);

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleUrlSubmit = () => {
    setIsUrlSubmitted(true);
    setConversationHistory([]);
    setQuestion('');
  };

  const handleKeyHighlightsClick = async () => {
    if (url) {
      setLoadingHighlights(true);
      setSummary(null);
      try {
        source.current.cancel('Operation canceled by the user.');
        source.current = axios.CancelToken.source();

        const response = await axiosInstance.post(`/youtube_transcript_summary/?transcript=${encodeURIComponent(url)}`, {
          cancelToken: source.current.token,
        });
        const parsedResponse = response.data.response;
        setSummary(parsedResponse);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
      setLoadingHighlights(false);
    }
  };

  const handleAskQuestionClick = async () => {
    if (url && question) {
      setLoadingQuestion(true);
      try {
        source.current.cancel('Operation canceled by the user.');
        source.current = axios.CancelToken.source();
        const response = await axiosInstance.post('/youtube_chat/', {
          question: question,
          chat_history: conversationHistory,
          url: url,
        }, {
          cancelToken: source.current.token,
        });
        setConversationHistory([...conversationHistory, { question: question, answer: response.data.answer }]);
        setQuestion('');
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error('Error fetching answer:', error);
        }
      }
      setLoadingQuestion(false);
    }
  };

  const handleChatClick = () => {
    setShowChat(!showChat);
  };

  return (
    <div className={styles.container}>
      <h4>Analysis</h4>
      <div className={`${styles.sectionDashboard} ${styles.sectionQuarter}`}>
        <div className={styles.sectionContent}>
          <label style={{ fontSize: 'small' }}>URL: </label>
          <input
            type="text"
            value={url}
            onChange={handleUrlChange}
            placeholder="Enter Youtube URL"
            className={styles.urlInput}
            style={{ padding: '10px' }}
          />
          <button onClick={handleUrlSubmit}>Go</button>
          {isUrlSubmitted && (
            <div className={styles.actionsContainer}>
              <div>
                <button onClick={handleKeyHighlightsClick}>Get Key Highlights</button>
                {loadingHighlights && <p>Loading...</p>}
                {summary && summary.length > 0 && <SummaryDisplay summary={summary} />}
              </div>
              <div>
                <button onClick={handleChatClick}>Chat</button>
                {showChat && (
                  <div className={styles.chatContainer}>
                    {conversationHistory.length > 0 && (
                      <div>
                        <h3>Conversation History:</h3>
                        {conversationHistory.map((qaPair, index) => (
                          <div key={index}>
                            <h4>Q: {qaPair.question}</h4>
                            <p>A: {qaPair.answer}</p>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className={styles.questionInputContainer}>
                      <textarea
                        type="text"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        placeholder="Ask about the video..."
                        className={styles.questionInput}
                      />
                      <button onClick={handleAskQuestionClick}>Ask Question</button>
                      {loadingQuestion && <p>Loading...</p>}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoInsight;
