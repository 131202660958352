import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../../../../axiosConfig';
import LatestDevelopments from './LatestDevelopments';
import styles from '../../MicroProbe//MicroProbe.module.css';
import IdeasMaker from '../../IdeasMaker';
import TopicGather from '../../TopicGather';
import ChatNumbersShortTermEconomics from './ChatNumbersShortTermEconomics';
import LongTermOutlook from './LongTermOutlook';
import PerformanceOverview from './PerformanceOverview';
import CentralBankHighlight from './CentralBankHighlight';
import EconomyHighlight from './EconomyHighlight';
import StockHighlight from './StockHighlight';
import ShortTermEconomics from './ShortTermEconomics';
import NearTermForecast from './NearTermForecast';
import ChatNumbersLongTermOutlook from './ChatNumbersLongTermOutlook';
import ChatNumbersNearTermForecast from './ChatNumbersNearTermForecast';
import VideoInsight from '../../MicroProbe/Companies/VideoInsightForDashboard';
import ChatUrl from '../../MicroProbe/Companies/ChatUrl';



const Countries = () => {
  const { economyName } = useParams();
  const [inputData, setInputData] = useState(null);
  
  useEffect(() => {
    const fetchInputData = async () => {
      try {
        const response = await axios.get(`/api/country/name/${economyName}`);
        setInputData(response.data);

      } catch (error) {
        console.error('Error fetching currency data:', error);
      }
    };
  
    fetchInputData();
  }, [economyName]);


return (
  <div className={styles.container}>
    <h1 className={styles.title}>{economyName }</h1>

    {inputData && (
      <>  
        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <LatestDevelopments  inputData={inputData} />
          </div>
  
    
        </div>


        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
              <EconomyHighlight inputData={inputData} />
            </div>
            <div className={styles.dividernone}></div> 

            <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
              <CentralBankHighlight inputData={inputData} />
            </div>
            <div className={styles.dividernone}></div> 

            <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
              <StockHighlight inputData={inputData} />
            </div>
            <div className={styles.dividernone}></div> 

            <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <PerformanceOverview  inputData={inputData} />
          </div>
          <div className={styles.dividernone}></div> 
      
        </div>

  
        <div className={`${styles.section} ${styles.sectionQuarter}`}>

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <ShortTermEconomics inputData={inputData} />
          </div>
          <div className={styles.dividernone}></div>

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <ChatNumbersShortTermEconomics inputData={inputData} />
          </div>

          <div className={styles.dividernone}></div> 
       
    
        </div>


        <div className={`${styles.section} ${styles.sectionQuarter}`}>

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}> 
            <TopicGather />
          </div>
          <div className={styles.divider}></div>

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}> 
            <ChatUrl />
          </div>
          
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}> 
            <VideoInsight inputData={inputData} />
          </div>
          
        </div>

    

        <div className={`${styles.section} ${styles.sectionQuarter}`}>



        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <LongTermOutlook inputData={inputData} />
          </div>
          <div className={styles.dividernone}></div>
        

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <ChatNumbersLongTermOutlook inputData={inputData} />
          </div>
          <div className={styles.divider}></div>

   
        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <NearTermForecast inputData={inputData} />
        </div>
        <div className={styles.dividernone}></div>
        

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <ChatNumbersNearTermForecast inputData={inputData} />
        </div>
        <div className={styles.dividernone}></div> 
        </div>

        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>   
          <IdeasMaker />
        </div>
        </div>

      </>
    )}

    <Link to="/ai-market-dashboard">Back</Link>
  </div>
);
};

export default Countries;