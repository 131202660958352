import axios from '../axiosConfig';

export const newsSummary = async (keyword) => {
    const response = await axios.get('/keyword_news_summary/', {
        params: {
            keyword: keyword
        }
    });
    return response.data; // This should return the 'data' part of the response
};


export const newsSummaryStock = async (keyword) => {
    const response = await axios.get('/keyword_news_summary_stock/', {
        params: {
            keyword: keyword
        }
    });
    return response.data; // This should return the 'data' part of the response
};


export const newsSummaryPrice = async (keyword, inputDataName) => {
    const response = await axios.get('/keyword_news_summary_price/', {
        params: {
            keyword: keyword,
            input_data_name: inputDataName, // added this line
        },
    });
    return response.data; // This should return the 'data' part of the response
};