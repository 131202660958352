import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import axios from '../../../../axiosConfig';
import LatestDevelopments from './LatestDevelopments';
import EarningsPress from './EarningsPress';
import styles from '../MicroProbe.module.css';
import IdeasMaker from '../../IdeasMaker';
import SecFilings from './SecFillings';
import SentimentTrend from './SentimentTrend';
import Networks from './Networks';
import ChatNumbers from './ChatNumbers';
import FinancialsOverview from './FinancialsOverview';
import FinancialData from './FinancialData';
import StockPerformanceOverview from './StockPerformanceOverview';
import SignalsBacktestTechnicalsPlot from './SignalsBacktestTechnicalsPlot';
import SignalsBacktestTechnicals from './SignalsBacktestTechnicals';
import SignalsBacktestFundamentals from './SignalsBacktestFundamentals';
import SignalsBacktestAI from './SignalsBacktestAI';
import SignalsBacktestMacro from './SignalsBacktestMacro';
import VideoInsight from './VideoInsightForDashboard';
import ChatUrl from './ChatUrl';



const Companies = () => {
  const { companyName } = useParams();
  const location = useLocation(); // Use useLocation hook to access the location object
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        let data;

        if (location.state && location.state.ticker) {
          const { ticker } = location.state;
          data = { name: companyName, symbol: ticker };
        } else {
          const response = await axios.get(`/api/company/name/${companyName}`);
          data = response.data;

        }

        setCompanyData(data);
     
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };
  
    fetchCompanyData();
  }, [companyName, location.state]);

return (
  <div className={styles.container}>
    <h1 className={styles.title}>Company Analysis: {companyName.includes('_ticker_') ? companyName.split('_ticker_')[0] : companyName}</h1>

    {companyData && (
      <>
    
        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent}>
            <LatestDevelopments  companyData={companyData} />
          </div> {/*}
          <div className={styles.divider}></div>
          <div className={styles.sectionContent}>
            <TopicGather companyData={companyData} />
          </div>  */}
        </div>

        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <SentimentTrend  companyData={companyData} />
          </div>
          
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <Networks  companyData={companyData} />
          </div>
        </div>

  
        <div className={`${styles.section} ${styles.sectionQuarter}`}>
        <div className={styles.sectionContent}>
            <StockPerformanceOverview  companyData={companyData} />
          </div>
          <div className={styles.sectionContent}>
            <FinancialsOverview companyData={companyData} />
          </div>
          <div className={styles.divider}></div>

          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <FinancialData companyData={companyData} />
          </div>
         

      
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>     
            <ChatNumbers companyData={companyData} />
          </div>
          <div className={styles.dividernone}></div> 
          
        </div>


        <div className={`${styles.section} ${styles.sectionQuarter}`}>
     
          <div className={styles.sectionContent}>
            <EarningsPress companyData={companyData} />
          </div>
          <div className={styles.dividernone}></div>
          <div className={styles.sectionContent}>
            <SecFilings companyData={companyData} />
          </div>

          <div className={styles.divider}></div>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <ChatUrl />
          </div>
          
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <VideoInsight />
          </div>
        </div>

        <div className={`${styles.section} ${styles.sectionQuarter}`}>

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestAI companyData={companyData} />
        </div>
        <div className={styles.divider}></div>

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestTechnicals companyData={companyData} />
        </div>
        <div className={styles.dividernone}></div>  
   
        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestTechnicalsPlot companyData={companyData} />
        </div>
        <div className={styles.divider}></div>

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestFundamentals companyData={companyData} />
        </div>
        <div className={styles.divider}></div>

        <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
          <SignalsBacktestMacro companyData={companyData} />
        </div>
        <div className={styles.divider}></div>
        </div>
       

        
        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>   
          <IdeasMaker />
        </div>
        </div>

      </>
    )}

    <Link to="/ai-market-dashboard">Back</Link>
  </div>
);
};

export default Companies;