import React, { useState, useEffect, useRef } from 'react';
import styles from './SecFilings.module.css';

const CustomDropdown = ({ options, onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleOptionClick = (index) => {
    onChange(index);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.customDropdown} ref={dropdownRef}>
      <div className={styles.customDropdown__selected} onClick={handleToggle}>
        {value !== null ? options[value] : 'Select Filing'}
        <span className={styles.customDropdown__arrow}></span>
      </div>
      {isOpen && (
        <div className={styles.customDropdown__menu}>
          {options.map((option, index) => (
            <div
              key={index}
              className={styles.customDropdown__option}
              onClick={() => handleOptionClick(index)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;