import React, { useState, useRef } from 'react';
import axiosInstance from '../../../../axiosConfig';
import axios from 'axios';
import styles from './InsightExplorer.module.css'; // Updated the path for the new CSS module


const InsightExplorer = () => {
  const [customerRequest, setCustomerRequest] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [displayChatHistory, setDisplayChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showChat, setShowChat] = useState(false); 
  const source = useRef(axios.CancelToken.source());

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      source.current.cancel('Operation canceled by the user.');
      source.current = axios.CancelToken.source();

      const response = await axiosInstance.post('/chat_with_insight_explorer/', {
        customer_request: customerRequest,
        chat_history: chatHistory,
      }, {
        cancelToken: source.current.token,
      });
  

      setChatHistory([
        ...chatHistory,
        { role: 'user', content: customerRequest },
        { role: 'assistant', content: response.data }, 
      ]);
      setDisplayChatHistory([
        ...displayChatHistory,
        { role: 'user', content: customerRequest },
        { role: 'assistant', content: typeof response.data === 'string' ? response.data.replace(/\n/g, '<br/>') : JSON.stringify(response.data, null, 2).replace(/\n/g, '<br/>') },
      ]);
      setCustomerRequest('');
      setResponseMessage('');
    } catch (error) {
      console.error('There was an error!', error);
      setResponseMessage(error.response ? error.response.data.error : "An error occurred");
    }
    setLoading(false);
  };

  const handleChatButtonClick = () => {
    setShowChat(!showChat); 
    if (showChat) { 
      setChatHistory([]); 
      setDisplayChatHistory([]); 
    }
  };

  return (
    <div className={styles.insightExplorer}>
      <div>
        <button onClick={handleChatButtonClick} className={styles.chatButton}>Chat</button> {/* New Chat button */}
        {showChat && ( 
          <>
            <div className={styles.chatContainer}>
              {displayChatHistory.map((message, index) => (
                <div key={index} className={styles.message}>
                  <strong>{message.role === 'user' ? 'You: ' : 'Assistant: '}</strong>
                  {message.role === 'user' ? (
                    message.content
                  ) : (
                    <pre className={styles.messageContent} dangerouslySetInnerHTML={{ __html: message.content }} />
                  )}
                </div>
              ))}

              <form onSubmit={handleSubmit}>
                <textarea 
                  value={customerRequest} 
                  onChange={e => { setCustomerRequest(e.target.value); setResponseMessage(''); }}  
                  placeholder="Message Insight Explorer"  
                  rows="6" 
                  className={styles.textarea} 
                />
                <button type="submit" disabled={loading} className={styles.submitButton}>Go</button>
              </form>
              {loading && <p>Loading...</p>}
            </div>
            <pre>{responseMessage}</pre>
          </>
        )}
      </div>
    </div>
  );
};

export default InsightExplorer;
