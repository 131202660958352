import React, { useState, useRef, useEffect } from 'react';
import axiosInstance from '../../../../axiosConfig';
import axios from 'axios';

const ChatUrl = () => {
  const [customerRequest, setCustomerRequest] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [displayChatHistory, setDisplayChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showChat, setShowChat] = useState(false); 
  const [chatWidth, setChatWidth] = useState('80%');
  const source = useRef(axios.CancelToken.source());

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setChatWidth('100%');
      } else {
        setChatWidth('80%');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial value

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      source.current.cancel('Operation canceled by the user.');
      source.current = axios.CancelToken.source();

      const response = await axiosInstance.post('/chat_find_youtube_url/', {
        customer_request: customerRequest,
        chat_history: chatHistory,
      }, {
        cancelToken: source.current.token,
      });
  
      setChatHistory([
        ...chatHistory,
        { role: 'user', content: customerRequest },
        { role: 'assistant', content: response.data }, 
      ]);
      setDisplayChatHistory([
        ...displayChatHistory,
        { role: 'user', content: customerRequest },
        { role: 'assistant', content: typeof response.data === 'string' ? response.data.replace(/\n/g, '<br/>') : JSON.stringify(response.data, null, 2).replace(/\n/g, '<br/>') },
      ]);
      setCustomerRequest('');
      setResponseMessage('');
    } catch (error) {
      console.error('There was an error!', error);
      setResponseMessage(error.response ? error.response.data.error : "An error occurred");
    }
    setLoading(false);
  };

  const handleChatButtonClick = () => {
    setShowChat(!showChat); 
    if (showChat) { 
      setChatHistory([]); 
      setDisplayChatHistory([]); 
    }
  };

  const formatMessage = (message) => {
    message = message.replace(/^\[/, 'Here are some videos I found. Please let me know if you have any follow up questions:').replace(/\]$/, '');
    message = message.replace(/\},\s*\{/g, ', ').replace(/{/g, '').replace(/}/g, '');
    const boldWords = ["Title", "Description", "Channel", "Duration", "Publish_date", "Url"];
    boldWords.forEach(word => {
      const regex = new RegExp(`"${word}":`, 'g');
      message = message.replace(regex, `<b>${word}</b>:`);
    });
    const segments = message.split(/(".*?")/);
    message = segments.map((segment, index) => index % 2 === 0 ? segment.replace(/,/g, '') : segment).join('');
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    return message.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
  };

  return (
    <div style={{ padding: '0px', fontFamily: 'Arial, sans-serif' }}>
      <h4 style={{ fontSize: '1.2em', textAlign: 'left' }}>Video Insight</h4>
      <button onClick={handleChatButtonClick} style={{ width: 'auto', padding: '10px', backgroundColor: 'white', borderRadius: '5px', cursor: 'pointer' }}>
        Find Videos
      </button>
      {showChat && ( 
        <div style={{ marginTop: '15px' }}>
          <div style={{ overflowY: 'auto', maxHeight: '400px', flexGrow: 1, padding: '2px', background: '#f5f5f5', borderRadius: '8px', margin: '10px 0', width: chatWidth, marginLeft: '0px', marginRight: 'auto' }}>
            {displayChatHistory.map((message, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <strong>{message.role === 'user' ? 'You: ' : 'Assistant: '}</strong>
                {message.role === 'user' ? message.content : <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: formatMessage(message.content) }} />}
              </div>
            ))}

            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <textarea 
                value={customerRequest} 
                onChange={e => { setCustomerRequest(e.target.value); setResponseMessage(''); }}  
                placeholder="Search URLs ..."
                rows="4" 
                style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', marginBottom: '0px', resize: 'none' }}
              />
          
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <input type="submit" value="Go" style={{ padding: '10px', backgroundColor: 'white', borderRadius: '5px', cursor: 'pointer', borderColor: '#ccc', borderWidth: '1px', borderStyle: 'solid' }} />
              </div>
            </form>

            {loading && <p style={{ textAlign: 'left', color: '#888' }}>Loading...</p>}
          </div>
          <pre style={{ textAlign: 'left', color: 'red' }}>{responseMessage}</pre>
        </div>
      )}
    </div>
  );
};

export default ChatUrl;