import React, { useState, useEffect, useRef } from 'react';
import styles from './CustomDropdownFinancialData.module.css';

const CustomDropdown = ({ options, onSelect, placeholder, resetTrigger }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(placeholder);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const displayOption = option.replace(/^\d+\.\s*/, ''); // Remove leading number and period
    setSelectedOption(displayOption); // Set the selected option text
    onSelect(option); // Pass original selected option to parent component
    setIsOpen(false); // Close dropdown
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  // Reset selected option when `resetTrigger` changes
  useEffect(() => {
    setSelectedOption(placeholder);
  }, [resetTrigger, placeholder]);

  return (
    <div className={styles.customDropdown} ref={dropdownRef}>
      <div className={styles.selected} onClick={toggleDropdown}>
        {selectedOption}
        <span className={styles.arrow}></span>
      </div>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {options.map((option, index) => {
            const displayOption = option.replace(/^\d+\.\s*/, ''); // Remove leading number and period
            return (
              <div
                key={index}
                className={styles.dropdownOption}
                onClick={() => handleOptionClick(option)}
              >
                {displayOption}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;