import axios from '../axiosConfig';

export const fetchFilingNumbers = async (cik) => {
    try {
        const response = await axios.get(`/company-filings/${cik}/`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch filing numbers:', error);

        return [];  
    }
};

