import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import AutocompleteInput from './AutocompleteInput'; 
import styles from './MicroProbe.module.css'; 


const MicroProbeSignals = () => {
  let navigate = useNavigate(); 
  const [companyName, setCompanyName] = useState('');
  const [ticker, setTicker] = useState('');

  const handleCompanySelect = (selectedCompanyName) => {
    // If a company is selected from AutocompleteInput, navigate without the ticker.
    navigate(`/company-signals/${selectedCompanyName}`);
  };
  


  const handleInputChange = (e, type) => {
    if (type === 'companyName') {
      setCompanyName(e.target.value);
    } else if (type === 'ticker') {
      setTicker(e.target.value);
    }
  };

  const handleSubmit = () => {
  
    //const path = ticker ? `${companyName}_ticker_${ticker}` : companyName;
    //navigate(`/company-details/${path}`);
    const path = `/company-signals/${companyName}`;
    navigate(path, { state: { ticker } });
  };

  return (
    <div >

      <div className={styles.searchSection}>
        <h5>Company:</h5>
   
        <AutocompleteInput onCompanySelect={handleCompanySelect} id="autocompleteInput" />
        <h5>Or input company:</h5>

        <input
          id="companyName"
          type="text"
          placeholder="Company Name"
          value={companyName}
          onChange={(e) => handleInputChange(e, 'companyName')}
          className={styles.suiteinput}
        />
        <input
          id="ticker"
          type="text"
          placeholder="Ticker"
          value={ticker}
          onChange={(e) => handleInputChange(e, 'ticker')}
          className={styles.suiteinput}
        />
        <button onClick={handleSubmit} className={styles.suitebutton}>Go</button>
      </div>
     

    </div>
  );
};

export default MicroProbeSignals;