import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axios from '../../../../axiosConfig';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import styles from '../../MicroProbe/Companies/FinancialData.module.css'; // Importing CSS
import CustomDropdown from './CustomDropdown'; 

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const commodityOptions = [
  { name: 'Palladium Futures', code: 'XPDUSD:CUR' },
  { name: 'Gold', code: 'XAUUSD:CUR' },
  { name: 'Platinum', code: 'XPTUSD:CUR' },
  { name: 'Silver', code: 'XAGUSD:CUR' },
  { name: "Crude Oil Futures", code: "CL1:COM"},
  { name: "Brent Futures", code: "CO1:COM"},
  { name: "Natural Gas Futures", code: "NG1:COM"},
  { name: 'Gasoline Futures', code: 'XB1:COM' },
  { name: 'Sugar Futures', code: 'SB1:COM' },
  { name: 'Corn Futures', code: 'C_1:COM' },
  { name: 'Soybeans Futures', code: 'S_1:COM' },
  { name: 'Coffee Futures', code: 'KC1:COM' },
  { name: 'Wheat Futures', code: 'W_1:COM' },
  { name: 'Nickel Futures', code: 'LN1:COM' },
  { name: 'Aluminum', code: 'LMAHDS03:COM' },
  { name: 'Copper Futures', code: 'HG1:COM' },
  { name: 'Tin Futures', code: 'LMSNDS03:COM' },
  { name: 'Steel Futures', code: 'JBP:COM' },
  { name: 'Iron Ore Futures', code: 'SCO:COM' },
  { name: 'Industrial Metals - Invesco DB Base Metals Fund ETF', code: 'DBB' },
  { name: 'Diversified Commodities - Bloomberg Commodities Index', code: 'BCI' },
  { name: 'Agriculture - Invesco DB Agriculture Fund ETF', code: 'DBA' },
  { name: 'Energy - Invesco DB Energy Fund ETF', code: 'DBE' },
  { name: 'Diversified Commodities - Goldman Sachs Commodities Index', code: 'GSG' }
];

function CompareData() {
  const [selectedCommodities, setSelectedCommodities] = useState([]);
  const [financialData, setFinancialData] = useState({});
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
 

  const handleCommodityChange = (selectedValue) => {
    const commodityCode = selectedValue;
    if (commodityCode && !selectedCommodities.includes(commodityCode)) {
      setSelectedCommodities([...selectedCommodities, commodityCode]);
    }
  };

  const handleDeleteCommodity = (commodity) => {
    setSelectedCommodities(selectedCommodities.filter(item => item !== commodity));
  };

  const handleCompareButtonClick = () => {
    setShowDropdown(true);
  };

  const handleCompareDataClick = async () => {
    setLoading(true);
    try {
      const symbols = selectedCommodities.join(',');
      const response = await axios.get('/get_commodity_data/', { params: { symbols } });

  
      // Function to recursively replace NaN with null
      const replaceNaN = (obj) => {
        if (Array.isArray(obj)) {
          return obj.map(replaceNaN);
        } else if (obj !== null && typeof obj === 'object') {
          const newObj = {};
          for (let key in obj) {
            newObj[key] = replaceNaN(obj[key]);
          }
          return newObj;
        } else if (typeof obj === 'number' && isNaN(obj)) {
  
          return null;
        } else {
          return obj;
        }
      };  

  
      const safeResponseData = replaceNaN(response.data);
  
      // Now you can use safeResponseData without worrying about NaN values
      const parsedData = safeResponseData.data;
  
      const newFinancialData = parsedData.reduce((acc, entry) => {
        Object.keys(entry).forEach(key => {
          if (key !== 'date') {
            if (!acc[key]) acc[key] = [];
            acc[key].push({ date: entry.date, value: parseFloat(entry[key]) });
          }
        });
        return acc;
      }, {});
  
      setFinancialData(newFinancialData);
    } catch (error) {
      console.error(`Error fetching data: ${error}`);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const filterDataByDate = (data, startDate, endDate) => {
      return data.filter(entry => {
        const date = new Date(entry.date);
        return (!startDate || date >= new Date(startDate)) && (!endDate || date <= new Date(endDate));
      });
    };
  
    let yAxisIDs = []; // To keep track of yAxis IDs
    const datasets = selectedCommodities.map((code, index) => {
      const commodityName = commodityOptions.find(option => option.code === code)?.name;
      const dataPoints = financialData[commodityName]
        ? filterDataByDate(financialData[commodityName], startDate, endDate).map(entry => entry.value)
        : [];
  
      const yAxisID = `y-axis-${index}`;
      yAxisIDs.push(yAxisID); // Assign a unique yAxis ID for each dataset
  
      return {
        label: commodityName,
        data: dataPoints,
        fill: false,
        borderColor: getRandomColor(),
        pointRadius: 0,
        yAxisID: yAxisID // Assign the yAxis ID to the dataset
      };
    }).filter(dataset => dataset.data.length > 0);
  
    if (datasets.length > 0) {
      const dates = financialData[commodityOptions.find(option => option.code === selectedCommodities[0])?.name]
        ? filterDataByDate(financialData[commodityOptions.find(option => option.code === selectedCommodities[0])?.name], startDate, endDate).map(entry => entry.date)
        : [];
      setChartData({
        labels: dates,
        datasets
      });
    }
  }, [financialData, startDate, endDate, selectedCommodities]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    return `#${Array.from({ length: 6 }).map(() => letters[Math.floor(Math.random() * 16)]).join('')}`;
  };

  const renderChartOrNote = () => {
    if (chartData.datasets && chartData.datasets.length > 0) {
      return (
        <div className={styles.chartContainer}>
          <div className={styles.scrollableChart}>
            <div className={styles.chartContent}>
              <Line
                data={chartData}
                options={{
                  responsive: true,
                  scales: {
                    x: {
                      ticks: { maxRotation: 0, autoSkipPadding: 20 }
                    },
                    ...chartData.datasets.reduce((acc, dataset, index) => {
                      acc[`y-axis-${index}`] = {
                        type: 'linear',
                        display: true,
                        position: index % 2 === 0 ? 'left' : 'right', // Alternate y-axes positions
                        grid: {
                          drawOnChartArea: index === 0, // Only draw grid lines for the first y-axis
                        },
                        title: {
                          display: true,
                          text: dataset.label
                        }
                      };
                      return acc;
                    }, {})
                  },
                  plugins: {
                    tooltip: {
                      bodyFont: {
                        size: window.innerWidth < 768 ? 12 : 14 // Adjust font size for mobile
                      },
                      titleFont: {
                        size: window.innerWidth < 768 ? 12 : 14 // Adjust title font size for mobile
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return <pre>{JSON.stringify(chartData, null, 2)}</pre>;
    }
  };

  return (
    <div className={styles.container}>
      {!showDropdown ? (
        <button onClick={handleCompareButtonClick} className={styles.button}>Compare Data</button>
      ) : (
        <div>
          <div className={styles.chartControls}>
            <CustomDropdown 
              options={commodityOptions.map(option => ({ label: option.name, value: option.code }))} 
              onSelect={handleCommodityChange} 
              className={styles.select} 
              label="Select data:"
            />
          </div>
          <div className={styles.selectedCommodities}>
            {selectedCommodities.map(commodity => {
              const selectedCommodity = commodityOptions.find(option => option.code === commodity);
              return (
                <div key={commodity} className={styles.commodityTag}>
                  <span>{selectedCommodity.name}</span>
                  <button onClick={() => handleDeleteCommodity(commodity)} className={styles.deleteButton}>x</button>
                </div>
              );
            })}
          </div>

          <button onClick={handleCompareDataClick} className={styles.button}>Compare</button>

          <div className={styles.dateInputs}>
            <div>
              <label>From:</label>
              <input type="date" onChange={event => setStartDate(event.target.value)} value={startDate} className={styles.dateInput} />
            </div>
            <div>
              <label>To:</label>
              <input type="date" onChange={event => setEndDate(event.target.value)} value={endDate} className={styles.dateInput} />
            </div>
          </div>
          
        </div>
      )}

      {loading ? <p>Loading...</p> : (Object.keys(chartData).length > 0 ? renderChartOrNote() : null)}
    </div>
  );
}

export default CompareData;