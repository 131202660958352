import React, { useState } from 'react';
import SummaryDisplayNews from './Companies/SummaryDisplayNews';
import { newsSummary } from '../../../services/newsSummary';
import styles from './MicroProbeTopicGather.module.css'; // Import the new CSS module

const TopicGather = () => {
    const [keyword, setKeyword] = useState('');
    const [summaryData, setSummaryData] = useState(null);
    const [isLoadingSummary, setIsLoadingSummary] = useState(false); 

    const fetchSummary = async () => {
        setIsLoadingSummary(true); 
        setSummaryData(null); 
        try {
            const summary = await newsSummary(keyword); 
            setSummaryData(summary); 
        } catch (error) {
            console.error('Failed to fetch summary:', error);
        }
        setIsLoadingSummary(false); 
    }

    const handleInputChange = (event) => {
        setKeyword(event.target.value);
    }

    const handleSummaryClick = () => {
        fetchSummary();
    }

    return (
        <div className={styles.topicGather}>
            <div className={styles.inputContainer}>
                <input 
                    type="text" 
                    value={keyword} 
                    onChange={handleInputChange} 
                    placeholder="Enter keyword" 
                    className={styles.inputField}
                />
                <button onClick={handleSummaryClick} className={styles.searchButton}>Summary</button>
            </div>
            {isLoadingSummary && <div>Loading ...</div>}
            {summaryData && <SummaryDisplayNews summary={summaryData.response} />}
        </div>
    );
}

export default TopicGather;