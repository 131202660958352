import React from 'react';

const SummaryDisplayEarnings = ({ summary }) => {
  const preprocessSection = (sectionString) => {
    sectionString = sectionString.replace(/\\n/g, '\n');
    sectionString = sectionString.replace(/[[\]"]/g, '').trim(); // Removed unnecessary escape character
    sectionString = sectionString.replace(/\*/g, '');
  
    const titles = [
        '1. Financial Performance:',
        '2. Strategic Developments:',
        '3. Industry and Competitive Landscape:',
        '4. Risks and Challenges:',
        '5. Future Outlook and Growth Opportunities:',
        '6. Investment Considerations:'
    ];
  
    const firstTitleIndex = sectionString.indexOf(titles[0]);
    if (firstTitleIndex > 0) {
      sectionString = sectionString.substring(firstTitleIndex);
    }
  
    const titleRegex = new RegExp(`(\\d*\\.?\\s?(${titles.join('|')})\\s?)`, 'g');
  
    let sections = sectionString.split(titleRegex).filter(section => section.trim() !== '');
  
    let processedLines = [];
    for (let i = 0; i < sections.length; i++) {
      if (titles.some(title => sections[i].includes(title))) {
        processedLines.push({ type: 'title', content: sections[i].replace(/\*+/g, '').trim() });
        i++;
      } else {
        let lines = sections[i].split('\n').filter(line => line.trim() !== '');
        lines.forEach(line => {
          if (line.trim().startsWith('- ')) {
            processedLines.push({ type: 'item', content: line.trim().substring(2) });
          } else if (line.trim() !== '') {
            processedLines.push({ type: 'text', content: line.trim() });
          }
        });
      }
    }
  
    return processedLines.map(line => {
      if (line.type === 'text' || line.type === 'item') {
        line.content = line.content.split('\n').join('<br />');
      }
      return line; // Ensure a value is returned
    });
  };

  return (
    <div style={{ padding: '0px', background: '#f5f5f5', borderRadius: '8px', margin: '10px 0' }}>
      {summary.map((section, index) => {
        const processedLines = preprocessSection(section);
        return (
          <div key={index} style={{
              background: '#eef2f5',
              padding: '10px',
              margin: '10px 0',
              borderRadius: '4px',
              borderLeft: '5px solid #007bff'
            }}>
            
            {processedLines.map((line, lineIndex) => {
              if (line.type === 'title') {
                return <p key={lineIndex} style={{fontWeight: 'bold'}}>{line.content}</p>
              } else if (line.type === 'item') {
                return (
                  <ul key={`ul-${lineIndex}`} style={{paddingLeft: '15px'}}>
                    <li key={lineIndex} dangerouslySetInnerHTML={{ __html: line.content }}></li>
                  </ul>
                );
              } else if (line.type === 'text') {
                return <p key={lineIndex} dangerouslySetInnerHTML={{ __html: line.content }}></p>
              }
              return null; // Ensure a value is returned
            })}
          </div>
        );
      })}
    </div>
  );
};

export default SummaryDisplayEarnings;