import React, { useState, useRef } from 'react';
import axiosInstance from '../../../../axiosConfig';
import axios from 'axios';

const ChatUrl = () => {
  const [customerRequest, setCustomerRequest] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [displayChatHistory, setDisplayChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showChat, setShowChat] = useState(false); 
  const source = useRef(axios.CancelToken.source());

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      source.current.cancel('Operation canceled by the user.');
      source.current = axios.CancelToken.source();

      const response = await axiosInstance.post('/chat_find_youtube_url/', {
        customer_request: customerRequest,
        chat_history: chatHistory,
      }, {
        cancelToken: source.current.token,
      });
  
      setChatHistory([
        ...chatHistory,
        { role: 'user', content: customerRequest },
        { role: 'assistant', content: response.data }, 
      ]);
      setDisplayChatHistory([
        ...displayChatHistory,
        { role: 'user', content: customerRequest },
        //{ role: 'assistant', content: response.data.replace(/\n/g, '<br/>') }, // HTML formatted for display

        { role: 'assistant', content: typeof response.data === 'string' ? response.data.replace(/\n/g, '<br/>') : JSON.stringify(response.data, null, 2).replace(/\n/g, '<br/>') },
      ]);
      setCustomerRequest('');
      setResponseMessage('');
    } catch (error) {
      console.error('There was an error!', error);
      setResponseMessage(error.response ? error.response.data.error : "An error occurred");
    }
    setLoading(false);
  };


  const handleChatButtonClick = () => {
    setShowChat(!showChat); 
    if (showChat) { 
      setChatHistory([]); 
      setDisplayChatHistory([]); 
    }
  };

const formatMessage = (message) => {
  // Remove leading [ and trailing ] if they exist
  message = message.replace(/^\[/, 'Here are some videos I found. Please let me know if you have any follow up questions:').replace(/\]$/, '');


  // Remove brackets that separate each section and the comma between the brackets
  message = message.replace(/\},\s*\{/g, ', ').replace(/{/g, '').replace(/}/g, '');
 
  // Bold the specified words and remove their quotes
  const boldWords = ["Title", "Description", "Channel", "Duration", "Publish_date", "Url"];
  boldWords.forEach(word => {
      const regex = new RegExp(`"${word}":`, 'g');
      message = message.replace(regex, `<b>${word}</b>:`);
  });

  // Split the message by quotation marks, remove commas from the even-indexed segments, and rejoin
  const segments = message.split(/(".*?")/);
  message = segments.map((segment, index) => index % 2 === 0 ? segment.replace(/,/g, '') : segment).join('');

  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
  return message.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
};

  return (
    <div>
      <button onClick={handleChatButtonClick} style={{ marginBottom: '20px' , marginTop: '20px'}}>Find Videos</button> {/* New Chat button */}
      {showChat && ( 
        <>
          <div style={{ overflowY: 'auto', maxHeight: '600px', flexGrow: 1, padding: '2px', background: '#f5f5f5', borderRadius: '8px', margin: '10px 0' }}>
          
            {displayChatHistory.map((message, index) => (
              <div key={index}>
                {/*<strong>{message.role === 'user' ? 'You' : 'Assistant'}:</strong>  */}
                <strong>{message.role === 'user' ? 'You: ' : 'Assistant: '}</strong>
                {message.role === 'user' ? message.content : <pre style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{ __html: formatMessage(message.content) }} />}
              </div>
            ))}

            <form onSubmit={handleSubmit}>
              <label>
                <textarea 
                  value={customerRequest} 
                  onChange={e => { setCustomerRequest(e.target.value); setResponseMessage(''); }}  
                  placeholder="Search urls ..."
                  rows="4" 
                  style={{ width: '80%' }} 
                />
              </label>
              <input type="submit" value="Go" />
            </form>

            {loading && <p>Loading...</p>}
          </div>
          <pre>{responseMessage}</pre>
        </>
      )}
    </div>
  );
};

export default ChatUrl;