import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { fetchSentimentData } from '../../../../services/getSentimentDataService';
import { Chart, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import styles from './SentimentTrend.module.css';  // Import the CSS module


Chart.register(TimeScale);


const SentimentTrend = ({ companyData }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let response = await fetchSentimentData(companyData.symbol);
      if (typeof response === 'string') {
        response = response.replace(/NaN/g, 'null');
        response = JSON.parse(response);
      }
      if (!response || typeof response !== 'object' || !response.filtered_df) {
        console.error('Unexpected data structure received from fetchSentimentData', response);
        setIsLoading(false);
        return;
      }
      setData(response);
    } catch (error) {
      console.error('Error fetching sentiment data:', error);
    } finally {
      setIsLoading(false);
    }
  };


  if (!data || typeof data.filtered_df !== 'object') {
    return (
      <div>
        <h3>Sentiment</h3>
        <button onClick={fetchData}>Trend</button>
        {isLoading && <p>Loading...</p>}
      </div>
    );
  }

  const datetimeEntries = Object.entries(data.filtered_df.datetime || {});
  const sentimentScores = Object.entries(data.filtered_df.sentiment || {}).map(([_, value]) => value);

  const formattedData = datetimeEntries.map(([_, value], index) => ({
    x: new Date(value),
    y: sentimentScores[index],
  }));

  const colors = sentimentScores.map(score => {
    if (score >= 0 && score < 0.15) return 'rgba(152, 251, 152, 0.4)';
    if (score >= 0.15 && score < 0.3) return 'rgb(60, 179, 113)';
    if (score >= 0.3) return 'rgb(0, 105, 0)';
    if (score < 0 && score > -0.15) return 'rgb(255, 182, 193)';
    if (score <= -0.15 && score > -0.3) return 'rgb(255, 105, 180)';
    if (score <= -0.3) return 'rgb(220, 20, 60)';
    return 'rgba(0,0,0,0.1)'; // Default for unexpected values
  });

  // Calculate mean sentiment scores by day
  const meanScoresByDay = {};
  formattedData.forEach(({ x, y }) => {
    const dateStr = x.toISOString().split('T')[0];
    if (!meanScoresByDay[dateStr]) {
      meanScoresByDay[dateStr] = [];
    }
    meanScoresByDay[dateStr].push(y);
  });


const sentimentRollingScore = Object.entries(data.filtered_df.sentiment_score || {}).map(([_, value]) => value);



  const data1 = {
    datasets: [
      {
        label: 'Individual Sentiment Scores',
        data: formattedData,
        backgroundColor: colors,
        borderColor:'rgba(75, 192, 192, 0.2)',
        borderWidth: 1,
        pointRadius: 3,
        pointHoverRadius: 5,
        showLine: true, // Ensures the line is drawn
      },
      
      {
        //label: 'Daily Mean Sentiment',
        //data: meanScores,
        label: 'Rolling Mean Sentiment (30 points)',
        data: sentimentRollingScore.map((value, index) => ({
          x: new Date(datetimeEntries[index][1]),
          y: value,
        })),
        borderColor: 'rgba(153, 102, 255, 1)', // Purple color for the median line
        backgroundColor: 'rgba(153, 102, 255, 0.8)',
        borderWidth: 3,
        pointRadius: 0,
        type: 'line',
        fill: false,
        order: -1,
      }, 
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,  // Allows the chart to adjust its size
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM d',
          },
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 0.3,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: 'nearest',
        intersect: true,
      },
      legend: {
        display: true,
      },
    },
  };
  
  

  return (
    <div>
      <h3>Sentiment</h3>
      <button onClick={fetchData}>Trend</button>
      {isLoading && <p>Loading...</p>}
      {!data || typeof data.filtered_df !== 'object' ? (
        <div>Loading...</div>
      ) : (
        <div className={`${styles['chart-container']}`} style={{ overflowX: 'auto' }}>
          <div className={styles['chart-wrapper']}>
            <Line data={data1} options={options} />
          </div>
          <div className={styles.legend}>
            {[
              { color: 'rgb(0, 105, 0)', label: 'Bullish' },
              { color: 'rgb(60, 179, 113)', label: 'Mild Bullish' },
              { color: 'rgba(152, 251, 152, 0.4)', label: 'Neutral to Mild Bullish' },
              { color: 'rgb(255, 182, 193)', label: 'Neutral to Mild Bearish' },
              { color: 'rgb(255, 105, 180)', label: 'Mild Bearish' },
              { color: 'rgb(220, 20, 60)', label: 'Bearish' },
            ].map((item, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '10px 0', fontSize: '12px', color: '#666', fontFamily: 'Arial' }}>
                <div style={{ backgroundColor: item.color, width: '6px', height: '6px', borderRadius: '50%', marginRight: '5px', flexShrink: 0, border: '1px solid rgba(75, 192, 192, 0.5)' }}></div>
                <span>{item.label}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SentimentTrend;
