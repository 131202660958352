import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import axios from '../../../../axiosConfig';
import EarningsPress from './EarningsPress';
import styles from '../MicroProbe.module.css';



const CompaniesEarnings = () => {
  const { companyName } = useParams();
  const location = useLocation(); // Use useLocation hook to access the location object
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        let data;

        if (location.state && location.state.ticker) {
          const { ticker } = location.state;
          data = { name: companyName, symbol: ticker };
        } else {
          const response = await axios.get(`/api/company/name/${companyName}`);
          data = response.data;

        }

        setCompanyData(data);
     
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };
  
    fetchCompanyData();
  }, [companyName, location.state]);

return (
  <div className={styles.container}>
    <h1 className={styles.title}>Company Analysis: {companyName.includes('_ticker_') ? companyName.split('_ticker_')[0] : companyName}</h1>

    {companyData && (
      <>

        <div className={`${styles.section} ${styles.sectionQuarter}`}>
     
          <div className={styles.sectionContent}>
            <EarningsPress companyData={companyData} />
          </div>
          <div className={styles.dividernone}></div>
          
        </div>


      </>
    )}

    <div style={{ marginTop: '20px' }}>
        <Link to="/text-videos-suite">Back</Link>
      </div> 

  </div>
);
};

export default CompaniesEarnings;