import axios from 'axios';
import config from './config';  // Your baseURL and other config settings

// Helper function to check token expiration
function isTokenExpired(token) {
    if (!token) return true;
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);  // Current time in seconds
    const buffer = 30;  // 30-second buffer to handle expiration edge cases
    return payload.exp < currentTime + buffer;
}

// Create Axios instance
const axiosInstance = axios.create({
    baseURL: config.baseURL,  // Use the baseURL from your config.js
    timeout: 5 * 60 * 1000,  // Optional timeout setting
});

// Handle token refresh queue (prevents multiple refresh requests)
let isRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

function onRefreshed(token) {
    refreshSubscribers.forEach((cb) => cb(token));
    refreshSubscribers = [];
}

// Add request interceptor to check for token expiration and refresh if necessary
axiosInstance.interceptors.request.use(
    async (requestConfig) => {
        let token = localStorage.getItem('access_token');
        let refreshToken = localStorage.getItem('refresh_token');

        // Check if access token is expired
        if (token && isTokenExpired(token)) {
            // Check if refresh process is already in progress
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    // Try refreshing the token
                    const response = await axios.post(`${config.baseURL}/api/token/refresh/`, { refresh: refreshToken });
                    token = response.data.access;
                    localStorage.setItem('access_token', token);
                    isRefreshing = false;

                    // Notify all subscribers that token has been refreshed
                    onRefreshed(token);
                } catch (err) {
                    console.error('Failed to refresh token', err);
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    isRefreshing = false;

                    // Reject the request and redirect to login if token refresh fails
                    window.location.href = '/login';
                    return Promise.reject(err);
                }
            }

            // Wait for the new token and retry the original request
            const retryOriginalRequest = new Promise((resolve) => {
                subscribeTokenRefresh((newToken) => {
                    requestConfig.headers['Authorization'] = `Bearer ${newToken}`;
                    resolve(requestConfig);
                });
            });

            return retryOriginalRequest;
        }

        // If the token is still valid, attach it to the request
        if (token) {
            requestConfig.headers['Authorization'] = `Bearer ${token}`;
        }

        return requestConfig;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor to handle 401 errors (unauthorized) and retry with new token
axiosInstance.interceptors.response.use(
    (response) => response,  // Handle successful responses
    async (error) => {
        const originalRequest = error.config;

        // Handle 401 Unauthorized errors
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refresh_token');

            if (refreshToken) {
                try {
                    // Try refreshing the token
                    const response = await axios.post(`${config.baseURL}/api/token/refresh/`, { refresh: refreshToken });
                    const { access } = response.data;
                    localStorage.setItem('access_token', access);

                    // Update the authorization header with the new access token
                    originalRequest.headers['Authorization'] = `Bearer ${access}`;

                    // Retry the original request with the new token
                    return axiosInstance(originalRequest);
                } catch (err) {
                    console.error('Refresh token is invalid or expired', err);
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    window.location.href = '/login';  // Redirect to login if refresh fails
                    return Promise.reject(err);  // Reject the original request
                }
            } else {
                window.location.href = '/login';  // Redirect to login if no refresh token
                return Promise.reject(error);
            }
        }

        // Handle other errors (e.g., network issues)
        if (!error.response) {
            console.error('No response received:', error.message);
        } else {
            console.error('Error response:', error.response);
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
