import React, { useEffect, useState, useRef } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import * as d3 from 'd3-force';
import { fetchSentimentData } from '../../../../services/getSentimentDataService';

function sentimentToColor(sentiment) {
  if (sentiment >= 0.3) return 'rgb(0, 105, 0)';
  else if (sentiment >= 0.15) return 'rgb(60, 179, 113)';
  else if (sentiment > 0) return 'rgba(152, 251, 152, 0.3)';
  else if (sentiment <= -0.3) return 'rgb(220, 20, 60)';
  else if (sentiment <= -0.15) return 'rgb(255, 105, 180)';
  else if (sentiment <= 0) return 'rgb(255, 182, 193)';
  return '#9E9E9E'; // Grey for neutral
}

function sentimentToLabel(sentiment) {
  if (sentiment >= 0.3) return 'Bullish';
  else if (sentiment >= 0.15) return 'Mild Bullish';
  else if (sentiment > 0) return 'Neutral to Mild Bullish';
  else if (sentiment < 0 && sentiment > -0.15) return 'Neutral to Mild Bearish';
  else if (sentiment <= -0.15 && sentiment > -0.3) return 'Mild Bearish';
  else if (sentiment <= -0.3) return 'Bearish';
  return 'Neutral';
}

const NetworkGraph = ({ companyData }) => {
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const containerRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [reloadKey, setReloadKey] = useState(0); 
  const [isMobile, setIsMobile] = useState(false);
  const [hoveredNode, setHoveredNode] = useState(null); // For displaying the tooltip

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
    }

    // Detect screen size for mobile view
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 600); // Adjust threshold as needed
    };

    window.addEventListener('resize', checkMobile);
    checkMobile();

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (!showGraph) return;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        let response = await fetchSentimentData(companyData.symbol);
        if (typeof response === 'string') {
          response = JSON.parse(response.replace(/NaN/g, 'null'));
        }
        const { total_relevance, mean_sentiment, company_names } = response.top_25;
        const nodes = Object.keys(total_relevance).map(key => {
          const relevance = total_relevance[key];
          const sentiment = mean_sentiment[key];
          const color = sentimentToColor(sentiment);
          const size = Math.log10(relevance + 1) * (isMobile ? 10 : 18) + 5; // Smaller size for mobile
          return { id: key, name: `${company_names[key]} (Sentiment: ${sentiment.toFixed(2)}, ${sentimentToLabel(sentiment)})`, color, size, val: relevance };
        });
    
        // Setup a d3-force layout to control the nodes based on their relevance
        const simulation = d3.forceSimulation(nodes)
          .force('charge', d3.forceManyBody().strength(-120))
          .force('center', d3.forceCenter(width / 12, 3 * height / 3))
          .force('collision', d3.forceCollide().radius(node => node.size + 10))
          .force('link', d3.forceLink().id(d => d.id).distance(d => 100).strength(0.5));

        simulation.on('tick', () => {
          setGraphData({ nodes: [...nodes], links: [] });
        });
      } catch (error) {
        console.error('Error fetching or processing sentiment data:', error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [companyData.symbol, width, height, showGraph, reloadKey, isMobile]);

  useEffect(() => {
    if (showGraph && containerRef.current) {
      setHeight(containerRef.current.offsetHeight);
    }
  }, [showGraph]);

  const handleClick = () => {
    if (containerRef.current) {
      setHeight(containerRef.current.offsetHeight);
    }
    setShowGraph(true);
    setReloadKey(prevKey => prevKey + 1);
  };

  return (
    <div ref={containerRef} className="sectionContent" style={{ width: '800px', position: 'relative', overflow: 'hidden', height: showGraph ? '100vh' : 'auto' }}>
      <button onClick={handleClick}>Comparison</button> 
      {isLoading && <p>Loading...</p>}
      {showGraph && !isLoading && (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>

          <ForceGraph2D
          graphData={graphData}
          nodeLabel={isMobile ? null : 'name'} // Disable the old pop-up on mobile
          nodeCanvasObject={(node, ctx, globalScale) => {
            const label = node.id;
            const fontSize = Math.max(8, (node.size / (isMobile ? 2 : 1)) / globalScale); // Adjust font size for mobile
            ctx.font = `${fontSize}px Sans-Serif`;
            ctx.textAlign = 'left';
            ctx.fillStyle = node.color;
            ctx.beginPath();
            ctx.arc(node.x, node.y, node.size / Math.pow(globalScale, 1), 0, 2 * Math.PI, false);
            ctx.fill();
            ctx.fillStyle = 'black';
            ctx.fillText(label, node.x, node.y + fontSize / 2);
          }}
          nodePointerAreaPaint={(node, color, ctx) => {
            ctx.fillStyle = color;
            ctx.beginPath();
            ctx.arc(node.x, node.y, node.size * 2, 0, 2 * Math.PI, false);
            ctx.fill();
          }}
          onNodeHover={node => setHoveredNode(node)} // Capture hovered node
          enableZoomPanInteraction={true}
          width={width}
          height={height}
        />

        {hoveredNode && isMobile && (
          <div
            style={{
              position: 'absolute',
              top: '30px', // Display the tooltip at the top of the graph area
              left: '16%',
              transform: 'translateX(-50%)',
              backgroundColor: 'white',
              padding: '5px',
              borderRadius: '4px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              pointerEvents: 'none', // Ensures tooltip does not interfere with interactions
              zIndex: 10,
              textAlign: 'center',
              lineHeight: '1.4'
            }}
          >
   
            {hoveredNode.name.split('(Sentiment:')[0]}
            <br />
            {'Sentiment: ' + hoveredNode.name.split('(Sentiment:')[1].split(')')[0].split(',')[0]} {/* Changed line */}
            <br />
            {hoveredNode.name.split('(Sentiment:')[1].split(')')[0].split(',')[1].trim()} {/* Changed line */}

          </div>
        )}
      </div>
      )}
    </div>
  );
};

export default NetworkGraph;
