import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axios from '../../../../axiosConfig';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import PriceDataBox from './PriceDataBox';
import styles from '../../MicroProbe/Companies/FinancialData.module.css'; 
import CustomDropdown from './CustomDropdown';


ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

function PriceData({ inputData }) {
  const [financialData, setFinancialData] = useState({});
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({});
  const [currentView, setCurrentView] = useState('');
  const [metricSelection, setMetricSelection] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [to_symbol, setToSymbol] = useState("USD");
  const [from_symbol, setFromSymbol] = useState("USD");
  
  const metricOptions = {
    TIME_SERIES_DAILY_ADJUSTED: [
      '1. open',
      '2. high',
      '3. low',
      '4. close',
    ]
  };

  const handleFromForeignExchangeSelect = async (foreignExchangeName) => {
    try {
      const response = await axios.get(`/api/foreignexchange/name/${foreignExchangeName}`);
      setFromSymbol(response.data.code);
    } catch (error) {
      console.error('Error fetching currency data:', error);
    }
  };

  const handleForeignExchangeSelect = async (foreignExchangeName) => {
    try {
      const response = await axios.get(`/api/foreignexchange/name/${foreignExchangeName}`);
      setToSymbol(response.data.code);
    } catch (error) {
      console.error('Error fetching currency data:', error);
    }
  };

  const handleButtonClick = async (functionType) => {
    setLoading(true);
    setCurrentView(functionType);
    setMetricSelection('');
    try {
      let response = await axios.get(`get_fx_data/${from_symbol}/${to_symbol}/`);
      setFinancialData(response.data);
    } catch (error) {
      console.error(`Error fetching data: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let reports = Object.entries(financialData['Time Series FX (Daily)'] || {}).map(([date, data]) => ({...data, fiscalDateEnding: date}));
  
    if (metricSelection && reports.length > 0) {
      reports = reports.filter(report => {
        const reportDate = new Date(report.fiscalDateEnding);
        return (!startDate || reportDate >= new Date(startDate)) && (!endDate || reportDate <= new Date(endDate));
      });
  
      const labels = reports.map(report => report.fiscalDateEnding).reverse();
      const dataPoints = reports.map(report => parseFloat(report[metricSelection]) || 0).reverse();
  
      const datasets = {
        label: metricSelection.replace(/^\d+\.\s/, ''),
        data: dataPoints,
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgb(75, 192, 192)',
        pointRadius: 0
      };
    
      setChartData({
        labels,
        datasets: [datasets]
      });
    }
  }, [metricSelection, financialData, startDate, endDate]);

  const renderChartOrNote = () => {
    if (typeof chartData.datasets[0].data[0] === 'string') {
      return <pre>{JSON.stringify(chartData, null, 2)}</pre>; 
    } else {
      return (
        <div className={styles.chartContainer}>
          <div className={styles.scrollableChart}>
            <div className={styles.chartContent}>
              <Line 
                data={chartData} 
                options={{ 
                  responsive: true,
                  scales: {
                    x: {
                      ticks: {
                        maxRotation: 0, 
                        autoSkipPadding: 20 
                      }
                    }
                  },
                  plugins: {
                    tooltip: {
                      bodyFont: {
                        size: window.innerWidth < 768 ? 12 : 14 // Adjust font size for mobile
                      },
                      titleFont: {
                        size: window.innerWidth < 768 ? 12 : 14 // Adjust title font size for mobile
                      }
                    }
                  }
                }} 
              />
            </div>
          </div>
        </div>
      );
    }
  };

 
  const handleMetricChange = (selectedValue) => setMetricSelection(selectedValue);

  return (
    <div className={styles.container}>
      <h4>Historical</h4>  

      <div className={styles.selectionContainer}>
        <label className={styles.label}>From: </label>
        <PriceDataBox onForeignExchangeSelect={handleFromForeignExchangeSelect} />
      </div>

      <div className={styles.selectionContainer}>
        <label className={styles.label}>To: </label>
        <PriceDataBox onForeignExchangeSelect={handleForeignExchangeSelect} />
      </div>

      <button 
        onClick={() => handleButtonClick('TIME_SERIES_DAILY_ADJUSTED')}
        className={styles.button}
      >
        Get FX Data
      </button> 

      {currentView && (
        <div className={styles.chartControls}>


          <CustomDropdown 
            onSelect={handleMetricChange} 
            placeholder="Select a Metric" 
            options={metricOptions[currentView] ? metricOptions[currentView].map(option => ({
              value: option,
              label: option.replace(/^\d+\.\s/, '')
            })) : []} 
            resetTrigger={currentView}
          />



          <div className={styles.dateInputs}>
            <div>
              <label>From:</label>
              <input type="date" onChange={event => setStartDate(event.target.value)} value={startDate} />
            </div>
            <div>
              <label>To:</label>
              <input type="date" onChange={event => setEndDate(event.target.value)} value={endDate} />
            </div>
          </div>
        </div>
      )}
      {loading ? <p>Loading...</p> : (metricSelection && Object.keys(chartData).length > 0 ? renderChartOrNote() : null)}
    </div>
  );
}

export default PriceData;
