import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import AutocompleteInputCrypto from './AutocompleteInputCrypto'; 
import styles from '../MicroProbe/MicroProbe.module.css'; 


const CryptoFrontPageSignals = () => {
  let navigate = useNavigate(); 

  // Function to handle selection of a foreign exchange
  const handleCryptoSelect = (cryptoName) => {
    navigate(`/crypto-signals/${cryptoName}`); 
  };

  return (
    <div>
      <h5>Cryptocurrency:</h5>

      <div className={styles.searchSection}>
        <AutocompleteInputCrypto onCryptoSelect={handleCryptoSelect} />
      </div>
    



    </div>
  );
};

export default CryptoFrontPageSignals;