import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../../../../axiosConfig';
import LatestDevelopments from './LatestDevelopments';
import SentimentTrend from './SentimentTrend';
import Networks from './Networks';
import styles from '../../MicroProbe//MicroProbe.module.css';



const CurrencyPairsNews = () => {
  const { foreignExchangeName } = useParams();
  const [inputData, setInputData] = useState(null);
  
  useEffect(() => {
    const fetchInputData = async () => {
      try {
        const response = await axios.get(`/api/foreignexchange/name/${foreignExchangeName}`);
        setInputData(response.data);

      } catch (error) {
        console.error('Error fetching currency data:', error);
      }
    };
  
    fetchInputData();
  }, [foreignExchangeName]);


return (
  <div className={styles.container}>
    <h1 className={styles.title}>FOREX: {foreignExchangeName }</h1>

    {inputData && (
      <>  
        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} >
            <LatestDevelopments  inputData={inputData} />
          </div>
        </div>

        <div className={`${styles.section} ${styles.sectionQuarter}`}>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <SentimentTrend  inputData={inputData} />
          </div>

          <div className={styles.dividernone}></div>
          <div className={styles.sectionContent} style={{ width: '100%', height: 'auto' }}>
            <Networks  inputData={inputData} />
          </div>   
          <div className={styles.dividernone}></div>
        </div>



      </>
    )}


    <div style={{ marginTop: '60px' }}>
        <Link to="/news-suite">Back</Link>
      </div> 

  </div>
);
};

export default CurrencyPairsNews;