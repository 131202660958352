import React, { useState, useEffect, useRef } from 'react';
import styles from './EarningsPress.module.css';

const CustomDropdown = ({ titles, handleSelectChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('Select Earnings Title');
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (title) => {
    setSelectedTitle(title.title); // Display the selected title in the dropdown
    handleSelectChange(title.id); // Pass the selected ID to the parent component
    setIsOpen(false); // Close the dropdown
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.customDropdown} ref={dropdownRef}>
      <div className={styles.selected} onClick={toggleDropdown}>
        {selectedTitle}
        <span className={styles.arrow}></span>
      </div>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {titles.map((title) => (
            <div
              key={title.id}
              className={styles.dropdownOption}
              onClick={() => handleOptionClick(title)}
            >
              {title.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;