import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import AutocompleteInputFx from './AutocompleteInputFx'; 
import styles from '../MicroProbe/MicroProbe.module.css'; 


const ForeignExchangeFrontPageNews = () => {
  let navigate = useNavigate(); 

  // Function to handle selection of a foreign exchange
  const handleForeignExchangeSelect = (foreignExchangeName) => {
    navigate(`/foreignexchange-news/${foreignExchangeName}`); 
  };

  return (

    <div>
      <h5>Foreign Exchange:</h5>

      <div className={styles.searchSection}>
        <AutocompleteInputFx onForeignExchangeSelect={handleForeignExchangeSelect} />
      </div>
    
    </div>
  );
};

export default ForeignExchangeFrontPageNews;