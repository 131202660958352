import React, { useState, useEffect } from 'react';
import SummaryDisplayStock from './SummaryDisplayStock';
import { newsSummaryStock } from '../../../../services/newsSummary';

const StockPerformanceOverview = ({ companyData }) => {
    const [keyword, setKeyword] = useState('');
    const [summaryData, setSummaryData] = useState(null);
    const [isLoadingSummary, setIsLoadingSummary] = useState(false); 

    useEffect(() => {
        setKeyword(`${companyData.name} stock`);
    }, [companyData]);

    const fetchSummary = async () => {
        setIsLoadingSummary(true); 
        setSummaryData(null); 
        try {
            const summary = await newsSummaryStock(keyword); 
            setSummaryData(summary); 
        } catch (error) {
            console.error('Failed to fetch summary:', error);
        }
        setIsLoadingSummary(false); 
    }

    const handleSearchClick = () => {
        fetchSummary();
    }

    const isLoading = isLoadingSummary;

    return (
        <div>
            <h3>Numbers</h3>
            <h4>Overview</h4>
       
            <div style={{ marginBottom: '20px' }}>
                <button onClick={handleSearchClick}>Stock Highlights</button>
                {isLoading && <div>Loading...</div>}
                {summaryData && <SummaryDisplayStock summary={summaryData.response} />} 
            </div>
        </div>
    );
}

export default StockPerformanceOverview;