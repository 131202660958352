import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from '../../../../axiosConfig';  // Custom Axios instance
import axios from 'axios';  // Original Axios library
import { fetchEarningsTitles } from '../../../../services/earningsApi';
import SummaryDisplayFinancials from './SummaryDisplayFinancials';
import NewsSummaryFinancials from './NewsSummaryFinancials';

const FinancialsOverview = ({ companyData }) => {
  const [titles, setTitles] = useState([]);
  const [selectedTitleId, setSelectedTitleId] = useState('');
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [summary, setSummary] = useState([]);
  const source = useRef(axios.CancelToken.source());  // Use original Axios for CancelToken

  useEffect(() => {
    const fetchTitles = async () => {
      try {
        const titlesData = await fetchEarningsTitles(companyData.symbol);
        setTitles(titlesData);
        if (titlesData.length > 0) {
          const mostRecentTitleId = titlesData[0].id;
          setSelectedTitleId(mostRecentTitleId);
        }
        setLoadingDetails(false);
      } catch (error) {
        console.error(error);
        setLoadingDetails(false);
      }
    };

    fetchTitles();
  }, [companyData.symbol]);

  const handleKeyHighlightsClick = async () => {
    if (selectedTitleId) {
      setLoadingSummary(true);
      setSummary(null);
      try {
        source.current.cancel('Operation canceled by the user.');
        source.current = axios.CancelToken.source();  // Reset the cancel token

        const response = await axiosInstance.get('/earnings_summary_financials/', {
          cancelToken: source.current.token,
          params: { transcript_id: selectedTitleId }
        });

        const parsedResponse = response.data.response;
        setSummary(parsedResponse);
      } catch (error) {
        if (axios.isCancel(error)) {

        } else {
          console.error(error);
        }
      }
      setLoadingSummary(false);
    }
  };

  return (
    <div>
      {loadingDetails ? (
        <p>Loading...</p>
      ) : (
        <>
          {titles.length === 0 && !loadingDetails && <NewsSummaryFinancials companyData={companyData} />}
          {titles.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <div style={{ flex: 1, overflowY: 'auto', maxHeight: '1200px' }}>
                <button onClick={handleKeyHighlightsClick}>Financials Highlights</button>
                {loadingSummary && <p>Loading...</p>}
                {summary && summary.length > 0 && <SummaryDisplayFinancials summary={summary} />}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FinancialsOverview;