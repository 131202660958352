import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axios from '../../../../axiosConfig';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import styles from '../../MicroProbe/Companies/FinancialData.module.css'; 


ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

function PriceData({ inputData }) {
  const [financialData, setFinancialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({});
  const [currentView, setCurrentView] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleButtonClick = async () => {
    setLoading(true);
    setCurrentView('TIME_SERIES_DAILY_ADJUSTED');
    try {
      const response = await axios.get('/get_commodity_data/', {
        params: {
          symbols: inputData.code
        }
      });
      let responseString = JSON.stringify(response.data);
      if (typeof responseString === 'string') {
        responseString = responseString.replace(/NaN/g, 'null');
        let responseData = JSON.parse(responseString);
        if (Array.isArray(responseData.data)) {
          const newData = responseData.data.map(item => ({
            date: item.date,
            value: parseFloat(item[inputData.name])
          }));
          setFinancialData(newData);
        } else {
          console.error('Unexpected data format:', responseData);
        }
      }
    } catch (error) {
      console.error(`Error fetching data: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (financialData.length > 0) {
      const reports = financialData.map(entry => {
        if (!entry.date || isNaN(Date.parse(entry.date))) {
          console.error('Invalid or missing date found:', entry.date);
          return null;
        }
        const date = new Date(entry.date);
        const value = entry.value === null ? NaN : entry.value;
        return {
          fiscalDateEnding: date.toISOString().split('T')[0],
          close: value
        };
      }).filter(report => report !== null);

      if (reports.length > 0) {
        const filteredReports = reports.filter(report => {
          const reportDate = new Date(report.fiscalDateEnding);
          return (!startDate || reportDate >= new Date(startDate)) && (!endDate || reportDate <= new Date(endDate));
        });

        filteredReports.sort((a, b) => new Date(a.fiscalDateEnding) - new Date(b.fiscalDateEnding));

        const labels = filteredReports.map(report => report.fiscalDateEnding);
        const dataPoints = filteredReports.map(report => parseFloat(report.close) || NaN);

        const datasets = {
          label: inputData.name,
          data: dataPoints,
          fill: false,
          backgroundColor: 'rgb(75, 192, 192)',
          borderColor: 'rgb(75, 192, 192)',
          pointRadius: 0
        };

        setChartData({
          labels,
          datasets: [datasets]
        });
      }
    }
  }, [financialData, startDate, endDate, inputData.name]); 

  const renderChartOrNote = () => {
    if (chartData.datasets && chartData.datasets.length > 0 && typeof chartData.datasets[0].data[0] === 'string') {
      return <pre>{JSON.stringify(chartData, null, 2)}</pre>;
    } else {
      return (
        <div className={styles.chartContainer}>
          <div className={styles.scrollableChart}>
            <div className={styles.chartContent}>
              <Line
                data={chartData}
                options={{
                  responsive: true,
                  scales: {
                    x: {
                      ticks: {
                        maxRotation: 0,
                        autoSkipPadding: 20
                      }
                    }
                  },
                  plugins: {
                    tooltip: {
                      bodyFont: {
                        size: window.innerWidth < 768 ? 12 : 14 // Adjust font size for mobile
                      },
                      titleFont: {
                        size: window.innerWidth < 768 ? 12 : 14 // Adjust title font size for mobile
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <button onClick={handleButtonClick} className={styles.button}>
        Get Historical Data
      </button>

      {currentView && (

        
   
        <div className={styles.dateInputs}>
          <div>
            <label className={styles.label}>From:</label>
            <input type="date" onChange={event => setStartDate(event.target.value)} value={startDate} className={styles.dateInput} />
          </div>
          <div>
            <label className={styles.label}>To:</label>
            <input type="date" onChange={event => setEndDate(event.target.value)} value={endDate} className={styles.dateInput} />
          </div>
        </div>




      )}
      {loading ? <p>Loading...</p> : (Object.keys(chartData).length > 0 ? renderChartOrNote() : null)}
    </div>
  );
}

export default PriceData;
