import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import FeaturesPage from './features/FeaturesPage';
import AgentsSuite from './features/AgentsSuite';
import NewsSuite from './features/NewsSuite';
import NumbersSuite from './features/NumbersSuite';
import TextsVideosSuite from './features/TextsVideosSuite';
import SmartSearchSuite from './features/SmartSearchSuite';
import SignalsSuite from './features/SignalsSuite';
import IdeaMakerSuite from './features/IdeaMakerSuite';
import AIMarketDecoder from './features/AIMarketDecoder/AIMarketDecoder';
import MicroProbe from './features/AIMarketDecoder/MicroProbe/MicroProbe';
import ContactPage from './features/ContactPage';
import HelpPage from './features/HelpPage';
import ForeignExchangeFrontPage from './features/AIMarketDecoder/ForeignExchange/ForeignExchangeFrontPage';
import Companies from './features/AIMarketDecoder/MicroProbe/Companies/Companies'; 
import CurrencyPairs from './features/AIMarketDecoder/ForeignExchange/CurrencyPairs/CurrencyPairs';
import CurrencyPairsNews from './features/AIMarketDecoder/ForeignExchange/CurrencyPairs/CurrencyPairsNews';
import EconomyFrontPage from './features/AIMarketDecoder/Economy/EconomyFrontPage';
import Countries from './features/AIMarketDecoder/Economy/Countries/Countries';
import CommodityFrontPage from './features/AIMarketDecoder/Commodity/CommodityFrontPage';
import IndividualCommodity from './features/AIMarketDecoder/Commodity/IndividualCommodity/IndividualCommodity';
import CrytoFrontPage from './features/AIMarketDecoder/Crypto/CryptoFrontPage';
import IndividualCrypto from './features/AIMarketDecoder/Crypto/IndividualCrypto/IndividualCrypto';
import BondFrontPage from './features/AIMarketDecoder/Bond/BondFrontPage';
import IndividualBond from './features/AIMarketDecoder/Bond/IndividualBond/IndividualBond';
import IndividualBondNews from './features/AIMarketDecoder/Bond/IndividualBond/IndividualBondNews';
import InvididualCryptoNews from './features/AIMarketDecoder/Crypto/IndividualCrypto/IndividualCryptoNews';
import InvididualCommodityNews from './features/AIMarketDecoder/Commodity/IndividualCommodity/IndividualCommodityNews';
import CompaniesNews from './features/AIMarketDecoder/MicroProbe/Companies/CompaniesNews';
import CountriesNews from './features/AIMarketDecoder/Economy/Countries/CountriesNews';
import IndividualBondNumbers from './features/AIMarketDecoder/Bond/IndividualBond/IndividualBondNumbers';
import IndividualCommodityNumbers from './features/AIMarketDecoder/Commodity/IndividualCommodity/IndividualCommodityNumbers';
import IndividualCryptoNumbers from './features/AIMarketDecoder/Crypto/IndividualCrypto/IndividualCryptoNumbers';
import CountriesNumbers from './features/AIMarketDecoder/Economy/Countries/CountriesNumbers';
import CompaniesNumbers from './features/AIMarketDecoder/MicroProbe/Companies/CompaniesNumbers';
import CurrencyPairsNumbers from './features/AIMarketDecoder/ForeignExchange/CurrencyPairs/CurrencyPairsNumbers';
import IndividualBondSignals from './features/AIMarketDecoder/Bond/IndividualBond/IndividualBondSignals';
import IndividualCommoditySignals from './features/AIMarketDecoder/Commodity/IndividualCommodity/IndividualCommoditySignals';
import IndividualCryptoSignals from './features/AIMarketDecoder/Crypto/IndividualCrypto/IndividualCryptoSignals';
import CountriesSignals from './features/AIMarketDecoder/Economy/Countries/CountriesSignals';
import CompaniesSignals from './features/AIMarketDecoder/MicroProbe/Companies/CompaniesSignals';
import CurrencyPairsSignals from './features/AIMarketDecoder/ForeignExchange/CurrencyPairs/CurrencyPairsSignals';
import CompaniesEarnings from './features/AIMarketDecoder/MicroProbe/Companies/CompaniesEarnings'; 
import CompaniesSEC from './features/AIMarketDecoder/MicroProbe/Companies/CompaniesSEC';
import VideoInsight from './features/AIMarketDecoder/MicroProbe/MicroProbeVideos';
import DocumentUpload from './features/AIMarketDecoder/MicroProbe/MicroProbeDocumentsCompare';
import TopicGather from './features/AIMarketDecoder/MicroProbe/MicroProbeTopicGather';
import InsightExplorer from './features/AIMarketDecoder/MicroProbe/Companies/InsightExplorer';
import PrivateRoute from './PrivateRoute'; 
import Login from './Login';
import Header from './Header';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faNewspaper,
  faChartBar,
  faFileAlt,
  faWaveSquare,
  faUserTie,
  faFileInvoice,
  faSearch,
  faThLarge    
} from '@fortawesome/free-solid-svg-icons';




const Sidebar = () => {
  const [isCollapsed, setCollapsed] = useState(true);

  const handleLinkClick = () => {
    setCollapsed(true);
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <button onClick={() => setCollapsed(!isCollapsed)}>
        {isCollapsed ? '>' : '<'}
      </button>
      <nav>
        <Link to="/news-suite" data-tooltip="News & Sentiment" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faNewspaper} className="icon" />
          {!isCollapsed && <span className="text">News & Sentiment</span>}
        </Link>
        <Link to="/numbers-suite" data-tooltip="Data Analytics" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faChartBar} className="icon" />
          {!isCollapsed && <span className="text">Data Analytics</span>}
        </Link>
        <Link to="/text-videos-suite" data-tooltip="Text and Video Insights" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faFileAlt} className="icon" />
          {!isCollapsed && <span className="text">Text and Video Insights</span>}
        </Link>
        <Link to="/signals-suite" data-tooltip="AI-Based Signals" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faWaveSquare} className="icon" />
          {!isCollapsed && <span className="text">AI-Based Signals</span>}
        </Link>
        <Link to="/agents" data-tooltip="Investment Agents" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faUserTie} className="icon" />
          {!isCollapsed && <span className="text">Investment Agents</span>}
        </Link>
        <Link to="/ideas-maker" data-tooltip="Instant Reporting Studio" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faFileInvoice} className="icon" />
          {!isCollapsed && <span className="text">Instant Report Studio</span>}
        </Link>
        <Link to="/smart-search-suite" data-tooltip="Smart Search Engine" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faSearch} className="icon" />
          {!isCollapsed && <span className="text">Smart Search Engine</span>}
        </Link>
        <Link to="/ai-market-dashboard" data-tooltip="AI Market Dashboard" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faThLarge} className="icon" />
          {!isCollapsed && <span className="text">AI Market Dashboard</span>}
        </Link>
      </nav>
    </div>
  );
};



const ProductGrid = () => (
  <div className="app-page">
    <div className="grid-container">
      <Link to="/news-suite" className="grid-item">
        <div className="grid-content">
          <FontAwesomeIcon icon={faNewspaper} className="grid-icon" />
          <h3>News & Sentiment</h3>
          <p>Filter market noise with AI insights</p>
        </div>
      </Link>
      <Link to="/numbers-suite" className="grid-item">
        <div className="grid-content">
          <FontAwesomeIcon icon={faChartBar} className="grid-icon" />
          <h3>Data Analytics</h3>
          <p>AI-powered analytics across assets</p>
        </div>
      </Link>
      <Link to="/text-videos-suite" className="grid-item">
        <div className="grid-content">
          <FontAwesomeIcon icon={faFileAlt} className="grid-icon" />
          <h3>Text and Video Insights</h3>
          <p>Extract critical insights instantly</p>
        </div>
      </Link>
      <Link to="/signals-suite" className="grid-item">
        <div className="grid-content">
          <FontAwesomeIcon icon={faWaveSquare} className="grid-icon" />
          <h3>AI-Based Signals</h3>
          <p>Real-time AI signals with reasoning</p>
        </div>
      </Link>
      <Link to="/agents" className="grid-item">
        <div className="grid-content">
          <FontAwesomeIcon icon={faUserTie} className="grid-icon" />
          <h3>Investment Agents</h3>
          <p>Personalized AI investment guidance</p>
        </div>
      </Link>
      <Link to="/ideas-maker" className="grid-item">
        <div className="grid-content">
          <FontAwesomeIcon icon={faFileInvoice} className="grid-icon" />
          <h3>Instant Report Studio</h3>
          <p>Create dynamic reports on the fly</p>
        </div>
      </Link>
      <Link to="/smart-search-suite" className="grid-item">
        <div className="grid-content">
          <FontAwesomeIcon icon={faSearch} className="grid-icon" />
          <h3>Smart Search Engine</h3>
          <p>Find precise market answers with AI</p>
        </div>
      </Link>
      <Link to="/ai-market-dashboard" className="grid-item">
        <div className="grid-content">
          <FontAwesomeIcon icon={faThLarge} className="grid-icon" />
          <h3>AI Market Dashboard</h3>
          <p>Live monitoring with deep analytics</p>
        </div>
      </Link>
    </div>
  </div>
);


const Footer = () => (
  <footer>
    <h1><Link to="/help">Help</Link> | <Link to="/contact">Contact</Link></h1>
    <p>© 2024 Fina.ai</p>
  </footer>
);

const Hero = () => (
  <section className="hero">
    <h1>Welcome to Fina.ai</h1>
    <p>AI-powered tools for real-time market intelligence.</p>
  </section>
);


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('access_token'));

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setIsAuthenticated(false);
    window.location.href = '/login';
  };

  return (
    <Router>
      <div className="App">
        <Header onLogout={handleLogout} isAuthenticated={isAuthenticated} />

        <div className="wrapper">
          <div className="main-content">
            {isAuthenticated && <Sidebar />}
            <div className="content-area">
              <Routes>
                <Route
                  path="/login"
                  element={<Login setIsAuthenticated={setIsAuthenticated} />}
                />
         
                {/* Private routes */}
                <Route
                  path="/"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <>
                        <Hero />
                        <ProductGrid />
                      </>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/features"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <FeaturesPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/help"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <HelpPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/contact"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <ContactPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/ai-market-dashboard"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <AIMarketDecoder />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/agents"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <AgentsSuite />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/news-suite"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <NewsSuite />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/topic-gather"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <TopicGather />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/numbers-suite"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <NumbersSuite />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/text-videos-suite"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <TextsVideosSuite />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/smart-search-suite"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <SmartSearchSuite />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/signals-suite"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <SignalsSuite />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/ideas-maker"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <IdeaMakerSuite />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/companies"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <MicroProbe />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/foreign-exchange"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <ForeignExchangeFrontPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/economy"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <EconomyFrontPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/commodity"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CommodityFrontPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/crypto"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CrytoFrontPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bond"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <BondFrontPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/company-details/:companyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <Companies />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/foreignexchange-details/:foreignExchangeName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CurrencyPairs />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/foreignexchange-news/:foreignExchangeName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CurrencyPairsNews />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/commodity-details/:commodityName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <IndividualCommodity />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/crypto-details/:cryptoName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <IndividualCrypto />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/economy-details/:economyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <Countries />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bond-details/:economyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <IndividualBond />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bond-news/:economyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <IndividualBondNews />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/crypto-news/:cryptoName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <InvididualCryptoNews />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/commodity-news/:commodityName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <InvididualCommodityNews />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/economy-news/:economyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CountriesNews />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/company-news/:companyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CompaniesNews />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bond-numbers/:economyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <IndividualBondNumbers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/commodity-numbers/:commodityName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <IndividualCommodityNumbers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/crypto-numbers/:cryptoName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <IndividualCryptoNumbers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/economy-numbers/:economyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CountriesNumbers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/company-numbers/:companyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CompaniesNumbers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/foreignexchange-numbers/:foreignExchangeName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CurrencyPairsNumbers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bond-signals/:economyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <IndividualBondSignals />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/commodity-signals/:commodityName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <IndividualCommoditySignals />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/crypto-signals/:cryptoName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <IndividualCryptoSignals />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/economy-signals/:economyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CountriesSignals />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/company-signals/:companyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CompaniesSignals />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/foreignexchange-signals/:foreignExchangeName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CurrencyPairsSignals />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/company-earnings/:companyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CompaniesEarnings />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/company-sec-fillings/:companyName"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <CompaniesSEC />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/videos"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <VideoInsight />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/documents"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <DocumentUpload />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/insight-explorer"
                  element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <InsightExplorer />
                    </PrivateRoute>
                  }
                />

                {/* More private routes can be added here */}
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Router>
  );
};


export default App;