import React, { useState, useEffect, useRef } from 'react';
import axios from '../../../../axiosConfig';
import './PriceDataBox.css';

const PriceDataBoxCrypto = ({ onCryptoSelect }) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const suggestionsRef = useRef(null);

    useEffect(() => {
        const loadSuggestions = async () => {
            if (query.length > 1) {
                setLoading(true); // Start loading
                try {
                    const response = await axios.get(`/api/cryptosearch/?q=${query}`);
                    setSuggestions(response.data);
                } catch (error) {
                    console.error('Error fetching suggestions:', error);
                } finally {
                    setLoading(false); // Stop loading
                }
                setHighlightedIndex(-1);
            } else {
                setSuggestions([]);
            }
        };

        loadSuggestions();
    }, [query]);

    const handleKeyDown = (e) => {
        if (e.keyCode === 40) { // Down arrow
            setHighlightedIndex(prevIndex =>
                prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex);
        } else if (e.keyCode === 38) { // Up arrow
            setHighlightedIndex(prevIndex =>
                prevIndex > 0 ? prevIndex - 1 : 0);
        } else if (e.keyCode === 13 && highlightedIndex !== -1) { // Enter key
            setQuery(suggestions[highlightedIndex].name);
            setSuggestions([]);
            onCryptoSelect(suggestions[highlightedIndex].name);
        }
    };

    const handleSelect = (cryptoName, event) => {
        event.preventDefault();
        setQuery(cryptoName);
        setSuggestions([]);
        onCryptoSelect(cryptoName);
    };

    const handleBlur = (e) => {
        if (suggestionsRef.current && suggestionsRef.current.contains(e.relatedTarget)) {
            return;
        }
        setSuggestions([]);
    };

    return (
        <div className="autocomplete">
            <input
                ref={inputRef}
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Search crypto..."
                className="autocomplete-input"
                onBlur={handleBlur}
            />
            {loading && <div className="loading-spinner"></div>}
            {!loading && suggestions.length > 0 && (
                <ul className="suggestions-list" ref={suggestionsRef}>
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={suggestion.id}
                            className={`suggestion-item ${index === highlightedIndex ? 'highlighted' : ''}`}
                            onMouseDown={(event) => handleSelect(suggestion.name, event)}
                        >
                            {suggestion.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default PriceDataBoxCrypto;
