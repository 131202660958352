import React, { useState, useEffect } from 'react';
import axios from '../../../../axiosConfig';
import SummaryDisplayForecastAI from './SummaryDisplayForecastAI';

const SignalsBacktestAI = ({ inputData }) => {
    const [predictionResults, setPredictionResults] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);


    useEffect(() => {
        if (inputData) {
            setDataLoaded(true);
        }
    }, [inputData])

    const fetchPredictionResults = async () => {
        if (!inputData || !inputData.name) {
            setError('No input symbol provided');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get('/ai_signal_reasoning_bond/', {
                params: { 
                    inputName: inputData.name + " government bond",
                    inputCentral_bank: inputData.central_bank,
                    inputEconomic_indicator: inputData.economic_search
        
                }
            });

            let data = response.data.response;
            if (typeof data === 'string') {
                data = [data];
            }
            setPredictionResults(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handlePredictionClick = () => {
        setPredictionResults(null); 
        setError(null); 
        if (dataLoaded) {
            fetchPredictionResults();
        }
    }

    return (
      <div>
          <h3>Signals</h3>
          <h4> Overview </h4>
          <button onClick={handlePredictionClick} disabled={loading || !dataLoaded}>
              Get Bond Analysis
          </button>
          {(loading || !dataLoaded) && <div>Loading...</div>}
          {predictionResults && <SummaryDisplayForecastAI summary={predictionResults} />}
          {/*{predictionResults && <div>{JSON.stringify(predictionResults)}</div>} */}
          {error && <div>Error: {error}</div>}
      </div>
  );
}

export default SignalsBacktestAI;