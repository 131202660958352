import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from '../../../../axiosConfig';
import axios from 'axios';  
import CustomDropdown from './CustomDropdownSEC'; // Import the custom dropdown component
import { fetchFilingNumbers } from '../../../../services/secFillingService';
import styles from './SecFilings.module.css'; // Import the new CSS module for mobile-friendly styles

const SecFilings = ({ companyData }) => {
  const [selectedFilingNumberIndex, setSelectedFilingNumberIndex] = useState(null);
  const [filingNumbers, setFilingNumbers] = useState([]);
  const [filingNumberLoading, setFilingNumberLoading] = useState(false);
  const [question, setQuestion] = useState('');
  const [conversationHistory, setConversationHistory] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [enteredCIK, setEnteredCIK] = useState(companyData.CIK || '');
  const [submittedCIK, setSubmittedCIK] = useState('');
  const [isCIKEntered, setIsCIKEntered] = useState(!!companyData.CIK);
  const [errorMessage, setErrorMessage] = useState('');
  const [cikBoxShownOnce, setCikBoxShownOnce] = useState(false);
  const source = useRef(axios.CancelToken.source());

  useEffect(() => {
    if (isCIKEntered && submittedCIK) {
      setFilingNumberLoading(true);
      fetchFilingNumbers(submittedCIK)
        .then(filingNumbers => {
          setFilingNumbers(filingNumbers.reverse());
          setFilingNumberLoading(false);
          setErrorMessage('');
        })
        .catch(error => {
          console.error('Error fetching filing numbers:', error);
          setFilingNumberLoading(false);
          setFilingNumbers([]);
          setIsCIKEntered(false);
          setEnteredCIK(''); // Clear the CIK input box
          setErrorMessage('No filings available for this CIK.');
        });
    }
  }, [isCIKEntered, submittedCIK]);

  useEffect(() => {
    setConversationHistory([]);
    setQuestion('');
  }, [selectedFilingNumberIndex]);

  const handleFilingNumberChange = (index) => {
    setSelectedFilingNumberIndex(index);
    setShowChat(true);
    setQuestion('');
    setConversationHistory([]);
  };

  const handleAskQuestionClick = async () => {
    if (question) {
      setLoadingQuestion(true);
      try {
        const cik = submittedCIK;
        const accn = filingNumbers[selectedFilingNumberIndex]?.accn;
  
        if (!cik || !accn) {
          setLoadingQuestion(false);
          return;
        }
  
        const requestBody = {
          question: question,
          chat_history: conversationHistory,
          cik: cik,
          accn: accn,
        };

        source.current.cancel('Operation canceled by the user.');
        source.current = axios.CancelToken.source();

        const response = await axiosInstance.post('/sec_chat/', requestBody, {
          headers: {
            'Content-Type': 'application/json'
          },
          cancelToken: source.current.token,
        });

        setConversationHistory([...conversationHistory, { question: question, answer: response.data.answer }]);
        setQuestion('');
      } catch (error) {
        console.error('Error asking question:', error);
      }
      setLoadingQuestion(false);
    }
  };

  const handleCIKSubmit = (e) => {
    e.preventDefault();
    setIsCIKEntered(true);
    setSubmittedCIK(enteredCIK);
    setFilingNumberLoading(true);
    setErrorMessage('');
    fetchFilingNumbers(enteredCIK)
      .then(filingNumbers => {
        setFilingNumbers(filingNumbers.reverse());
        setFilingNumberLoading(false);
        setEnteredCIK('');
      })
      .catch(error => {
        console.error('Error fetching filing numbers:', error);
        setFilingNumberLoading(false);
        setFilingNumbers([]);
        setIsCIKEntered(false);
        setEnteredCIK(''); // Clear the CIK input box
        setErrorMessage('No filings available for this CIK.');
      });
  };

  return (
    <div className={styles.secFilingsContainer}>
      <h4>SEC Filings</h4>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      {(!isCIKEntered || errorMessage || (filingNumbers.length === 0 && !filingNumberLoading)) ? (
        <div>
          {cikBoxShownOnce && (
            <div className={styles.noDataMessage}>
              <p>No data is available, please try a different CIK:</p>
            </div>
          )}
          <form onSubmit={handleCIKSubmit}>
            <label className={styles.cikLabel}>
              Enter CIK:{" "}
              <input
                type="text"
                placeholder="eg: 0000088941"
                value={enteredCIK}
                onChange={(e) => setEnteredCIK(e.target.value)}
                onFocus={() => setCikBoxShownOnce(true)}
              />
            </label>
            <button type="submit">Submit</button>
            <p className={styles.cikInfo}>
              CIK is a unique identifier for a company. You can find it on the SEC website: <a href="https://www.sec.gov/edgar/searchedgar/cik" target="_blank" rel="noopener noreferrer">search</a>
            </p>
          </form>
        </div>
      ) : (
        <>
          {filingNumberLoading ? <p>Loading filings...</p> : (
            <>
              {filingNumbers.length > 0 ? (
                <CustomDropdown
                  options={filingNumbers.map(filing => `${filing.form}-${filing.fy}-${filing.fp}`)}
                  onChange={handleFilingNumberChange}
                  value={selectedFilingNumberIndex}
                />
              ) : (
                <p>No filings available for this CIK.</p>
              )}
            </>
          )}
          {showChat && (
            <div className={styles.chatContainer}>
              {conversationHistory.length > 0 && (
                <div>
                  <h3>Conversation History:</h3>
                  {conversationHistory.map((qaPair, index) => (
                    <div key={index}>
                      <h4>Q: {qaPair.question}</h4>
                      <p>A: {qaPair.answer}</p>
                    </div>
                  ))}
                </div>
              )}
              <div className={styles.inputSection}>
                <textarea
                  type="text"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  placeholder="Ask about the filing"
                  rows={5}
                />
                <button onClick={handleAskQuestionClick}>Ask</button>
                {loadingQuestion && <p>Loading...</p>}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default SecFilings;