import React, { useState, useEffect } from 'react';
import SummaryDisplayLatestD from './SummaryDisplayLatestD';
import { newsSummary } from '../../../../services/newsSummary';

const EconomyHighlight = ({ inputData }) => {
    const [keyword, setKeyword] = useState('');
    const [summaryData, setSummaryData] = useState(null);
    const [isLoadingSummary, setIsLoadingSummary] = useState(false); 

    useEffect(() => {
        setKeyword(`${inputData.name} economy`);
    }, [inputData]);

    const fetchSummary = async () => {
        setIsLoadingSummary(true); 
        setSummaryData(null); 
        try {
            const summary = await newsSummary(keyword); 
            setSummaryData(summary); 
        } catch (error) {
            console.error('Failed to fetch summary:', error);
        }
        setIsLoadingSummary(false); 
    }

    const handleSearchClick = () => {
        fetchSummary();
    }

    const isLoading = isLoadingSummary;

    return (
        <div>
            <h3>Highlights</h3>
      
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px' }}>
                <button onClick={handleSearchClick}>Economy</button>
                {isLoading && <div>Loading...</div>}
         
                {summaryData && <SummaryDisplayLatestD summary={summaryData.response} />}
              
               
            </div>
        </div>
    );
}

export default EconomyHighlight;